import React from "react";
import { formatDateTime } from "../helper/misc";

const DataRefresher = ({ refetch, loading, lastUpdated }) => {
  return (
    <div>
      <span className="text-xs text-gray-500 mr-4">Last Data Updated: {formatDateTime(lastUpdated)}</span>
      <span className={loading ? "opacity-0" : ""}>
        <svg
          onClick={refetch}
          xmlns="http://www.w3.org/2000/svg"
          className="inline-block icon icon-tabler icon-tabler-reload cursor-pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1.002 7.935 1.007 9.425 4.747"></path>
          <path d="M20 4v5h-5"></path>
        </svg>
      </span>
    </div>
  );
};

export default DataRefresher;
