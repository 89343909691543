import React, { useState } from "react";

const Tabs = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <div className="flex border-b">
        {data?.map((tab, index) => (
          <button
            key={index}
            className={`flex p-4 text-center rounded-t-xl ${
              activeTab === index ? "bg-sky-100" : "bg-white text-gray-400"
            } hover:bg-sky-50`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="p-6">
        {data?.map((tab, index) => (activeTab === index ? <div key={index}>{tab.content}</div> : null))}
      </div>
    </div>
  );
};

export default Tabs;
