import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, authApi } from "../api";

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      loadAuthHeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggedIn = () => !!localStorage.getItem("user");

  const loadAuthHeader = () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      api.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response.status === 401) {
            alert("session invalid. please login again");
            navigate("/login");
          } else {
            throw error;
          }
        }
      );
    }
  };

  const login = (body) => {
    authApi
      .adminLogin(body)
      .then((r) => {
        if (r.status === 200) {
          localStorage.setItem("authToken", r.data.Token);
          localStorage.setItem("user", JSON.stringify(r.data.AdminUser));
          loadAuthHeader();
          navigate("/admin/overview");
        }
      })
      .catch((e) => {
        console.error(e);
        alert("can't connect to server");
      });
  };

  const logout = () => {
    localStorage.setItem("authToken", "");
    localStorage.setItem("user", "");
    navigate("/login");
  };

  const getShownName = () => {
    const user = localStorage.getItem("user");
    if (user) {
      return JSON.parse(user)?.Username;
    }
    return "-";
  };

  return { login, logout, isLoggedIn, loadAuthHeader, getShownName };
};

export default useAuth;
