export const IndustryOptions = [
  { value: "Advertising and marketing", label: "Advertising and marketing" },
  { value: "Aerospace", label: "Aerospace" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Art and Design", label: "Art and Design" },
  { value: "Computer and technology", label: "Computer and technology" },
  { value: "Construction", label: "Construction" },
  { value: "Education", label: "Education" },
  { value: "Energy", label: "Energy" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Fashion", label: "Fashion" },
  { value: "Finance and economic", label: "Finance and economic" },
  { value: "Food and beverage", label: "Food and beverage" },
  { value: "Government / Bureaucracy", label: "Government / Bureaucracy" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Human rights", label: "Human rights" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Media and news", label: "Media and news" },
  { value: "Mining", label: "Mining" },
  { value: "Pharmaceutical", label: "Pharmaceutical" },
  { value: "Religion organizations", label: "Religion organizations" },
  { value: "Telecommunication", label: "Telecommunication" },
  { value: "Transportation", label: "Transportation" },
];
