import React, { PureComponent } from "react";
import { getNameByID } from "../../helper/GameHelper";

export default class SaleData extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      soldData: {},
      dateList: {},
    };
    var _this = this;
    fetch("/api/sold-items", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var formattedData = {};
        var dateList = {};
        data["soldItems"]["content"].forEach((element) => {
          var gameName = element["_id"]["item"];
          if (formattedData[gameName] === undefined) {
            formattedData[gameName] = {};
          }
          if (dateList[element["_id"]["date"]] === undefined) {
            dateList[element["_id"]["date"]] = true;
          }
          formattedData[gameName][element["_id"]["date"]] = element["newPurchase"] * 59;
        });
        _this.setState({
          dateList: dateList,
          soldData: formattedData,
        });
      });
  }

  render() {
    var displayItem = [];
    var dateHeader = [];
    for (let key in this.state.dateList) {
      dateHeader.push(<th style={{ minWidth: "100px", textAlign: "right" }}>{key}</th>);
    }
    for (let key in this.state.soldData) {
      var row = [];
      row.push(<td style={{ textAlign: "right" }}>{getNameByID(key)}</td>);
      for (let dateKey in this.state.dateList) {
        row.push(<td style={{ textAlign: "right" }}>{this.state.soldData[key][dateKey]}</td>);
      }
      displayItem.push(<tr>{row}</tr>);
    }
    return (
      <center>
        <p style={{ fontSize: "36px", textAlign: "center" }}>Sale Data</p>
        <br />
        <center style={{ "overflow-x": "scroll" }}>
          <table>
            <tr>
              <th style={{ minWidth: "150px", textAlign: "right" }}>GameID</th>
              {dateHeader}
            </tr>
            {displayItem}
          </table>
        </center>
      </center>
    );
  }
}
