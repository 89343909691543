import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import Navbar from "../../components/Navbar";
import "../../css/Home.css";
import { logActivity } from "../../helper/LogActivity";
import { changeLang } from "../../helper/RoutingHelper";
import i18n from "../../i18n";

const cookies = new Cookies();

var campaignID;
var cookieID = "";
var lang = "en";

class FitforceHowToInstall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 480,
    };

    var queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    campaignID = urlParams.get("cid");

    lang = urlParams.get("hl");

    if (i18n.language !== lang && lang != null) {
      i18n.changeLanguage(lang);
    } else if (lang === null) {
      var language = window.navigator.userLanguage || window.navigator.language;
      changeLang(language);
    }

    cookieID = cookies.get("fitforceCookieID");

    if (cookieID !== undefined) {
      logActivity("DownloadPage", campaignID, cookieID);
    } else {
      fetch("/api/gen-cookie", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          cookies.set("fitforceCookieID", data.CookieID, { path: "/" });
          cookieID = cookies.get("fitforceCookieID");
          logActivity("DownloadPage", campaignID, cookieID);
        });
    }
  }

  resize = () => {
    this.setState({ isMobile: window.innerWidth <= 480 });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  computerInstall = (isMobile, t) => {
    return (
      <div
        style={
          isMobile
            ? {
                backgroundColor: "#222",
                flex: 1,
                padding: "2vw",
                flexDirection: "row",
                display: "flex",
                paddingLeft: "1vw",
                paddingRight: 0,
              }
            : {
                backgroundColor: "#111",
                width: "60vw",
                padding: "2vw",
                flexDirection: "row",
                display: "flex",
                paddingLeft: "1vw",
                paddingRight: 0,
              }
        }
      >
        <img
          style={
            isMobile
              ? { width: "60vw", height: "30vw", marginTop: "10vw", marginLeft: "-10vw" }
              : { width: "40vw", height: "20vw", marginTop: "3vw" }
          }
          alt="load_pc"
          src={require("../../img/FitforceDownload/load_pc.png")}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: isMobile ? "5vw" : 0,
          }}
        >
          <div style={{ flex: 1 }} />
          <p
            style={{
              color: "#b65f30",
              margin: "2vw",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ? "5vw" : "2vw",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("STEP")} {isMobile ? 2 : 1}
          </p>
          <p
            style={{
              fontSize: isMobile ? "4vw" : "1.5vw",
              color: "#fff",
              textAlign: "center",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("install on your pc")}
          </p>
          <span
            onClick={() => logActivity("DirectDownload", campaignID, cookieID)}
            href="http://fitforce.fun:8888/hfs/fitforcesetup.exe"
            target="_blank"
          >
            <img
              style={{ width: isMobile ? "30vw" : "15vw", height: isMobile ? "14vw" : "7vw" }}
              alt="downloadforpc"
              src={require("../../img/FitforceDownload/downloadforpc.png")}
            />
          </span>
          <p
            style={{
              fontSize: isMobile ? "4vw" : "1.5vw",
              color: "#fff",
              textAlign: "center",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("or get it on")}
          </p>
          <span
            onClick={() => logActivity("ToSteam", campaignID, cookieID)}
            href="https://store.steampowered.com/app/1081670/Fitforce/"
            target="_blank"
          >
            <img
              style={{ width: isMobile ? "30vw" : "15vw", height: isMobile ? "14vw" : "7vw" }}
              alt="steamload"
              src={require("../../img/FitforceDownload/steamload.png")}
            />
          </span>
          <div style={{ flex: 1 }} />
        </div>
      </div>
    );
  };

  mobileInstall = (isMobile, t) => {
    return (
      <div
        style={
          isMobile
            ? { backgroundColor: "#111", flex: 1, padding: "2vw", flexDirection: "row", display: "flex" }
            : {
                backgroundColor: "#222",
                width: "40vw",
                padding: "2vw",
                flexDirection: "row",
                display: "flex",
              }
        }
      >
        <img
          style={{
            width: isMobile ? "40vw" : "18vw",
            height: isMobile ? "73.8vw" : "33.21vw",
            marginLeft: isMobile ? "5vw" : 0,
          }}
          alt="load_mobile"
          src={require("../../img/FitforceDownload/load_mobile.png")}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: isMobile ? "5vw" : 0,
          }}
        >
          <div style={{ flex: 1 }} />
          <p
            style={{
              color: "#b65f30",
              margin: "2vw",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ? "5vw" : "2vw",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("STEP")} {isMobile ? 1 : 2}
          </p>
          <p
            style={{
              fontSize: isMobile ? "4vw" : "1.5vw",
              color: "#fff",
              textAlign: "center",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("install on your phone")}
          </p>
          <span
            onClick={() => logActivity("ToAppStore", campaignID, cookieID)}
            href="https://apps.apple.com/us/app/fitforce/id1402662894"
            target="_blank"
          >
            <img
              style={{ width: isMobile ? "30vw" : "15vw", height: isMobile ? "14vw" : "7vw" }}
              alt="appstoreload"
              src={require("../../img/FitforceDownload/appstoreload.png")}
            />
          </span>
          <p
            style={{
              fontSize: isMobile ? "4vw" : "1.5vw",
              color: "#fff",
              textAlign: "center",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("or")}
          </p>
          <span
            onClick={() => logActivity("ToPlayStore", campaignID, cookieID)}
            href="https://play.google.com/store/apps/details?id=com.runster"
            target="_blank"
          >
            <img
              style={{ width: isMobile ? "30vw" : "15vw", height: isMobile ? "14vw" : "7vw" }}
              alt="googleplayload"
              src={require("../../img/FitforceDownload/googleplayload.png")}
            />
          </span>
          <div style={{ flex: 1 }} />
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div style={{ width: "100%", maxWidth: "100vw", minHeight: "100vh", backgroundColor: "#2c3335" }}>
        <Navbar
          campaignID={campaignID}
          lang={lang}
          isGuideToDownload={false}
          isMobile={this.state.isMobile}
        />
        <div style={{ height: this.state.isMobile ? 60 : "10vh" }} />
        <center>
          <p
            style={{
              color: "#b65f30",
              marginTop: "1vw",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: this.state.isMobile ? "6vw" : "3.5vw",
              width: this.state.isMobile ? "45vw" : "",
              alignSelf: "center",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("2 EASY STEPS TO DOWNLOAD")}
          </p>
        </center>
        <div
          style={{
            width: "80vw",
            height: 1,
            backgroundColor: "#b65f30",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <div style={{ flex: 1 }} />
          <div
            style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#b65f30", marginTop: -5 }}
          />
        </div>
        <div style={{ height: this.state.isMobile ? "6vw" : "2vw" }} />
        {this.state.isMobile && (
          <div style={{ flexDirection: "column", display: "flex" }}>
            {this.mobileInstall(this.state.isMobile, t)}
            {this.computerInstall(this.state.isMobile, t)}
          </div>
        )}
        {!this.state.isMobile && (
          <div style={{ flexDirection: "row", display: "flex" }}>
            {this.computerInstall(this.state.isMobile, t)}
            {this.mobileInstall(this.state.isMobile, t)}
          </div>
        )}
        <div style={{ padding: this.state.isMobile ? "5vw" : "2vw" }}>
          <p
            style={{
              color: "#b65f30",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: this.state.isMobile ? "5vw" : "2vw",
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("You're ready! Let's get fit with FITFORCE")}
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FitforceHowToInstall);
