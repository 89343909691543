import React, { Component } from "react";

class FitforceTroubleshoot extends Component {
  render() {
    return (
      <div className="container" style={{ padding: "20px" }}>
        <center>
          <br />
          <h style={{ fontSize: "36px" }}>Customer support</h>
          <br />
          <p style={{ fontSize: "18px" }}>Let's see how to tackle the problem</p>
        </center>
        <br />
        <div style={{ alignItems: "flex-start" }}>
          <p style={{ fontSize: "18px", textAlign: "left" }}>
            1. Go to "Control Panel &gt; System and Security &gt; Windows Defender Firewall"
          </p>
          <img
            style={{ height: "350px", width: "auto" }}
            alt="controlPanel"
            src={require("../../img/controlPanel.png")}
          />
          <img
            style={{ height: "350px", width: "auto" }}
            alt="system-security"
            src={require("../../img/system&security.png")}
          />
          <br />
          <br />
          <p style={{ fontSize: "18px", textAlign: "left" }}>
            2. On the left-hand side, there will be the "Restore defaults" menu, select it
          </p>
          <img
            style={{ height: "400px", width: "auto" }}
            alt="windowfirewall"
            src={require("../../img/windowfirewall.png")}
          />
          <br />
          <br />
          <p style={{ fontSize: "18px", textAlign: "left" }}>3. Click "Restore defaults" button</p>
          <img
            style={{ height: "400px", width: "auto" }}
            alt="restoreDefault"
            src={require("../../img/restoreDefault.png")}
          />
          <br />
          <br />
          <p style={{ fontSize: "18px", textAlign: "left" }}>
            4. Close Runster on PC by clicking the 'X' button on the top right of a screen
          </p>
          <br />
          <p style={{ fontSize: "18px", textAlign: "left" }}>
            5. Open it again, and it will show a firewall popup
          </p>
          <img
            style={{ height: "350px", width: "auto" }}
            alt="allowFirewall"
            src={require("../../img/allowFirewall.png")}
          />
          <br />
          <p style={{ fontSize: "18px", textAlign: "left" }}>6. Select allow access </p>
          <br />
          <p style={{ fontSize: "18px", textAlign: "left" }}>7. Scan QR code again, it should work now.</p>
        </div>
      </div>
    );
  }
}

export default FitforceTroubleshoot;
