import React from "react";
import CardInstallation from "../../components/CardInstallation";
import StaticLayout from "../../layouts/StaticLayout";

const CBTInstalliOS = () => {
  return (
    <StaticLayout
      title={
        <div>
          6 ขั้นตอน <br />
          ติดตั้งแอปพลิเคชัน (iOS)
        </div>
      }
      titleCenter
    >
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-wrap justify-center">
          <CardInstallation title="1. เข้าไปที่อีเมลจากระบบของ Apple แล้ว กดปุ่ม View in Testflight">
            <img src={require("../../img/Fitforce2/Howto iOS 1.png")} alt="ios-step1" />
          </CardInstallation>
          <CardInstallation title="2. กดเข้าไปที่ Testflight from the App Store แล้วดาวน์โหลด (Get) แอปพลิเคชัน Testflight">
            <img src={require("../../img/Fitforce2/Howto iOS 2.png")} alt="ios-step2" />
          </CardInstallation>
          <CardInstallation title="3. เมื่อเข้าไปที่แอป ให้สังเกตข้อความ Redeem มุมขวาบน">
            <img src={require("../../img/Fitforce2/Howto iOS 3.png")} alt="ios-step3" />
          </CardInstallation>
          <CardInstallation title="4. กรอกรหัสจากข้อ 3. ในหน้า Testflight บนเบราวเซอร์ในกล่องข้อความ แล้วกด Redeem">
            <img src={require("../../img/Fitforce2/Howto iOS 4.png")} alt="ios-step4" />
          </CardInstallation>
          <CardInstallation title="5. หน้าจอจะปรากฏแอปพลิเคชัน FITFORCE2 กดติดตั้งและเปิดได้ทันที">
            <img src={require("../../img/Fitforce2/Howto iOS 5.png")} alt="ios-step5" />
          </CardInstallation>
          <CardInstallation title="6. เมื่อเข้าแอปพลิเคชัน FITFORCE2 ให้กรอก รหัส Beta Test ที่ได้จากอีเมลของเรา แล้วเริ่มเล่นได้เลย!">
            <img src={require("../../img/Fitforce2/Howto iOS 6.png")} alt="ios-step6" />
          </CardInstallation>
        </div>
      </div>
    </StaticLayout>
  );
};

export default CBTInstalliOS;
