import React, { Component } from "react";
import "../../css/Home.css";

class RunsterAboutUs extends Component {
  render() {
    var isMobile = window.innerWidth <= 480;
    return (
      <div className="bg">
        <div
          style={{
            height: isMobile ? "5vh" : "8vh",
            width: "100%",
            position: "fixed",
            top: 0,
            backgroundColor: "#000000aa",
            display: "flex",
            flexDirection: "row",
            paddingLeft: "5vw",
            paddingRight: "5vw",
            zIndex: 10,
          }}
        >
          <img
            alt="textlogo"
            src={require("../../img/textlogo.png")}
            style={{ height: isMobile ? "5vh" : "8vh", width: "auto" }}
          />
          <div style={{ flex: 1 }} />
          <a href="/runster" style={{ paddingTop: isMobile ? "1vh" : "1.8vh" }}>
            <h style={{ color: "#fff", fontSize: isMobile ? "2.5vh" : "3.5vh" }}>Home</h>
          </a>
          <div style={{ width: "5vw" }} />
          <a href="/runster/about-us" style={{ paddingTop: isMobile ? "1vh" : "1.8vh" }}>
            <h style={{ color: "#fff", fontSize: isMobile ? "2.5vh" : "3.5vh" }}>About us</h>
          </a>
        </div>
        <div style={{ height: isMobile ? "5vh" : "8vh" }} />
        <div style={{ display: isMobile ? "none" : "float", width: "100%", height: "auto" }}>
          <img
            style={{ width: "100vw", height: "auto", display: "float" }}
            alt="aboutHeader2"
            src={require("../../img/aboutHeader2.png")}
          />
          <div style={{ position: "absolute", top: "32vh", left: "60vw" }}>
            <h style={{ color: "#fff", fontSize: "4.3vw", lineHeight: "120%" }}>
              <span style={{ display: "block" }}>Bring fun</span>
              <span style={{ display: "block" }}>to your exercise</span>
            </h>
            <p style={{ width: "30vw", color: "#fff", fontSize: "1.4vw" }}>
              With Runster, you will exercise without realizing it.
            </p>
            <a href="https://store.steampowered.com/app/1081670/Runster/" target="_blank" rel="noreferrer">
              <img
                style={{ height: "auto", width: "20vw", marginLeft: "-2vw" }}
                alt="tryDemo"
                src={require("../../img/tryDemo.png")}
              />
            </a>
          </div>
        </div>
        <div style={{ display: isMobile ? "flex" : "none", flexDirection: "column" }}>
          <img
            style={{ width: "100vw", height: "auto" }}
            alt="aboutHeader"
            src={require("../../img/aboutHeader.png")}
          />
          <center>
            <div style={{ height: "5vw" }} />
            <h style={{ color: "#fff", fontSize: "8vw", lineHeight: "120%" }}>
              <span style={{ display: "block" }}>Bring fun to</span>
              <span style={{ display: "block" }}>your exercise</span>
            </h>
            <div style={{ height: "1vh" }} />
            <p style={{ width: "70vw", color: "#fff", fontSize: "4vw" }}>
              With Runster, you will exercise without realizing it.
            </p>
            <div style={{ height: "1vh" }} />
            <a href="https://store.steampowered.com/app/1081670/Runster/" target="_blank" rel="noreferrer">
              <img
                style={{ height: "auto", width: "50vw" }}
                alt="tryDemo"
                src={require("../../img/tryDemo.png")}
              />
            </a>
          </center>
          <div style={{ height: "5vw" }} />
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ flex: 1, alignItems: "flex-end", paddingLeft: isMobile ? "8vw" : "16vw" }}>
            <div style={{ height: "3vw" }} />
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ flex: 1 }} />
              <h style={{ textAlign: "right", fontSize: isMobile ? "5vw" : "4vw", color: "#a3fcfc" }}>2017</h>
            </div>
            <p
              style={{
                textAlign: "end",
                fontFamily: "Staatliches-Regular",
                color: "#fff",
                fontSize: isMobile ? "4vw" : "2vw",
                marginTop: isMobile ? "3vw" : 0,
              }}
            >
              5th Place in ASEAN at the Espirex Business Model Competition in Indonesia
            </p>
            <div style={{ height: "3vw" }} />
            <p
              style={{
                textAlign: "end",
                fontFamily: "Staatliches-Regular",
                color: "#fff",
                fontSize: isMobile ? "4vw" : "2vw",
                marginTop: isMobile ? "3vw" : 0,
              }}
            >
              Winner of Startup League Thailand
            </p>
            <div style={{ height: isMobile ? "38vw" : "18vw" }} />
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ flex: 1 }} />
              <h style={{ textAlign: "right", fontSize: isMobile ? "5vw" : "4vw", color: "#a3fcfc" }}>2019</h>
            </div>
            <p
              style={{
                textAlign: "end",
                fontFamily: "Staatliches-Regular",
                color: "#fff",
                fontSize: isMobile ? "4vw" : "2vw",
                marginTop: isMobile ? "3vw" : 0,
              }}
            >
              Funded by Chulalongkorn University Business Innovation Program
            </p>
            <div style={{ height: "3vw" }} />
            <p
              style={{
                textAlign: "end",
                fontFamily: "Staatliches-Regular",
                color: "#fff",
                fontSize: isMobile ? "4vw" : "2vw",
                marginTop: isMobile ? "3vw" : 0,
              }}
            >
              Funded by Depa Startup Fund S2
            </p>
          </div>
          <div style={{ background: "#888", width: 2, marginLeft: "2vw", marginRight: "2vw" }}>
            <div style={{ height: isMobile ? "15vw" : "11vw" }} />
            <div
              style={{
                width: "1.5vw",
                height: "1.5vw",
                borderRadius: 100,
                backgroundColor: "#a3fcfc",
                marginLeft: "-0.7vw",
              }}
            />
            <div style={{ height: isMobile ? "22.5vw" : "6vw" }} />
            <div
              style={{
                width: "1.5vw",
                height: "1.5vw",
                borderRadius: 100,
                backgroundColor: "#a3fcfc",
                marginLeft: "-0.7vw",
              }}
            />
            <div style={{ height: isMobile ? "22vw" : "11.5vw" }} />
            <div
              style={{
                width: "1.5vw",
                height: "1.5vw",
                borderRadius: 100,
                backgroundColor: "#a3fcfc",
                marginLeft: "-0.7vw",
              }}
            />
            <div style={{ height: isMobile ? "35.5vw" : "14vw" }} />
            <div
              style={{
                width: "1.5vw",
                height: "1.5vw",
                borderRadius: 100,
                backgroundColor: "#a3fcfc",
                marginLeft: "-0.7vw",
              }}
            />
            <div style={{ height: isMobile ? "22vw" : "6vw" }} />
            <div
              style={{
                width: "1.5vw",
                height: "1.5vw",
                borderRadius: 100,
                backgroundColor: "#a3fcfc",
                marginLeft: "-0.7vw",
              }}
            />
            <div style={{ height: isMobile ? "20vw" : "5vw" }} />
          </div>
          <div style={{ flex: 1, paddingRight: isMobile ? "8vw" : "16vw" }}>
            <div style={{ height: isMobile ? "50vw" : "25vw" }} />
            <h style={{ textAlign: "left", fontSize: isMobile ? "5vw" : "4vw", color: "#a3fcfc" }}>2018</h>
            <p
              style={{
                fontFamily: "Staatliches-Regular",
                color: "#fff",
                fontSize: isMobile ? "4vw" : "2vw",
                marginTop: isMobile ? "3vw" : 0,
              }}
            >
              Top 20 finalist on Win-Win War Thailand (social enterprise reality TV show)
            </p>
          </div>
        </div>
        <div style={{ height: "3vw" }} />
        <center>
          <h style={{ fontSize: isMobile ? "8vw" : "5vw", color: "#a3fcfc" }}>meet the team</h>
          <center
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              paddingLeft: "20vw",
              paddingRight: "20vw",
            }}
          >
            <div
              style={{
                width: isMobile ? "30vw" : "20vw",
                display: "flex",
                flexDirection: "column",
                marginTop: "2vh",
              }}
            >
              <img
                style={{ width: isMobile ? "30vw" : "16vw", height: isMobile ? "30vw" : "16vw" }}
                alt="keerati"
                src={require("../../img/keerati-profile.png")}
              />
              <h
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: isMobile ? "5vw" : "3vw",
                  marginTop: "-0.5vw",
                }}
              >
                keerati
              </h>
              <p
                style={{
                  textAlign: "center",
                  color: "#a3fcfc",
                  fontSize: isMobile ? "2.5vw" : "1.5vw",
                  marginTop: "-0.5vw",
                }}
              >
                Founder & Developer
              </p>
            </div>
            <div
              style={{
                width: isMobile ? "30vw" : "20vw",
                display: "flex",
                flexDirection: "column",
                marginTop: "2vh",
              }}
            >
              <img
                style={{ width: isMobile ? "30vw" : "16vw", height: isMobile ? "30vw" : "16vw" }}
                alt="proa"
                src={require("../../img/proa-profile.png")}
              />
              <h
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: isMobile ? "5vw" : "3vw",
                  marginTop: "-0.5vw",
                }}
              >
                nattavadee
              </h>
              <p
                style={{
                  textAlign: "center",
                  color: "#a3fcfc",
                  fontSize: isMobile ? "2.5vw" : "1.5vw",
                  marginTop: "-0.5vw",
                }}
              >
                Co-founder & Strategist
              </p>
            </div>
            <div
              style={{
                width: isMobile ? "30vw" : "20vw",
                display: "flex",
                flexDirection: "column",
                marginTop: "2vh",
              }}
            >
              <img
                style={{ width: isMobile ? "30vw" : "16vw", height: isMobile ? "30vw" : "16vw" }}
                alt="aticha"
                src={require("../../img/aticha-profile.png")}
              />
              <h
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: isMobile ? "5vw" : "3vw",
                  marginTop: "-0.5vw",
                }}
              >
                athicha
              </h>
              <p
                style={{
                  textAlign: "center",
                  color: "#a3fcfc",
                  fontSize: isMobile ? "2.5vw" : "1.5vw",
                  marginTop: "-0.5vw",
                }}
              >
                Designer
              </p>
            </div>
          </center>
          <div style={{ height: "5vw" }} />
          <div style={{ backgroundColor: "#000" }}>
            <div style={{ height: isMobile ? "3vh" : "5vh" }} />
            <h style={{ color: "#fff", fontSize: isMobile ? "4vw" : "3vw" }}>contact us</h>
            <p style={{ color: "#fff", fontSize: isMobile ? "2vw" : "1.5vw" }}>RunsterOnline@gmail.com</p>
            <div style={{ height: isMobile ? "1.5vh" : "3vh" }} />
            <a href="https://www.facebook.com/RunsterOnline/" target="_blank" rel="noreferrer">
              <img
                style={{ width: isMobile ? "5vw" : "4vw", height: isMobile ? "5vw" : "4vw" }}
                alt="fbIcon"
                src={require("../../img/fbIcon.png")}
              />
            </a>
            <br />
            <div style={{ height: "5vh" }} />
          </div>
        </center>
      </div>
    );
  }
}

export default RunsterAboutUs;
