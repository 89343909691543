import moment from "moment";
import React, { useMemo, useState } from "react";

const backFrameOptions = Array.from({ length: 9 }, (_, i) => ({ value: i + 8, label: i + 8 }));
const forwardFrameOptions = Array.from({ length: 9 }, (_, i) => ({ value: i + 5, label: i + 4 }));

const DayRetentionTable = ({ data }) => {
  const [backFrame, setBackFrame] = useState(backFrameOptions[1].value);
  const [forwardFrame, setForwardFrame] = useState(forwardFrameOptions[4].value);

  const tableData = useMemo(() => {
    if (data && data?.PlayerFirstAndLatestPlay) {
      const firstPivotDate = moment().subtract(backFrame, "days");

      const newTableData = [];
      for (let i = 0; i < backFrame; i++) {
        const pivotDate = moment(firstPivotDate).add(i, "days");
        const pivotDateUsers = data?.PlayerFirstAndLatestPlay?.filter((x) => {
          return x.firstDate === pivotDate.format("YYYY-MM-DD");
        });
        const pivotDateUsersCount = pivotDateUsers.length;

        if (pivotDateUsersCount === 0) {
          newTableData.push({
            pivotDate,
            rowData: Array.from({ length: forwardFrame }, () => ({})),
          });
          continue;
        }

        const rowData = [];
        for (let i = 0; i < forwardFrame; i++) {
          const forwardDate = moment(pivotDate).add(i, "days");

          if (forwardDate.isSameOrAfter(moment().format("YYYY-MM-DD"))) break;

          const forwardDateUsersCount = pivotDateUsers.filter((x) =>
            moment(x.latestDate).isSameOrAfter(moment(forwardDate.format("YYYY-MM-DD")))
          ).length;

          const dayRetention = ((forwardDateUsersCount / pivotDateUsersCount) * 100).toFixed(1) + "%";

          rowData.push({
            dayRetention,
            forwardDate,
            forwardDateUsersCount,
            pivotDateUsersCount,
          });
        }
        newTableData.push({ pivotDate, rowData });
      }
      return newTableData;
    }
    return [];
  }, [data, backFrame, forwardFrame]);

  return (
    <div>
      <div className="flex m-2 mt-8">
        <p className="text-xl font-bold">Day Retention View</p>
        <select
          className="ml-4 bg-white rounded-md cursor-pointer border text-teal-600"
          value={backFrame}
          onChange={(e) => setBackFrame(e.target.value)}
        >
          {backFrameOptions.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </select>
        <select
          className="ml-4 bg-white rounded-md cursor-pointer border text-teal-600"
          value={forwardFrame}
          onChange={(e) => setForwardFrame(e.target.value)}
        >
          {forwardFrameOptions.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <table className="table-auto overflow-x-auto">
        <thead>
          <tr className="bg-teal-200">
            <th className="px-4 py-2 text-center border">
              First Play <br /> Date
            </th>
            {Array.from({ length: forwardFrame }, (_, i) => i).map((x) => (
              <th key={x} className="px-4 py-2 text-center border">
                {x}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map(({ pivotDate, rowData }, index) => (
            <tr key={index}>
              <td className="border px-3 py-1 text-center">
                <p className="text-sm">{pivotDate.format("YYYY-MM-DD")}</p>
              </td>
              {rowData.map((cell, i) => (
                <td
                  key={i}
                  className={"border px-3 py-1 text-center" + (cell.dayRetention ? "" : " bg-slate-200")}
                >
                  <p>{cell.dayRetention}</p>
                  <p className="text-xs text-gray-400">{cell.forwardDate?.format("YYYY-MM-DD")}</p>
                  <p className="text-xs text-gray-400">
                    ({cell.forwardDateUsersCount}) / ({cell.pivotDateUsersCount})
                  </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DayRetentionTable;
