import { convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { articleApi } from "../../api";
import StaticLayout from "../../layouts/StaticLayout";

const BlogPage = () => {
  const { slug } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const navigate = useNavigate();

  const fetchData = () => {
    articleApi.view(slug).then((r) => {
      if (!r.data) {
        setIsNotFound(true);
        return;
      }
      setTitle(r.data.Title);
      try {
        const convertedState = convertFromRaw(JSON.parse(r.data.Content));
        const rawContentState = convertToRaw(convertedState);
        const htmlContent = draftToHtml(rawContentState);
        setContent(htmlContent);
      } catch (e) {
        console.error(e);
      }
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNotFound) {
    navigate("/");
    return null;
  }

  return (
    <StaticLayout title={title} showTitle={false} card titleCenter>
      <div>
        <h1 className="text-4xl indent-10 mb-12">{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </StaticLayout>
  );
};

export default BlogPage;
