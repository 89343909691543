import React from "react";
import Tabs from "../../components/Tabs";
import QuestionManager from "../../components/QuestionManager";
import QuizOverview from "../../components/QuizOverview";

const AdminQuizManager = () => {
  const tabs = [
    {
      title: "Overview",
      content: <QuizOverview />,
    },
    {
      title: "Question Manager",
      content: <QuestionManager />,
    },
  ];

  return (
    <>
      <Tabs data={tabs} />
    </>
  );
};

export default AdminQuizManager;