import React, { useEffect } from "react";
import StaticFooter from "../components/StaticFooter";
import "../css/font-kanit.css";
import StaticHeader from "../components/StaticHeader";
import { XyzTransition, XyzTransitionGroup } from "@animxyz/react";

const StaticLayout = ({
  title = "No Title",
  showTitle = true,
  titleCenter = false,
  card = false,
  children,
}) => {
  useEffect(() => {
    document.title = "Fitforce 2 - " + title;
  }, [title]);

  return (
    <div className="font-kanit bg-gradient-to-b from-[#2e3756] via-[#313067] to-[#393778] min-h-screen w-full flex flex-col justify-between">
      <div className="bg-repeat-y w-screen bg-center bg-contain lg:bg-cover text-white" style={{ backgroundImage: `url(${require("../img/Fitforce2/margintextdeco.png")})` }}>
      <StaticHeader />
      <div className="p-6 lg:p-20 pt-10 lg:pt-12 flex flex-col items-center min-h-[85vh]">
        <div className={"flex flex-col w-full" + (card ? " max-w-4xl mt-4" : " max-w-6xl")}>
          {showTitle && (
            
            <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
              {titleCenter ? (
                <div className="text-white text-center text-3xl my-4 lg:my-8 textshadow">{title}</div>
              ) : (
                <p className="text-white text-xl mb-4 mt-8 lg:mt-16 lg:text-3xl textshadow">{title}</p>
              )}
            </XyzTransition>
          )}
          {card ? (
            <div className={"opacity-90 min-h-[50vh] rounded-xl p-8 bg-white"}>{children}</div>
          ) : (
            children
          )}
        </div>
      </div>
      <StaticFooter />
      </div>
    </div>
  );
};

export default StaticLayout;
