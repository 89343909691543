import React, { Component } from "react";
import { isAndroid, isIOS, isSafari } from "react-device-detect";
import Fitforce2Landing from "./Fitforce2Landing";

class FitforceGetApp extends Component {
  componentDidMount() {
    if (isIOS || isSafari) {
      window.location = "https://apps.apple.com/th/app/fitforce2/id1607830236";
    } else {
      window.location = "https://play.google.com/store/apps/details?id=com.RunnexTech.Fitforce2";
    }
  }

  render() {
    return <Fitforce2Landing />;
  }
}

export default FitforceGetApp;
