import { XyzTransition, XyzTransitionGroup } from "@animxyz/react";
import React from "react";
import { Helmet } from "react-helmet";
import CBTRegisterNowButton from "../../components/CBTRegisterNowButton";
import StaticFooter from "../../components/StaticFooter";

const description = `Fitforce 2 เกมออกกำลังกายบนมือถือ ลงทะเบียนรอบ Closed BETA ฟรี! Get ready to sweat and tone up with Fitforce 2, the ultimate mobile fitness game! Join our free Closed BETA program and be one of the first to experience the future of exercise.`;

const Fitforce2CBT = () => {
  return (
    <div className="font-kanit bg-[#675BF4] min-h-screen w-full flex flex-col justify-between">
      <Helmet>
        <title>Fitforce 2 | เกมออกกำลังกายบนมือถือ </title>
        <meta name="description" content={description} />
        <meta name="og:title" property="og:title" content="Fitforce 2 | เกมออกกำลังกายบนมือถือ" />
        <meta name="og:description" property="og:description" content={description} />
        <meta name="og:image" property="og:image" content="/img/fitforce2logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Fitforce 2 | เกมออกกำลังกายบนมือถือ" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="/img/fitforce2logo.png" />
      </Helmet>
      <div className="flex flex-wrap justify-center mx-auto min-h-screen p-8 lg:p-12 pt-20 lg:pt-12 z-10">
        <img
          src={require("../../img/Fitforce2/Gradient Scroll.png")}
          alt="fitforce2-bg"
          className="absolute left-0 w-full h-screen z-0"
        />
        <div className="z-10 flex justify-center lg:justify-end lg:pr-10 w-full lg:w-1/2 2xl:scale-[120%] 2xl:-translate-x-20">
          <div className="flex flex-col justify-center text-center lg:text-left">
            <img className="w-[400px] text-left" alt="logo" src="/img/fitforce2logo.png" />
            <div className="h-16 my-6 lg:mb-6 relative">
              <div className="absolute z-0 h-20 sm:h-[120px] bg-white w-[300vw] -translate-x-[100vw]"></div>
            </div>
            <XyzTransitionGroup appear mode="in-out" xyz="fade right-100% small stagger">
              <p className="relative font-semibold h-0 -top-[65px] sm:-top-12 text-xl sm:text-3xl lg:text-4xl">
                เกมออกกำลังกายบนมือถือ
              </p>
              <div className="text-white text-xl sm:text-3xl lg:text-4xl mt-8 sm:mt-20 lg:mt-12">
                เล่นฟรี รอบ Close BETA
              </div>
              <div className="text-white text-6xl sm:text-7xl">500 คน</div>
            </XyzTransitionGroup>
            <CBTRegisterNowButton />
          </div>
        </div>
        <div className="z-10 flex flex-col p-1 lg:p-2 justify-center lg:w-1/2 2xl:scale-[120%] 2xl:translate-x-20">
          <div className="m-2 mt-20 lg:mt-8 lg:m-8">
            <img
              src={require("../../img/Fitforce2/landing-right-1.png")}
              alt="hero-1"
              className="object-contain"
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-900 min-h-[70vh] sm:min-h-screen">
        <div className="flex flex-col h-[70vh] sm:h-screen justify-center items-center overflow-hidden">
          <video className="h-[90%]" controls autoPlay playsInline muted loop>
            <source src={require("../../video/Fitforce2-Landing.mp4")} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="flex flex-col items-center min-h-[120vh] relative">
        <img
          src={require("../../img/Fitforce2/Gradient Scroll.png")}
          alt="fitforce2-bg"
          className="absolute w-full h-[120vh] z-0"
        />
        <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
          <div className="z-10 text-white text-xl sm:text-3xl lg:text-5xl mt-20 lg:mt-32 xyz-nested">
            ใช้มือถือเครื่องเดียวก็เล่นได้แล้วนะ
          </div>
        </XyzTransition>
        <img
          src={require("../../img/Fitforce2/landing-3-upper.png")}
          alt="hero-1"
          className="z-10 object-contain"
        />
        <div className="min-h-[50vh] mt-20">
          <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
            <div className="z-10 text-white text-xl sm:text-3xl lg:text-4xl xl:text-5xl lg:mt-20 mt-4 lg:-translate-x-32 2xl:translate-x-0">
              คำนวนแคลลอรี่ที่เผาผลาญ <br /> และเวลาที่ใช้ออกกำลังกายได้
            </div>
          </XyzTransition>
          <div className="h-[50vh] sm:h-[65vh] lg:h-0"></div>
          <img
            src={require("../../img/Fitforce2/landing-3-lower.png")}
            alt="hero-1"
            className="z-10 absolute bottom-0 right-0 h-[40vh] sm:h-[56vh] object-contain"
          />
        </div>
      </div>
      <div className="flex flex-col min-h-screen items-center relative">
        <div className="bg-white w-full flex flex-col lg:items-start px-10 pt-10 pb-80 lg:pl-[10vw] lg:pt-20 lg:pb-20 xl:pl-[20vw] md:-translate-y-[1px]">
          <div className="text-4xl sm:text-5xl lg:text-7xl text-[#675BF4] font-bold text-center leading-snug">
            ตอนนี้ยังทัน <br className="block sm:hidden " /> สมัครเลย!
          </div>
          <div className="flex flex-col items-center">
            <XyzTransitionGroup appearVisible mode="in-out" xyz="fade small stagger">
              <p className="text-xl sm:text-2xl lg:text-3xl max-w-lg lg:max-w-xl mt-12">
                เพื่อให้ทุกคนได้รับประสบการณ์ที่ดีที่สุด ทีมงานจึงจำกัดให้มีผู้เข้าร่วมทดสอบเกมจำนวน 500 คน
                และตอนนี้ยังมีที่ว่างพิเศษสำหรับคุณอยู่ด้วย
              </p>
              <p className="text-xl sm:text-2xl lg:text-3xl text-[#675BF4] max-w-lg lg:max-w-xl my-8">
                มาร่วมเป็นส่วนหนึ่งของการพัฒนาเกมออกกำลังกายที่ดีที่สุดไปด้วยกันนะ
              </p>
            </XyzTransitionGroup>
          </div>
          <CBTRegisterNowButton />
        </div>
        <div className="h-[60vh] lg:h-0"></div>
        <img
          src={require("../../img/Fitforce2/landing-4-right.png")}
          alt="landing-4-right"
          className="absolute bottom-0 lg:right-0 2xl:right-[10vw] object-contain max-h-[100vh]"
        />
      </div>
      <div className="flex flex-col min-h-[140vh] items-center relative bg-[#5BF4CF]">
        <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
          <div className="text-black text-xl sm:text-3xl lg:text-4xl mt-12 lg:mt-20">
            ใครเล่นแล้ว อย่าลืมมาร่วมกิจกรรม
          </div>
        </XyzTransition>
        <div className="text-[#675BF4] text-3xl sm:text-5xl lg:text-7xl mt-4">หวยแคลอรี่</div>
        <img
          src={require("../../img/Fitforce2/landing-calories-reward.png")}
          alt="hero-1"
          className="px-4 lg:-mt-12"
        />
        <div className="max-w-5xl md:w-[90vw] text-center lg:text-left relative px-2">
          <div className="text-black font-bold text-xl sm:text-3xl lg:text-4xl my-8 lg:mt-20 text-left">
            วิธีเข้าร่วมกิจกรรม
          </div>
          <div
            className="text-black text-lg sm:text-3xl lg:text-4xl mt-2 mb-8 lg:mb-0 text-left"
            style={{ lineHeight: "1.4" }}
          >
            1. แคปหน้าจอหน้าแรกสุด <br />
            2. คอมเมนต์ภาพ + เลขท้าย 2 ตัว ใต้โพสต์นี้ <br />
            3. รอประกาศผล วันที่ 16 ก.พ. 2566
          </div>
          <img
            src={require("../../img/Fitforce2/landing-app-home.png")}
            alt="landing-app-home"
            className="mx-auto lg:absolute lg:right-0 lg:top-10 lg:w-[240px]"
          />
          <div className="bg-white rounded-lg mx-auto md:w-1/2 lg:mx-0 py-10 px-16 my-12">
            <div className="text-black text-2xl lg:text-3xl mt-2">ตัวอย่างคอมเมนต์ :</div>
            <div className="text-black text-3xl lg:text-4xl mt-4">91 ขอให้ได้ขอให้โดน สาาธุ</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center relative bg-[#202020] pb-20">
        <div className="max-w-5xl md:w-[90vw] text-left relative text-white py-14 px-4">
          <p className="text-xl">รางวัลใหญ่ : Mi band จำนวน 1 รางวัล</p>
          <p className="text-xl mt-8">หลักเกณฑ์การแจกรางวัลใหญ่ :</p>
          <p className="mt-1 font-light">
            1. ผู้ที่จะได้รางวัลใหญ่ ต้องมีเลขแคลลอรี่ 2 ตัวท้ายตรงกับรางวัลเลขท้าย 2
            ตัวของสลากกินแบ่งงวดวันที่ 16 ก.พ. 2566 <br />
            2. หากไม่มีผู้ร่วมกิจกรรมคนใดที่มีเลขแคลลอรี่ตรงกับเลขท้าย 2 ตัวของสลากกินแบ่ง
            ทีมงานจะเลือกจากผู้ที่มีเลขใกล้เคียงที่สุด (+,-) และ รางวัลอื่นๆ 19 รางวัล
          </p>
          <p className="text-xl mt-8">หลักเกณฑ์การแจกรางวัล :</p>
          <p className="mt-1 font-light">
            1. ทีมงานจะแจกรางวัลย่อยโดยการสุ่มเลขด้วยตัวเอง <br />
            2. หากไม่มีผู้ร่วมกิจกรรมคนไหนเลขตรง ทีมงานจะเลือกจากผู้ที่มีเลขใกล้เคียงที่สุด (+,-)
          </p>
          <p className="text-xl mt-8">การประกาศรางวัล :</p>
          <p className="mt-1 font-light">
            ทีมงานจะมีการ <u>#ไลฟ์ประกาศรางวัล</u> ในวันพฤหัสบดีที่ 16 กุมภาพันธ์ 2566 เวลา 15.00 น.
            และหากผู้โชคดีไม่ทักแชทแอดมินเพจเฟสบุค FitForce เข้ามายืนยันตัว ภายในวันที่ 19 กุมภาพันธ์
            ทางทีมงานจะถือว่าผู้โชคดีสละสิทธิโดยทันที
          </p>
        </div>
        <a
          href="https://www.facebook.com/fitforce.th/posts/pfbid02bYtEz8qPixDYvD1ECTFvdk2jveWwK2bJPu4ojNf71oE1uVdiiSUTw9zo81FAwFbHl"
          target="_blank"
          className="px-14 py-3 bg-[#675BF4] text-white text-2xl rounded-lg"
          rel="noreferrer"
        >
          ร่วมกิจกรรม
        </a>
      </div>
      <StaticFooter />
    </div>
  );
};

export default Fitforce2CBT;
