import { XyzTransition } from "@animxyz/react";
import React, { useEffect, useRef, useState } from "react";
import { useIsVisible } from "react-is-visible";

const CBTRegisterNowButton = () => {
  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setClicked(true);
      }, 1700);
    }
  }, [isVisible]);

  return (
    <a
      className="bg-black text-white rounded-lg mt-4 py-4 w-60 sm:w-[360px] mx-auto lg:mx-[unset] text-center text-2xl sm:text-4xl cursor-pointer relative"
      href="https://docs.google.com/forms/d/e/1FAIpQLSdwIV88swXC6D6CWlnwkFayGIud4LtwYCWc8hlvjKpra51qRA/viewform"
      target="_blank"
      rel="noreferrer"
    >
      สมัครเลย!
      <XyzTransition appearVisible xyz="fade right-100% delay-10">
        <img
          ref={nodeRef}
          src={clicked ? require("../img/Fitforce2/click-2.png") : require("../img/Fitforce2/click-1.png")}
          alt="fitforce2-bg"
          className="absolute w-12 lg:w-24 top-3 right-0 lg:translate-x-1/4"
        />
      </XyzTransition>
    </a>
  );
};

export default CBTRegisterNowButton;
