import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { frontApi } from "../../api";
import TextInput from "../../components/forms/TextInput";
import TextInputWithButton from "../../components/forms/TextInputWithButton";
import StaticLayout from "../../layouts/StaticLayout";
import { Label } from "recharts";
import { useSearchParams } from 'react-router-dom';

const RedeemCode = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const handleRedeemCode = () => {
    formik.handleSubmit();
  };

  const formik = useFormik({
    initialValues: {
      uid: "",
      redeemCode: "",
    },
    validationSchema: Yup.object({
      uid: Yup.string().required("UID is Required"),
    }),
    onSubmit: (values) => {
      frontApi
        .redeemCode(values.uid, values.redeemCode)
        .then(() => {
          setShowModal(true)
          formik.setValues({redeemCode: "", uid: values.uid});
        })
        .catch((e) => {
          if (e?.response?.status === 400) {
            if(e?.response?.data?.ErrorMessage === "UID not found"){
              formik.setErrors({ uid: e?.response?.data?.ErrorMessage });
            }
            else {
              formik.setErrors({ redeemCode: e?.response?.data?.ErrorMessage });
            }
          } else {
            formik.setErrors({ redeemCode: "Something went wrong" });
          }
        })
        .finally(() => setLoading(false));
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const language = searchParams.get('hl') || "English"
  
  var redeemCode = "Redeem Code"
  var enterUID = "Enter your UID"
  var enterCode = "Enter code"
  var redeem = "Redeem"
  var cannotFindYourID = "Cannot find your UID?"
  var uidDescription = "You can find the UID in the Fitforce2 app by going to Settings > Account and pressing the copy icon"

  if(language == "Thai")
  {
    redeemCode = "ใช้งานโค้ดพิเศษ"
    enterUID = "กรอกรหัสผู้ใช้"
    enterCode = "กรอกโค้ดพิเศษ"
    redeem = "ใข้งานโค้ด"
    cannotFindYourID = "หารหัสผู้ใช้ไม่เจอ ?"
    uidDescription = "คุณสามารถหา รหัสผู้ใช้ ได้ใน แอพพริเคชั่น Fitforce2 เข้าไปที่ ตั้งค่า > บัญชีผู้ใช้  และกดปุ่มคัดลอก"
  }

  return (
    <StaticLayout title={redeemCode}>
      <div className="px-8 md:py-8 bg-white bg-opacity-70 rounded-xl">
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row ">
            <div className="flex-[3] lg:pl-8 lg:pr-20 pt-5">
              <label for="uid" class="block mb-2 text-sm lg:text-lg font-bold text-gray-900">UID: </label>
              <TextInput
                placeholder={enterUID}
                name="uid"
                onChange={formik.handleChange}
                value={formik.values.uid}
                error={formik.errors.uid}
              />
              <label for="first_name" class="block mb-2 text-sm lg:text-lg font-bold text-gray-900">Redeem code: </label>
              <TextInput
                placeholder={enterCode}
                name="redeemCode"
                onChange={formik.handleChange}
                value={formik.values.redeemCode}
                error={formik.errors.redeemCode}
              />
              <button
                onClick={handleRedeemCode}
                className="bg-indigo-600 w-full text-white rounded text-center py-3 mt-4 text-xl lg:text-2xl cursor-pointer disabled:bg-slate-400 disabled:cursor-not-allowed"
                disabled={loading || formik.values.uid === "" || formik.values.redeemCode === ""}
              >
                {redeem}
              </button>
              <div>
                <br></br>
                <p class="text-xl lg:text-2xl text-blue-600 font-semibold">{cannotFindYourID}</p>
                <div class="pt-2"></div>
                <p class="text-gray-600 text-md lg:text-lg">{uidDescription}</p>
                <br></br>
              </div>
            </div>
            <div className="flex-[3] flex justify-center items-center pb-4">
              <img
                src={require("../../img/Fitforce2/redeem-ex.jpg")}
                alt="redeem-ex"
                className="max-h-[70vh] max-w-[50vw]"
              />
            </div>
          </div>
        </div>
      </div>
      <br></br>

      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-3"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl text-black">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6">
                <div className="relative flex-auto content-start p-1">
                  <center>
                    <img alt="success" className="w-24 my-4" src={require("../../img/SuccessCheck.png")}/>
                    </center>
                    <p className="text-lg leading-relaxed font-semibold text-center">
                      Redeem success
                    </p>
                    <p className="text-sm leading-relaxed text-center">
                    Open the app to view your inventory.
                    </p>
                </div>
                <div className="flex items-center justify-center pb-6 rounded-b mt-4">
                  <button
                    className="bg-blue-600 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-40"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </StaticLayout>
  );
};

export default RedeemCode;
