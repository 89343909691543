import moment from "moment";
import React, { useMemo, useState } from "react";

const backFrameOptions = Array.from({ length: 9 }, (_, i) => ({ value: i + 8, label: i + 8 }));
const forwardFrameOptions = Array.from({ length: 9 }, (_, i) => ({ value: i + 5, label: i + 4 }));

function convertWeekToDate(week) {
  const date = moment(week, "GGGG-[W]WW").startOf("week");
  return date.format("YYYY-MM-DD");
}

const WeekRetentionTable = ({ data }) => {
  const [backFrame, setBackFrame] = useState(backFrameOptions[0].value);
  const [forwardFrame, setForwardFrame] = useState(forwardFrameOptions[4].value);

  const tableData = useMemo(() => {
    if (data && data?.PlayerFirstAndLatestPlay) {
      const firstPivotWeek = moment().subtract(backFrame, "weeks").format("GGGG-[W]WW");
      const firstPivotDate = convertWeekToDate(firstPivotWeek);

      const newTableData = [];
      for (let i = 0; i < backFrame; i++) {
        const pivotWeek = moment(firstPivotDate).add(i, "weeks").format("GGGG-[W]WW");
        const pivotDate = convertWeekToDate(pivotWeek);
        const pivotWeekStartedUsers = data?.PlayerFirstAndLatestPlay?.filter(
          (x) =>
            moment(x.firstDate).diff(pivotDate, "days") >= 0 &&
            moment(x.firstDate).diff(pivotDate, "days") < 7
        );
        const pivotWeekStartedUsersCount = pivotWeekStartedUsers.length;

        if (pivotWeekStartedUsersCount === 0) {
          newTableData.push({
            pivotWeek,
            pivotDate,
            weekData: Array.from({ length: forwardFrame }, () => ({})),
          });
          continue;
        }
        const weekData = [];
        for (let i = 0; i < forwardFrame; i++) {
          const forwardWeek = moment(pivotDate).add(1, "days").add(i, "weeks").format("GGGG-[W]WW");
          const forwardWeekDate = convertWeekToDate(forwardWeek);
          if (moment(forwardWeekDate).add(1, "weeks").isAfter(moment())) break;

          const forwardWeekUsers = pivotWeekStartedUsers.filter((x) =>
            moment(x.latestDate).isSameOrAfter(forwardWeekDate)
          );
          const forwardWeekUsersCount = forwardWeekUsers.length;
          const forwardWeekRetention =
            ((forwardWeekUsersCount / pivotWeekStartedUsersCount) * 100).toFixed(1) + "%";
          const weekForwardRetentionData = {
            pivotWeekStartedUsersCount,
            forwardWeek,
            forwardWeekDate,
            forwardWeekRetention,
            forwardWeekUsersCount,
          };
          weekData.push(weekForwardRetentionData);
        }
        newTableData.push({ pivotWeek, pivotDate, weekData });
      }

      return newTableData;
    }
    return [];
  }, [data, backFrame, forwardFrame]);

  return (
    <div>
      <div className="flex m-2 mt-8">
        <p className="text-xl font-bold">Week Retention View</p>
        <select
          className="ml-4 bg-white rounded-md cursor-pointer border text-teal-600"
          value={backFrame}
          onChange={(e) => setBackFrame(e.target.value)}
        >
          {backFrameOptions.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </select>
        <select
          className="ml-4 bg-white rounded-md cursor-pointer border text-teal-600"
          value={forwardFrame}
          onChange={(e) => setForwardFrame(e.target.value)}
        >
          {forwardFrameOptions.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <table className="table-auto overflow-x-auto">
        <thead>
          <tr className="bg-teal-200">
            <th className="px-4 py-2 text-center border">
              First Play <br /> Week
            </th>
            {Array.from({ length: forwardFrame }, (_, i) => i).map((x) => (
              <th key={x} className="px-4 py-2 text-center border">
                {x}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.map(({ pivotWeek, pivotDate, weekData }, index) => (
            <tr key={index}>
              <td className="border px-3 py-1 text-center">
                <p className="text-sm">{pivotWeek}</p>
                <p className="text-xs text-gray-500">{pivotDate}</p>
              </td>
              {weekData.map((weekDatum, i) => (
                <td
                  key={i}
                  className={
                    "border px-3 py-1 text-center" + (weekDatum.forwardWeekRetention ? "" : " bg-slate-200")
                  }
                >
                  <p>{weekDatum.forwardWeekRetention}</p>
                  <p className="text-xs text-gray-400">{weekDatum.forwardWeek}</p>
                  <p className="text-xs text-gray-400">
                    ({weekDatum.forwardWeekUsersCount}) / ({weekDatum.pivotWeekStartedUsersCount})
                  </p>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WeekRetentionTable;
