import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { articleApi, uploadApi } from "../../api";
import { makeArticleUrl, makeSlug } from "../../helper/misc";

class Mode {
  static ADD = "ADD";
  static EDIT = "EDIT";
}

const AdminArticleDetail = () => {
  const { id } = useParams();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const mode = id ? Mode.EDIT : Mode.ADD;
  const [data, setData] = useState({ Title: "" });
  const navigate = useNavigate();

  const fetchData = () => {
    articleApi.get(id).then((r) => {
      setData(r.data);
      try {
        const convertedState = convertFromRaw(JSON.parse(r.data.Content));
        setEditorState(EditorState.createWithContent(convertedState));
      } catch {}
    });
  };

  useEffect(() => {
    if (mode === Mode.EDIT) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

    if (data?.Title && data?.Title.trim() === "") {
      alert("Title is required");
      return;
    }

    if (mode === Mode.ADD) {
      articleApi
        .create({ title: data?.Title, slug: makeSlug(data?.Title), content })
        .then((r) => {
          alert(r.data.message);
          navigate(`/admin/articles/${r.data.id}`);
          fetchData();
        })
        .catch((e) => {
          alert(e?.response?.data?.error || e);
        });
    } else {
      articleApi
        .update(id, { title: data?.Title, slug: makeSlug(data?.Title), content })
        .then((r) => {
          alert(r.data.message);
          fetchData();
        })
        .catch((e) => {
          alert(e?.response?.data?.error || e);
        });
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure?")) {
      articleApi.delete(id).then((r) => {
        navigate("/admin/articles");
      });
    }
  };

  const handleUpload = (file) => {
    return new Promise((resolve, reject) => {
      uploadApi
        .uploadFile(file)
        .then((r) => {
          resolve({ data: { link: r.data.fileUrl } });
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return (
    <div className="mx-auto md:px-10">
      <div className="my-4">
        <div className="flex space-x-3 items-center">
          <div className="text-lg font-bold">title:</div>
          <input
            type="text"
            name="title"
            onChange={(e) => {
              setData({ ...data, Title: e.target.value });
            }}
            value={data.Title}
            className="block px-5 py-3 text-base border-2 border-gray-100 text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform rounded-lg focus:outline-none"
            placeholder="Your Title"
          />
          <div className="pl-24 text-lg font-bold">url:</div>
          <a
            href={makeArticleUrl(makeSlug(data?.Title))}
            target="_blank"
            className="text-lg text-gray-400"
            rel="noreferrer"
          >
            {makeArticleUrl(makeSlug(data?.Title))}
          </a>
        </div>
      </div>
      <Editor
        editorState={editorState}
        toolbar={{
          image: {
            uploadEnabled: true,
            uploadCallback: handleUpload,
          },
        }}
        toolbarClassName=""
        wrapperClassName=""
        editorClassName="mt-2 border-gray-100 border-2 rounded-md p-4 min-h-[400px]"
        onEditorStateChange={setEditorState}
      />
      <div className="mt-10 flex justify-between">
        <NavLink
          className="px-6 py-2 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
          to="/admin/articles"
        >
          Back
        </NavLink>
        <div className="flex space-x-3">
          <button
            className="px-6 py-2 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
            onClick={handleSubmit}
          >
            {mode === Mode.ADD ? "Create" : "Update"}
          </button>
          {mode === Mode.EDIT && (
            <button
              className="px-6 py-2 border-2 border-red-400 text-red-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
              onClick={handleDelete}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminArticleDetail;
