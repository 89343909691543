import React, { useEffect, useState } from "react";
import { adminApi } from "../../api";
import DayRetentionTable from "../../components/analytics/DayRetentionTable";
import WeekRetentionTable from "../../components/analytics/WeekRetentionTable";

const AdminRetention = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    adminApi.getRetention().then((r) => {
      setData(r.data || {});
    });
  }, []);

  return (
    <div className="text-center flex flex-col items-center flex-wrap">
      <p className="text-4xl font-bold pb-3">CBT Retention</p>
      <DayRetentionTable data={data} />
      <hr className="m-4" />
      <WeekRetentionTable data={data} />
    </div>
  );
};

export default AdminRetention;
