import React from "react";

class Policy extends React.Component {
  render() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const lang = params.get('hl');
    if(lang == "Thai") {
      return (
        <div class="reset-tw" >
        <h1>นโยบายความเป็นส่วนตัวสำหรับลูกค้า</h1>
        <p>บริษัท รันเน็กซ์เทคโนโลยี จำกัด ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ ของเจ้าของข้อมูลส่วนบุคคล ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล</p>
        
        <h2>การเก็บรวบรวมข้อมูลส่วนบุคคล</h2>
        <p>
        เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง ดังต่อไปนี้
        </p><ul>
        <li>การสมัครสมาชิก</li>
        <li>อีเมล</li>
        <li>Google Login</li>
        </ul>
        <p></p>
        
        <h2>ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</h2>
        <p><b>ข้อมูลการติดต่อ</b> เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล เป็นต้น</p>
        <p><b>ข้อมูลบัญชี</b> เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น</p>
        <p><b>ข้อมูลทางเทคนิค</b> เช่น IP address, Cookie ID, ประวัติการใช้งานเว็บไซต์ (Activity Log) เป็นต้น</p>
        <p><b>ข้อมูลอื่น ๆ</b> เช่น รูปภาพ ภาพเคลื่อนไหว และข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล</p>
        
        <p>เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลอ่อนไหว ดังต่อไปนี้ เมื่อเราได้รับความยินยอมโดยชัดแจ้งจากคุณ เว้นแต่กฎหมายกำหนดให้ทำได้</p>
        <ul>
        <li>ข้อมูลสุขภาพ</li>
        </ul>
        <p>ข้อมูลอื่นใดที่กระทบต่อข้อมูลส่วนบุคคลของคุณตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด</p>
        
        <h2>ผู้เยาว์</h2>
        <p>หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้ หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา</p>
        
        <h2>วิธีการเก็บรักษาข้อมูลส่วนบุคคล</h2>
        <p>เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์</p>
        <p>เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้</p>
        <ul>
        <li>ผู้ให้บริการเซิร์ฟเวอร์ในต่างประเทศ</li>
        </ul>
        
        <h2>การประมวลผลข้อมูลส่วนบุคคล</h2>
        <p>เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้</p>
        <ul>
        <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</li>
        <li>เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน</li>
        <li>เพื่อการบริหารจัดการภายในบริษัท</li>
        <li>เพื่อการตลาดและการส่งเสริมการขาย</li>
        <li>เพื่อรวบรวมข้อเสนอแนะ</li>
        <li>เพื่อชำระค่าสินค้าหรือบริการ</li>
        <li>เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</li>
        </ul>
        
        <h2>การเปิดเผยข้อมูลส่วนบุคคล</h2>
        <p>เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่ผู้อื่นภายใต้ความยินยอมของคุณหรือที่กฎหมายอนุญาตให้เปิดเผยได้ ดังต่อไปนี้</p>
        
        <p><b>การบริหารจัดการภายในองค์กร</b></p>
        <p>เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณภายในบริษัทเท่าที่จำเป็นเพื่อปรับปรุงและพัฒนาสินค้าหรือบริการของเรา เราอาจรวบรวมข้อมูลภายในสำหรับสินค้าหรือบริการต่าง ๆ ภายใต้นโยบายนี้เพื่อประโยชน์ของคุณและผู้อื่นมากขึ้น</p>
        
        <p><b>การถ่ายโอนธุรกิจ</b></p>
        <p>เราอาจเปิดเผยข้อมูล รวมถึงข้อมูลส่วนบุคคลของคุณ สำหรับการปรับโครงสร้างองค์กร การควบรวมหรือการขายกิจการ หรือการถ่ายโอนสินทรัพย์อื่น ๆ โดยฝ่ายที่รับโอนต้องปฏิบัติกับข้อมูลของคุณในลักษณะที่สอดคล้องกับนโยบายนี้ รวมถึงกฎหมายคุ้มครองข้อมูลส่วนบุคคลด้วย</p>
        
        <p><b>การโอนข้อมูลส่วนบุคคลไปต่างประเทศ</b></p>
        <p>เราอาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของคุณไปยังบุคคล องค์กร หรือเซิร์ฟเวอร์ (Server) ที่ตั้งอยู่ในต่างประเทศ โดยเราจะดำเนินการตามมาตรการต่าง ๆ เพื่อให้มั่นใจว่าการโอนข้อมูลส่วนบุคคลของคุณไปยังประเทศปลายทางนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลอย่างเพียงพอ หรือกรณีอื่น ๆ ตามที่กฎหมายกำหนด</p>
        
        <p>ในกรณีที่มีการเปิดเผยหรือโอนข้อมูลส่วนบุคคลจากเขตเศรษฐกิจยุโรป (EEA) ไปยังสหรัฐอเมริกา และประเทศอื่นนอกเขตเศรษฐกิจยุโรป (Non-EEA)  เราจะจัดให้มีมาตรการที่เหมาะสมในการโอนข้อมูลส่วนบุคคลไปยังต่างประเทศต่าง ๆ ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลของสหภาพยุโรป (GDPR) กำหนดหรือที่กฎหมายอนุญาตให้กระทำได้</p>
        
        <h2>ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</h2>
        <p>เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้ ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้ เราจะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้ เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว</p>
        
        <h2>สิทธิของเจ้าของข้อมูลส่วนบุคคล</h2>
        <p>ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล  คุณมีสิทธิในการดำเนินการดังต่อไปนี้</p>
        
        <p><b>สิทธิขอถอนความยินยอม (right to withdraw consent)</b> หากคุณได้ให้ความยินยอม เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา</p>
        
        <p><b>สิทธิขอเข้าถึงข้อมูล (right to access)</b> คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเราและขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ  รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร</p>
        
        <p><b>สิทธิขอถ่ายโอนข้อมูล (right to data portability)</b> คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค</p>
        
        <p><b>สิทธิขอคัดค้าน (right to object)</b>  คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้ หากการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเราหรือของบุคคลหรือนิติบุคคลอื่น โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์</p>
        
        <p><b>สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction)</b> คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวคุณได้ หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้ หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว</p>
        
        <p><b>สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing)</b> คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน</p>
        
        <p><b>สิทธิขอให้แก้ไขข้อมูล (right to rectification)</b> คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด</p>
        
        <p><b>สิทธิร้องเรียน (right to lodge a complaint)</b> คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง หากคุณเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง</p>
        
        <p>หากคุณอยู่ในเขตเศรษฐกิจยุโรป (EEA) นอกจากสิทธิของเจ้าของข้อมูลส่วนบุคคลดังกล่าวข้างต้นแล้ว คุณมีสิทธิที่จะยื่นเรื่องร้องเรียนกับสำนักงานคุ้มครองข้อมูลส่วนบุคคลในประเทศของคุณ หากคุณเชื่อว่าเราไม่ได้ปฏิบัติตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล</p>
        
        <p>คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้ โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราตามรายละเอียดท้ายนโยบายนี้ เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้ หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง ๆ เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น</p>
        
        <h2>กฎหมายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย สหรัฐอเมริกา (CCPA)</h2>
        <p>แนวปฏิบัติเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลตามกฎหมายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย สหรัฐอเมริกา 2018 (CCPA) โปรดดูนโนบายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย (CCPA Privacy Policy)</p>
        
        <h2>การโฆษณาและการตลาด</h2>
        
        <p>เพื่อประโยชน์ในการได้รับสินค้าหรือบริการของเรา เราใช้ข้อมูลของคุณเพื่อวิเคราะห์และปรับปรุงสินค้าหรือบริการ และทำการตลาดผ่าน Google, Facebook, pixel tracking code และอื่น ๆ เราใช้ข้อมูลดังกล่าวเพื่อให้สินค้าหรือบริการเหมาะสมกับคุณ</p>
        
        <p>เราจะไม่ใช้ระบบการตัดสินใจอัตโนมัติ (automated decision-making)โดยปราศจากการบุคคลกำกับดูแล รวมถึงการทำโปรไฟล์ลิ่ง (profiling) ในลักษณะที่ก่อให้เกิดผลกระทบกับคุณอย่างมีนัยสำคัญ</p>
        
        <h2>เทคโนโลยีติดตามตัวบุคคล (Cookies)</h2>
        <p>เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์และมีประสิทธิภาพมากขึ้น เราใช้คุกกี้ (Cookies)หรือเทคโนโลยีที่คล้ายคลึงกัน เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม และติดตามการใช้งานของคุณ เราใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์และการเข้าถึงเว็บไซต์ของเรา หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของเราได้</p>
        
        <h2>การรักษาความมั่งคงปลอดภัยของข้อมูลส่วนบุคคล</h2>
        <p>เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity) และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard) และมาตรการป้องกันทางกายภาพ (physical safeguard) ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)</p>
        
        <h2>การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล</h2>
        <p>ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน 72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้ ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง ๆ เช่น  เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น</p>
        
        <h2>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h2>
        <p>เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา</p>
        <p>นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 15 พฤษภาคม 2566</p>
        
        <h2>นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น</h2>
        <p>นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ และการใช้งานบนเว็บไซต์สำหรับลูกค้าของเราเท่านั้น หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย</p>
        
        <h2>รายละเอียดการติดต่อ</h2>
        <p>หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราได้ ดังนี้</p>
        
        <p><b>ผู้ควบคุมข้อมูลส่วนบุคคล</b></p>
        <p>บริษัท รันเน็กซ์เทคโนโลยี จำกัด</p>
        
        <p>
        842 เสนาวิลล่า84 ถนนแฮบปี้แลนด์  คลองจั่น บางกะปิ กรุงเทพมหานคร 10240
        </p>
        
        <p>อีเมล runnextechnology@gmail.com</p>
        <p>เว็บไซต์ https://fitforce2.com</p>
        <p>หมายเลขโทรศัพท์ 0888983283</p>
        
        <p><b>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</b></p>
        <p>ทีม รันเน็กซ์เทคโนโลยี</p>
        
        <p>
        842 เสนาวิลล่า84 ถนนแฮบปี้แลนด์  คลองจั่น บางกะปิ กรุงเทพมหานคร 10240
        </p>
        
        <p>อีเมล keerati.tan@gmail.com</p>
        <p>หมายเลขโทรศัพท์ 0888983283</p>
        
        <br/><br/><br/><hr/><br/><br/><br/>
        <h1>นโยบายความเป็นส่วนตัวของผู้บริโภครัฐแคลิฟอร์เนีย (CCPA Policy)</h1>
        
        <p>บริษัท รันเน็กซ์เทคโนโลยี จำกัด ให้ความสำคัญกับความเป็นส่วนตัว และการรักษาความปลอดภัยข้อมูลส่วนบุคคลของคุณ  นโยบายความเป็นส่วนตัวฉบับนี้อธิบายแนวปฏิบัติการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวข้องกับผู้พักอาศัยในรัฐแคลิฟอร์เนียตามกฎหมายความเป็นส่วนตัวของผู้บริโภคแห่งรัฐแคลิฟอร์เนีย (CCPA) ปี 2018</p>
        
        <h2>สิทธิของคุณตาม CCPA</h2>
        <p>นอกจากสิทธิตามที่กำหนดไว้ในนโยบายฉบับนี้ ผู้อยู่อาศัยในแคลิฟอร์เนียมีสิทธิต่าง ๆ ดังต่อไปนี้</p>
        <br/>
        <h3>สิทธิในการได้รับการแจ้ง</h3>
        <p>เราเก็บรวบรวมข้อมูลส่วนบุคคลของผู้พักอาศัยในรัฐแคลิฟอร์เนียประเภทต่าง ๆ และเปิดเผยแก่บุคคลภายนอกหรือผู้ให้บริการเพื่อจุดประสงค์ทางธุรกิจตามที่ระบุไว้ในนโยบายฉบับนี้ ทั้งนี้ ในช่วง 12 เดือนที่ผ่านมา เราอาจได้เก็บรวบรวมข้อมูลส่วนบุคคลประเภทต่าง ๆ ต่อไปนี้ โดยขึ้นอยู่กับการปฏิสัมพันธ์ของคุณกับเรา</p>
        
        <p><b>ประเภทข้อมูลส่วนบุคคลตาม CCPA</b></p>
        <p>ข้อมูลที่ระบุตัวตน เช่น ชื่อ นามสกุล เพศ สัญชาติ บัตรประจำตัวประชาชน หนังสือเดินทาง ข้อมูลการติดต่อ เป็นต้น</p>
        
        <p>ข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลของรัฐแคลิฟอร์เนีย เช่น ข้อมูลทางการเงิน รายละเอียดบัตรเครดิต บัญชีธนาคาร เป็นต้น</p>
        
        <p>ประเภทข้อมูลที่ได้รับการคุ้มครองภายใต้กฎหมายของรัฐแคลิฟอร์เนียหรือกฎหมายของรัฐบาลกลาง เช่น ความทุพพลภาพ หรือลักษณะทางกายภาพอื่น ๆ เป็นต้น</p>
        
        <p>ข้อมูลทางการค้า เช่น ข้อมูลการซื้อสินค้าหรือบริการ เป็นต้น</p>
        
        <p>กิจกรรมทางอินเทอร์เน็ตหรือบนเครือข่ายอิเล็กทรอนิกส์ เช่น ประวัติการเข้าชม ประวัติการค้นหา เป็นต้น</p>
        
        <p>ข้อมูลตำแหน่งที่ตั้งตามพิกัดทางภูมิศาสตร์ เช่น ตำแหน่งที่ตั้ง เป็นต้น</p>
        
        <p>ข้อมูลเสียง สื่ออิเล็กทรอนิกส์ ภาพ หรือข้อมูลที่คล้ายคลึงกัน เช่น บันทึกเสียงและวิดีโอ เป็นต้น</p>
        
        <p>ข้อมูลวิชาชีพหรือเกี่ยวกับการจ้างงาน เช่น ประวัติการทำงาน เป็นต้น</p>
        
        <p>ข้อมูลทางการศึกษา เช่น ระดับการศึกษา ประกาศนียบัตร เป็นต้น</p>
        
        <p>ข้อมูลอื่น ๆ เช่น โปรไฟล์ ความชื่นชอบ หรือคุณลักษณะของคุณ เป็นต้น</p>
        
        <br/>
        
        <p>แหล่งที่มาของข้อมูล ได้แก่ ข้อมูลที่คุณให้เราโดยตรง การมีปฏิสัมพันธ์ระหว่างคุณกับเรา ข้อมูลจากพันธมิตรทางธุรกิจหรือผู้ให้บริการ เป็นต้น</p>
        
        <br/>
        
        <p>เราใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ทางธุรกิจดังต่อไปนี้</p>
        <ul>
        <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</li>
        <li>เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน</li>
        <li>เพื่อการบริหารจัดการภายในบริษัท</li>
        <li>เพื่อการตลาดและการส่งเสริมการขาย</li>
        <li>เพื่อรวบรวมข้อเสนอแนะ</li>
        <li>เพื่อชำระค่าสินค้าหรือบริการ</li>
        <li>เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</li>
        </ul>
        
        
        <h3>สิทธิขอข้อมูลส่วนบุคคล</h3>
        <p>คุณมีสิทธิขอให้เราเปิดเผยการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในช่วง 12 เดือนที่ผ่านมา รวมถึงประเภทของข้อมูลส่วนบุคคล วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล ประเภทแหล่งที่มาของข้อมูล ประเภทบุคคลที่สามที่เราเปิดเผยข้อมูลให้ในวัตถุประสงค์ทางธุรกิจ และวัตถุประสงค์ของเราในการดำเนินการดังกล่าว</p>
        
        
        <h3>สิทธิขอให้ลบข้อมูลส่วนบุคคล</h3>
        <p>คุณมีสิทธิขอให้เราลบข้อมูลส่วนบุคคลของคุณที่เราได้เก็บหรือรักษาไว้ ในการขอใช้สิทธิลบข้อมูลส่วนบุคคล คุณสามารถส่งคำขอมายังอีเมลของเราได้ ทั้งนี้ โปรดระบุข้อมูลส่วนบุคคลที่คุณที่ต้องการลบหรือจะขอให้เราลบข้อมูลส่วนบุคคลของคุณทั้งหมดตามที่ CCPA กำหนดก็ได้</p>
        
        
        <h3>สิทธิในการไม่เลือกปฏิบัติ</h3>
        <p>ตามกฎหมาย CCPA ห้ามไม่ให้เลือกปฏิบัติต่อผู้พักอาศัยแคลิฟอร์เนียในการใช้สิทธิตามกฎหมาย CCPA ดังนี้ คุณจะไม่ได้รับการถูกเลือกปฏิบัติในการขอใช้สิทธิของคุณตามกฎหมาย CCPA</p>
        
        
        <h3>สิทธิเลือกความยินยอมในการไม่จำหน่ายข้อมูลส่วนบุคคล</h3>
        <p>คุณมีสิทธิเลือกให้ความยินยอมหรือไม่ให้ความยินยอมในการจำหน่ายข้อมูลส่วนบุคคล ทั้งนี้ เราไม่ได้จำหน่ายข้อมูลส่วนบุคคลของคุณ รวมถึงข้อมูลส่วนบุคคลของผู้พักอาศัยแคลิฟอร์เนีย</p>
        
        
        <h2>รายละเอียดการติดต่อ</h2>
        <p>หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราได้ ดังนี้</p>
        
        <p>บริษัท รันเน็กซ์เทคโนโลยี จำกัด</p>
        
        <p>
        842 เสนาวิลล่า84 ถนนแฮบปี้แลนด์  คลองจั่น บางกะปิ กรุงเทพมหานคร 10240
        </p>
        
        <p>อีเมล runnextechnology@gmail.com</p>
        <p>เว็บไซต์ https://fitforce2.com</p>
        <p>หมายเลขโทรศัพท์ 0888983283</p>
        
        </div>
      )
    }
    return (
      <div class="reset-tw" >
<h1>Privacy Policy for Customer</h1>
<p>Runnex Technology Co., Ltd. recognizes the importance of the protection of your personal data. This Privacy Policy explains our practices regarding the collection, use or disclosure of personal data including other rights of the Data Subjects in accordance with the Personal Data Protection Laws.</p>

<h2>Collection of Personal Data</h2>
<p>
We will collect your personal data that receive directly from you as following:
</p><ul>
<li>your account registration</li>
<li>email address</li>
<li>Google Login</li>
</ul>
<p></p>

<h2>Types of Data Collected</h2>
<p><b>Contact information</b> such as address, telephone number, e-mail address, etc.</p>
<p><b>Account details</b> such as username, password, transactions history, etc.</p>
<p><b>Technical data</b> such as IP address, Cookie ID, Activity Log, etc.</p>
<p><b>Other</b> such as photo, video, and other information that is considered personal data under the Personal Data Protection Laws.</p>

<p>We may collect, use or disclose your sensitive data that is specially categorized by law when we have obtained explicit consent from you or where necessary for us as permissible under law. We may collect, use or disclose your sensitive personal data as following:</p>
<ul>
<li>health data</li>
</ul>
<p>Any data which may affect the data subject in the same manner, as prescribed by the Personal Data Protection Committee.</p>

<h2>Children</h2>
<p>If you are under the age of 20 or having legal restrictions, we may collect use or disclose your personal data. We require your parents or guardian to be aware and provide consent to us or allowed by applicable laws. If we become aware that we have collected personal data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

<h2>Storage of Data</h2>
<p>We store your personal data as hard copy and soft copy.</p>
<p>We store your personal data by using the following systems:</p>
<ul>
<li>Third-party server service providers outside of Thailand</li>
</ul>

<h2>Use of Data</h2>
<p>We use the collected data for various purposes:</p>
<ul>
<li>To create and manage accounts</li>
<li>To improve products, services, or user experiences</li>
<li>To share and manage information within organization</li>
<li>To conduct marketing activities and promotions</li>
<li>To gather user’s feedback</li>
<li>To process payments of products or services</li>
<li>To comply with our Terms and Conditions</li>
</ul>

<h2>Disclosure of Personal Data</h2>
<p>We may disclose your personal data to the following parties in certain circumstances:</p>

<p><b>Organization</b></p>
<p>We may disclose your personal data within our organization to provide and develop our products or services. We may combine information internally across the different products or services covered by this Privacy Policy to help us be more relevant and useful to you and others.</p>

<p><b>Business Transfer</b></p>
<p>In connection with any reorganization, restructuring, merger or acquisition, or other transfer of assets, we will transfer information, including certain your personal data, provided that the receiving party agrees to respect your personal data in a manner that is consistent with this Privacy Policy and the Personal Data Protection Laws.</p>

<p><b>Cross-Border Data Transfer</b></p>
<p>We may disclose or transfer your personal data to third parties, organizations or servers located in foreign countries. We will take steps and measures to ensure that your personal data is securely transferred, and the receiving parties have an appropriate level of personal data protection standard or as allowed by laws.</p>

<p>With respect to transfers originating from the European Economic Area (EEA) to the United States and other non-EEA jurisdictions, we will have appropriate solutions to address cross-border transfers as required or permitted by the General Data Protection Regulation.</p>

<h2>Data Retention</h2>
<p>We will retain your personal data for as long as necessary during the period you are a customer or under relationship with us, or for as long as necessary in connection with the purposes set out in this Privacy Policy, unless law requires or permits a longer retention period. We will erase, destroy or anonymize your personal data when it is no longer necessary or when the period lapses.</p>

<h2>Data Subject Rights</h2>
<p>Subject to the Personal Data Protection Laws thereof, you may exercise any of these rights in the following:</p>

<p><b>Withdrawal of consent</b>: If you have given consent to us to collect, use or disclose your personal data whether before or after the effective date of the Personal Data Protection Laws, you have the right to withdraw such consent at any time throughout the period your personal data available to us, unless it is restricted by laws or you are still under beneficial contract.</p>

<p><b>Data access</b>: You have the right to access your personal data that is under our responsibility; to request us to make a copy of such data for you; and to request us to reveal as to how we obtain your personal data.</p>

<p><b>Data portability</b>: You have the right to obtain your personal data if we organize such personal data in automatic machine-readable or usable format and can be processed or disclosed by automatic means; to request us to send or transfer the personal data in such format directly to other data controllers if doable by automatic means; and to request to obtain the personal data in such format sent or transferred by us directly to other data controller unless not technically feasible.</p>

<p><b>Objection</b>: You have the right to object to collection, use or disclosure of your personal data at any time if such doing is conducted for legitimate interests of us, corporation or individual which is within your reasonable expectation; or for carrying out public tasks.</p>

<p><b>Data erasure or destruction</b>: You have the right to request us to erase, destroy or anonymize your personal data if you believe that the collection, use or disclosure of your personal data is against relevant laws; or retention of the data by us is no longer necessary in connection with related purposes under this Privacy Policy; or when you request to withdraw your consent or to object to the processing as earlier described.</p>

<p><b>Suspension</b>: You have the right to request us to suspend processing your personal data during the period where we examine your rectification or objection request; or when it is no longer necessary and we must erase or destroy your personal data pursuant to relevant laws but you instead request us to suspend the processing.</p>

<p><b>Rectification</b>: You have the right to rectify your personal data to be updated, complete and not misleading.</p>

<p><b>Complaint lodging</b>: You have the right to complain to competent authorities pursuant to relevant laws if you believe that the collection, use or disclosure of your personal data is violating or not in compliance with relevant laws.</p>

<p>If you are in the European Economic Area (EEA) you have the rights as mentioned above and also you have the right to lodge a complaint with the local data protection authority if you believe that we have not complied with applicable Personal Data Protection laws.</p>

<p>You can exercise these rights as the Data Subject by contacting our Data Protection Officer as mentioned below. We will notify the result of your request within 30 days upon receipt of such request. If we deny the request, we will inform you of the reason via SMS, email address, telephone, registered mail (if applicable).</p>

<h2>California Consumer Privacy Act (CCPA)</h2>
<p>For information about our data processing practices as required by the California Consumer Privacy Act 2018, please view our CCPA Privacy Policy.</p>

<h2>Advertising and Marketing</h2>

<p>To enable you to receive benefits from using our products or services, we use your personal data to analyze, personalize and enhance our products or services, and marketing efforts through Google, Facebook, pixel tracking code and others. We use such information to provide you with customized and personalized recommendations for products or services we think will be of interest to you.</p>

<p>We do not use automated decision-making without human intervention, including profiling, in a way that produces legal effects concerning you or otherwise significantly affects you.</p>

<h2>Cookies</h2>
<p>To enrich and perfect your experience, we use cookies or similar technologies to display personalized content, appropriate advertising and store your preferences on your computer. We use cookies to identify and track visitors, their usage of our website and their website access preferences. If you do not wish to have cookies placed on your computer you should set their browsers to refuse cookies before using our website.</p>

<h2>Data Security</h2>
<p>We endeavor to protect your personal data by establishing security measures in accordance with the principles of confidentiality, integrity, and availability to prevent loss, unauthorized or unlawful access, destruction, use, alteration, or disclosure including administrative safeguard, technical safeguard, physical safeguard and access controls.</p>

<h2>Data Breach Notification</h2>
<p>We will notify the Office of the Personal Data Protection Committee without delay and, where feasible, within 72 hours after having become aware of it, unless such personal data breach is unlikely to result in a risk to the rights and freedoms of you. If the personal data breach is likely to result in a high risk to the rights and freedoms of you, we will also notify the personal data breach and the remedial measures to you without delay through our website, SMS, email address, telephone or registered mail (if applicable).</p>

<h2>Changes to this Privacy Policy</h2>
<p>We may change this Privacy Policy from time to time. Any changes of this Privacy Policy, we encourage you to frequently check on our website.</p>
<p>This Privacy Policy was last updated and effective on 15th May 2023</p>

<h2>Links to Other Sites</h2>
<p>The purpose of this Privacy Policy is to offer products or services and use of our website. Any websites from other domains found on our site is subject to their privacy policy which is not related to us.</p>

<h2>Contact Information</h2>
<p>If you have any questions about this Privacy Policy or would like to exercise your rights, you can contact us by using the following details:</p>

<p><b>Data Controller</b></p>
<p>Runnex Technology Co., Ltd.</p>

<p>
842 Senavilla84 Happyland rd., Khlong Chan, Bang Kapi, Bangkok, 10240
</p>

<p>runnextechnology@gmail.com</p>
<p>https://fitforce2.com</p>
<p>+66888983283</p>

<p><b>Data Protection Officer</b></p>
<p>Runnex Technology team</p>

<p>
842 Senavilla84 Happyland rd., Khlong Chan, Bang Kapi, Bangkok, 10240
</p>

<p>keerati.tan@gmail.com</p>
<p>+66888983283</p>

<br/><br/><br/><hr/><br/><br/><br/>
<h1>California Consumer Privacy Act Policy</h1>

<p>Runnex Technology Co., Ltd. takes the privacy and security of your personal information. This Privacy Policy describes our practice on how we collect, use, or disclose personal information relating to California residents under the California Consumer Privacy Act of 2018 (CCPA).</p>

<h2>Your Rights Under the CCPA</h2>
<p>In addition to the rights as explained in the Privacy Policy, California residents has the rights with the following rights:</p>

<h3>Right to Notification</h3>
<p>We collect the following categories of personal Information about California residents and disclose to third parties or service providers for the business purposes listed. In the past 12 months, we may have, depending on your interactions with us, collected the following categories of personal information.</p>

<p><b>CCPA Personal Information Categories</b></p>
<p>Identifiers such as name, surname, gender, nationality, identification card, passport, contact information, etc.</p>

<p>Personal information, as defined in the California safeguards law, such as financial information, credit card details, bank account, etc.</p>

<p>Protected classifications under California or federal law, such as disability status or other attributes, etc.</p>

<p>Commercial information such as information regarding products or services purchased, etc.</p>

<p>Internet or other electronic network activities such as browsing history, search history, etc.</p>

<p>Geolocation data such as location, etc.</p>

<p>Audio, electronic, visual, or similar information, such as call and video recordings, etc.</p>

<p>Professional or employment-related information such as work history, etc.</p>

<p>Education such as degrees, certificates, etc.</p>

<p>Other information such as your profile, your preferences, and characteristics, etc.</p>

<br/>

<p>Sources of Information such as information you provide directly to us, your interactions with us and information from our business partners or service providers, etc.</p>

<br/>

<p>We use personal information for the following business purposes:</p>
<ul>
<li>To create and manage accounts</li>
<li>To improve products, services, or user experiences</li>
<li>To share and manage information within organization</li>
<li>To conduct marketing activities and promotions</li>
<li>To gather user’s feedback</li>
<li>To process payments of products or services</li>
<li>To comply with our Terms and Conditions</li>
</ul>


<h3>Right to Request for Personal Information</h3>
<p>You have the right to request that how we have collected, used, or disclosed your personal information over the past 12 months, including the categories of personal information we collected and our purposes for doing so; the categories of sources for that information; the categories of third parties with whom we disclosed it for a business purpose and our purposes for doing so.</p>


<h3>Right to Request Deletion of Personal Information</h3>
<p>You have the right to request the deletion of personal information that we collect or maintain about you. To submit a request to delete personal information, please submit a request to our email address as below. Please specify in your request the personal information about you that you would like to have deleted, which can be all your personal information as required by the CCPA.</p>


<h3>Right to Non-Discrimination</h3>
<p>The CCPA prohibits discrimination against California residents for exercising their rights under the CCPA. In this regard, you may not be discriminated against because you exercise any of your rights under the CCPA.</p>


<h3>Right to Opt-Out of the Sale of Personal Information</h3>
<p>You have the right to opt-out of the sale of your personal information. We do not sell your personal information including California residents’ personal information.</p>


<h2>Contact Information</h2>
<p>If you have any questions about this Privacy Policy or would like to exercise your rights, you can contact us by using the following details:</p>

<p>Runnex Technology Co., Ltd.</p>

<p>
842 Senavilla84 Happyland rd., Khlong Chan, Bang Kapi, Bangkok, 10240
</p>

<p>runnextechnology@gmail.com</p>
<p>https://fitforce2.com</p>
<p>+66888983283</p>
      </div>
    );
  }
}

export default Policy;
