import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { adminApi } from "../api";
import { capitalizeObjectKeys } from "../helper/misc";

const PromoCodeForm = ({ refetch }) => {
  const [collapse, setCollapse] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rewardChoices, setRewardChoices] = useState([]);

  const formik = useFormik({
    initialValues: {
      seriesName: "",
      code: "",
      rewardType: "",
      limitCount: 999999,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      seriesName: Yup.string().required("Required"),
      code: Yup.string().required("Required").max(14),
      rewardType: Yup.string().required("Required"),
      limitCount: Yup.number().required("Required").min(1),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const body = capitalizeObjectKeys(values);
      adminApi
        .createUniversalPromoCode(body)
        .then(() => {
          formik.resetForm();
          refetch();
          setCollapse(true);
        })
        .catch((e) => {
          alert(e.response?.data?.error || "Something went wrong");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    adminApi.getPromoCodeRewards().then((r) => {
      setRewardChoices(r.data?.data || []);
      if (r.data?.data?.length > 0) {
        formik.setFieldValue("rewardType", r.data.data[0].rewardType);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {collapse ? (
        <button
          className="flex items-center justify-center px-12 py-2 border text-gray-500 rounded-lg hover:text-gray-700"
          onClick={() => setCollapse(false)}
        >
          Add New Promo Code
        </button>
      ) : (
        <section className="bg-white dark:bg-gray-900">
          <div className="max-w-2xl p-4 px-8 border border-dashed border-teal-400 rounded-lg">
            <div className="flex justify-between">
              <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">Add New Promo Code</h2>
              <button
                className="flex items-center justify-center w-8 h-8 text-gray-500 rounded-full hover:text-gray-700"
                onClick={() => setCollapse(true)}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Series Name
                </label>
                <input
                  type="text"
                  name="seriesName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="ex. Fitforce 2 Open Beta Campaign"
                  onChange={formik.handleChange}
                  value={formik.values.seriesName}
                />
                <p className="text-red-400 text-xs m-1">{formik.errors.seriesName}</p>
              </div>
              <div className="w-full">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Code</label>
                <input
                  type="text"
                  name="code"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="ex. HAPPYFITFORCE2"
                  uppercase
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();
                    if (!/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                      e.target.value = e.target.value.slice(0, e.target.value.length - 1);
                    }
                    formik.handleChange(e);
                  }}
                  value={formik.values.code}
                />
                <p className="text-red-400 text-xs m-1">{formik.errors.code}</p>
              </div>
              <div className="w-full">
                <label
                  htmlFor="price"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Limit Usage
                </label>
                <input
                  type="text"
                  name="limitCount"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="ex. 9999"
                  onChange={(e) => {
                    if (e.target.value.length > 6) {
                      e.target.value = e.target.value.slice(0, 6);
                    } else if (e.target.value.includes("e")) {
                      e.target.value = e.target.value.slice(0, e.target.value.length - 1);
                    }
                    formik.handleChange(e);
                  }}
                  value={formik.values.limitCount}
                />
                <p className="text-red-400 text-xs m-1">{formik.errors.limitCount}</p>
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Reward</label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  name="rewardType"
                  onChange={formik.handleChange}
                  value={formik.values.rewardType}
                >
                  {rewardChoices.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <p className="text-red-400 text-xs m-1">{formik.errors.rewardType}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4 mt-8">
              <button
                className="text-black min-w-[12rem] border-gray-300 rounded-lg hover:bg-gray-100 text-sm px-5 py-2.5 text-center border cursor-pointer"
                disabled={loading}
                onClick={formik.handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PromoCodeForm;
