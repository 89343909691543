import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { adminApi } from "../../api";
import ChartByDateDefault from "../../components/ChartByDateDefault";
import ChartDonutDefault from "../../components/ChartDonutDefault";

const AdminOverview = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    adminApi.getOverview().then((r) => {
      setData(r.data || {});
    });
  }, []);

  const singles = useMemo(() => {
    if (data) {
      const registeredYesterday =
        data?.RegisteredCountByDate?.find((x) => x._id === moment().subtract(1, "days").format("YYYY-MM-DD"))
          ?.playercount || 0;
      const activatedYesterday =
        data?.ActivatedCountByDate?.find((x) => x._id === moment().subtract(1, "days").format("YYYY-MM-DD"))
          ?.playercount || 0;

      return [
        { title: "Overall Registered User Count", value: data?.OverallRegisteredUserCount },
        { title: "Overall Activated User Count", value: data?.OverallActivatedUserCount },
        { title: "Registered Yesterday", value: registeredYesterday },
        { title: "Activated Yesterday", value: activatedYesterday },
        { title: "Overall Fake Purchase Count", value: data?.OverallFakePurchaseCount },
        { title: "Overall Purchase Percent", value: Math.floor(data?.OverallPurchasePercent || 0) + "%" },
      ];
    }
    return [];
  }, [data]);

  const PlayerGroupByOSPlatform = useMemo(() => {
    if (data?.PlayerGroupByOSPlatform) {
      let result = data.PlayerGroupByOSPlatform.reduce((acc, item) => {
        let os = item._id.split(" ")[0].toLowerCase();
        if (os === "android") {
          os = "Android";
        } else if (os === "windows") {
          os = "Windows";
        } else {
          os = "iOS";
        }
        acc[os] = (acc[os] || 0) + item.count;
        return acc;
      }, {});

      let finalResult = Object.entries(result).map(([os, count]) => ({
        _id: os,
        count: count,
      }));

      return finalResult;
    }
    return [];
  }, [data?.PlayerGroupByOSPlatform]);

  return (
    <div className="text-center flex flex-col items-center flex-wrap">
      <p className="text-4xl font-bold pb-3">CBT Overview</p>
      <div className="flex flex-wrap py-1 justify-center">
        {singles.map((x, i) => (
          <div
            key={i}
            className="w-44 m-2 flex flex-col items-center justify-center py-4 px-6 rounded-2xl bg-slate-100 shadow-md"
          >
            <p className="text-5xl font-semibold leading-none tracking-tight">{x.value}</p>
            <p className="mt-4 text-sm font-medium text-center">{x.title}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap py-3 justify-center w-full">
        <div className="text-left indent-4 h-[360px] px-4 w-1/2 lg:w-1/4 mt-4">
          <ChartDonutDefault title="Gender" data={data?.PlayerGroupByGender} />
        </div>
        <div className="text-left indent-4 h-[360px] px-4 w-1/2 lg:w-1/4 mt-4">
          <ChartDonutDefault title="Age" data={data?.PlayerGroupByAgeRange} />
        </div>
        <div className="text-left indent-4 h-[360px] px-4 w-1/2 lg:w-1/4 mt-4">
          <ChartDonutDefault title="Job" data={data?.PlayerGroupByJob} />
        </div>
        <div className="text-left indent-4 h-[360px] px-4 w-1/2 lg:w-1/4 mt-4">
          <ChartDonutDefault title="OS Platform" data={PlayerGroupByOSPlatform} />
        </div>
      </div>
      <div className="flex flex-wrap py-3 justify-center w-full">
        <div className="text-left indent-4 h-[360px] px-4 w-full lg:w-1/2 mt-12">
          Register-Activate Count By Date
          <ChartByDateDefault
            data={data}
            fieldOptions={{
              key1Series: "RegisteredCountByDate",
              key1Name: "Register Count",
              key1Value: "playercount",
              key2Series: "ActivatedCountByDate",
              key2Name: "Activate Count",
              key2Value: "playercount",
            }}
          />
        </div>
        <div className="text-left indent-4 h-[360px] px-4 w-full lg:w-1/2 mt-12">
          Start-End Game Count By Date
          <ChartByDateDefault
            data={data}
            fieldOptions={{
              key1Series: "StartGameCountByDate",
              key1Name: "Start Game Count",
              key1Value: "startcount",
              key2Series: "EndGameCountByDate",
              key2Name: "End Game Count",
              key2Value: "endcount",
            }}
          />
        </div>
        <div className="text-left indent-4 h-[360px] px-4 w-full lg:w-1/2 mt-12">
          First Play By Date
          <ChartByDateDefault
            data={data}
            fieldOptions={{
              key1Series: "FirstStartGameByDate",
              key1Name: "First Play Count",
              key1Value: "playercount",
            }}
          />
        </div>
        <div className="text-left indent-4 h-[360px] px-4 w-full lg:w-1/2 mt-12">
          Last Login By Date
          <ChartByDateDefault
            data={data}
            fieldOptions={{
              key1Series: "LastLoginByDate",
              key1Name: "Last Login Count",
              key1Value: "playercount",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminOverview;
