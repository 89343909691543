import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const chartOptions = {
  chart: {
    animations: {
      enabled: false,
    },
    fontFamily: "inherit",
    foreColor: "inherit",
    height: "100%",
    type: "area",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  tooltip: {
    followCursor: true,
    theme: "dark",
    x: {
      format: "MMM dd, yyyy",
    },
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    labels: {
      rotate: 0,
      style: {
        colors: "darkgray",
      },
      datetimeUTC: false,
    },
    tickAmount: 3,
    tooltip: {
      enabled: false,
    },
    type: "datetime",
  },
  yaxis: {
    labels: {
      style: {
        colors: "lightgray",
      },
    },
    tickAmount: 5,
  },
};

function fillZeros(data, minDate, maxDate, endPadding = 0) {
  const result = [];
  const diff = maxDate.diff(minDate, "days");
  for (let i = -1; i <= diff + endPadding; i++) {
    const date = moment(minDate).add(i, "days");
    const value = data?.find((x) => x.x.isSame(date, "day"));
    result.push({
      x: date,
      y: value?.y || 0,
    });
  }
  return result;
}

const ChartByDateDefault = ({ data, fieldOptions }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (data) {
      const dataDates = data?.[fieldOptions?.key1Series]
        ?.map((x) => moment(x._id))
        .concat(fieldOptions?.key2Series ? data?.[fieldOptions?.key2Series]?.map((x) => moment(x._id)) : [])
        ?.filter((x) => !!x);

      const minDate = moment.min(dataDates) || moment();
      const maxDate = moment.max(dataDates) || moment();

      const startData = data?.[fieldOptions?.key1Series]?.map((x) => ({
        x: moment(x._id),
        y: x?.[fieldOptions?.key1Value],
      }));
      const endData = data?.[fieldOptions?.key2Series]?.map((x) => ({
        x: moment(x._id),
        y: x?.[fieldOptions?.key2Value],
      }));

      const newSeries = [
        {
          name: fieldOptions?.key1Name,
          data: fillZeros(startData, minDate, maxDate),
        },
      ].concat(
        fieldOptions?.key2Series
          ? [
              {
                name: fieldOptions?.key2Name,
                data: fillZeros(endData, minDate, maxDate),
              },
            ]
          : []
      );

      setSeries(newSeries);
    }
  }, [data, fieldOptions]);

  return (
    <ReactApexChart
      className="flex-auto w-full h-full"
      options={chartOptions}
      series={series}
      type={chartOptions.chart.type}
      height={chartOptions.chart.height}
    />
  );
};

export default ChartByDateDefault;
