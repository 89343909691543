import React from "react";

const CardInstallation = ({ title, children }) => {
  return (
    <div className="rounded p-6 mx-2 bg-gray-100 mb-4 w-[18rem] sm:w-80">
      <div className="block mb-4 break-normal">{title}</div>
      <div className="flex justify-center h-80">{children}</div>
    </div>
  );
};

export default CardInstallation;
