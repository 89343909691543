import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || "/api",
  headers: { "Content-Type": "application/json" },
});

const multipartHeader = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const authApi = {
  adminLogin(body) {
    return api.post("/auth/admin-login", body);
  },
};

export const sseApi = {
  createChannel() {
    return api.get("/sse/create-channel");
  },
  sendMessageServerTime() {
    return api.get("/sse/create-message");
  },
  url(key) {
    return process.env.REACT_APP_BACKEND_URL + "/sse/events/" + key;
  },
};

export const frontApi = {
  getPlayerByUid(uid) {
    return api.get("/auth/get-player/" + uid);
  },
  redeemCode(uid, code) {
    return api.post("/redeem-code", { UserID: uid, Code: code });
  },
};

export const adminApi = {
  getOverview() {
    return api.get("/admin/analytic-overview");
  },
  getRetention() {
    return api.get("/admin/analytic-retention");
  },
  getCBTUsers() {
    return api.get("/cbt/cbt-users");
  },
  getCBTFeedbacks() {
    return api.get("/cbt/cbt-feedbacks");
  },
  sendActivationCode(id) {
    return api.post("/cbt/email-activation-code/" + id);
  },
  getPromoCodes() {
    return api.get("/inventory/get-promocodes");
  },
  getPromoCodeRewards() {
    return api.get("/inventory/get-promocode-rewards");
  },
  createSeriesPromoCode(body) {
    return api.post("/inventory/create-series-promocode", body);
  },
  createUniversalPromoCode(body) {
    return api.post("/inventory/create-universal-promocode", body);
  },
};

export const uploadApi = {
  uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return api.post("/upload/single", formData, multipartHeader);
  },
};

export const articleApi = {
  getAll() {
    return api.get("/admin/articles");
  },
  get(key) {
    return api.get("/admin/article/" + key);
  },
  create(body) {
    return api.post("/admin/article", body);
  },
  update(id, body) {
    return api.put("/admin/article/" + id, body);
  },
  delete(id) {
    return api.delete("/admin/article/" + id);
  },
  view(slug) {
    return api.post("/admin/article-view/" + slug);
  },
};

export const redirectApi = {
  getAll() {
    return api.get("/admin/redirect-records");
  },
  create(body) {
    return api.post("/admin/redirect-record", body);
  },
  delete(id) {
    return api.delete("/admin/redirect-record/" + id);
  },
  increase(key) {
    return api.post("/admin/redirect-record-increase/" + key);
  },
};

export const quizApi = {
  getAll() {
    return api.get("/admin/questions");
  },
  getResult() {
    return api.get("/admin/results");
  },
  create(body) {
    return api.post("/admin/question-create", body);
  },
  update(id, body) {
    return api.put("/admin/question-update/" + id, body);
  },
  delete(id) {
    return api.delete("/admin/question-delete/" + id);
  },
};
