import React, { PureComponent } from "react";

export default class UserFlow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      InterestedDate: [],
      FirstLogin: {},
      ScanPage: {},
      ScanSucess: {},
      PlayGame: {},
      EndGame: {},
      Purchase: {},
    };
    var _this = this;
    fetch("/api/user-flow", {
      method: "POST",
      timeout: 0,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var date = {};
        var FirstLogin = {};
        data.firstLoginByDate.content.forEach((element) => {
          date[element._id] = true;
          FirstLogin[element._id] = element.nUser;
        });
        var ScanPage = {};
        data.firstScanByDate.content.forEach((element) => {
          date[element._id] = true;
          ScanPage[element._id] = element.nUser;
        });
        var ScanSucess = {};
        data.FirstSuccessScannedByDate.content.forEach((element) => {
          date[element._id] = true;
          ScanSucess[element._id] = element.nUser;
        });
        var PlayGame = {};
        data.firstPlayByDate.content.forEach((element) => {
          date[element._id] = true;
          PlayGame[element._id] = element.nUser;
        });
        var EndGame = {};
        data.firstEndByDate.content.forEach((element) => {
          date[element._id] = true;
          EndGame[element._id] = element.nUser;
        });
        var Purchase = {};
        data.firstPurchase.content.forEach((element) => {
          date[element._id] = true;
          Purchase[element._id] = element.nUser;
        });
        var InterestedDate = Object.keys(date);
        InterestedDate.sort();
        _this.setState({
          InterestedDate: InterestedDate,
          FirstLogin: FirstLogin,
          ScanPage: ScanPage,
          ScanSucess: ScanSucess,
          PlayGame: PlayGame,
          EndGame: EndGame,
          Purchase: Purchase,
        });
      });
  }

  render() {
    var tableContent = [];
    this.state.InterestedDate.forEach((date) => {
      tableContent.push(
        <tr>
          <td style={{ minWidth: "200px", textAlign: "left" }}>{date}</td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>{this.state.FirstLogin[date]}</td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>{this.state.ScanPage[date]}</td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>{this.state.ScanSucess[date]}</td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>{this.state.PlayGame[date]}</td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>{this.state.EndGame[date]}</td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>{this.state.Purchase[date]}</td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>
            {this.state.ScanSucess[date] > 0
              ? ((this.state.ScanSucess[date] / this.state.ScanPage[date]) * 100).toFixed(1) + " %"
              : ""}
          </td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>
            {this.state.EndGame[date] > 0 && this.state.PlayGame[date] > 0
              ? ((this.state.EndGame[date] / this.state.PlayGame[date]) * 100).toFixed(1) + " %"
              : ""}
          </td>
          <td style={{ minWidth: "150px", textAlign: "right" }}>
            {this.state.Purchase[date] > 0 && this.state.PlayGame[date] > 0
              ? ((this.state.Purchase[date] / this.state.PlayGame[date]) * 100).toFixed(1) + " %"
              : ""}
          </td>
        </tr>
      );
    });
    return (
      <center>
        <p style={{ fontSize: "36px", textAlign: "center" }}>User flow</p>
        <br />
        <table>
          <tr>
            <th style={{ minWidth: "200px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              Date
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              FirstLogin
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              ScanPage
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              ScanSucess
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              PlayGame
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              EndGame
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              Purchase
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              Suc/Scan
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              End/Play
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              Purchase/Play
            </th>
          </tr>
          {tableContent}
        </table>
      </center>
    );
  }
}
