import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import th from "./locales/th";

var language = window.navigator.userLanguage || window.navigator.language;

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en,
  },
  th: {
    translation: th,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.on("languageChanged", function (lng) {
  // E.g. set the moment locale with the current language
  // moment.locale(lng);
  // alert(lng)
  // then re-render your app
  //app.render();
});

export default i18n;
