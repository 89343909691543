import React, { PureComponent } from "react";

export default class AdsPerformance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      performance: {},
    };
    var _this = this;
    fetch("/api/get-ads-performance", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        _this.setState({ performance: data.AdsPerformance["content"] });
      });
  }

  render() {
    var out = {};
    var usedCampaign = {};
    var idMapping = {};
    var id = 0;
    for (var key in this.state.performance) {
      if (usedCampaign[this.state.performance[key]["_id"]["campaignid"]] === undefined) {
        usedCampaign[this.state.performance[key]["_id"]["campaignid"]] = true;
        idMapping[id] = this.state.performance[key]["_id"]["campaignid"];
        id++;
      }
      if (out[this.state.performance[key]["_id"]["campaignid"]] === undefined) {
        out[this.state.performance[key]["_id"]["campaignid"]] = {};
      }
      out[this.state.performance[key]["_id"]["campaignid"]][this.state.performance[key]["_id"]["activity"]] =
        this.state.performance[key]["count"];
    }
    var displayItem = [];
    for (var i = 0; i < id; i++) {
      var keys = ["LoadPage", "ToAppStore", "ToPlayStore", "ToSteam", "DirectDownload"];
      for (var j = 0; j < keys.length; j++) {
        if (!out[idMapping[i]][keys[j]]) {
          out[idMapping[i]][keys[j]] = 0;
        }
      }
      displayItem.push(
        <tr>
          <td style={{ textAlign: "right" }}>{idMapping[i]}</td>
          <td style={{ textAlign: "right" }}>{out[idMapping[i]]["LoadPage"]}</td>
          <td style={{ textAlign: "right" }}>
            {out[idMapping[i]]["ToAppStore"] +
              out[idMapping[i]]["ToPlayStore"] +
              " (" +
              (
                ((out[idMapping[i]]["ToAppStore"] + out[idMapping[i]]["ToPlayStore"]) /
                  out[idMapping[i]]["LoadPage"]) *
                100
              ).toFixed(0) +
              "%)"}
          </td>
          <td style={{ textAlign: "right" }}>
            {out[idMapping[i]]["ToAppStore"] +
              " (" +
              ((out[idMapping[i]]["ToAppStore"] / out[idMapping[i]]["LoadPage"]) * 100).toFixed(0) +
              "%)"}
          </td>
          <td style={{ textAlign: "right" }}>
            {out[idMapping[i]]["ToPlayStore"] +
              " (" +
              ((out[idMapping[i]]["ToPlayStore"] / out[idMapping[i]]["LoadPage"]) * 100).toFixed(0) +
              "%)"}
          </td>
          <td style={{ textAlign: "right" }}>
            {out[idMapping[i]]["ToSteam"] +
              out[idMapping[i]]["DirectDownload"] +
              " (" +
              (
                ((out[idMapping[i]]["ToSteam"] + out[idMapping[i]]["DirectDownload"]) /
                  out[idMapping[i]]["LoadPage"]) *
                100
              ).toFixed(0) +
              "%)"}
          </td>
          <td style={{ textAlign: "right" }}>
            {out[idMapping[i]]["ToSteam"] +
              " (" +
              ((out[idMapping[i]]["ToSteam"] / out[idMapping[i]]["LoadPage"]) * 100).toFixed(0) +
              "%)"}
          </td>
          <td style={{ textAlign: "right" }}>
            {out[idMapping[i]]["DirectDownload"] +
              " (" +
              ((out[idMapping[i]]["DirectDownload"] / out[idMapping[i]]["LoadPage"]) * 100).toFixed(0) +
              "%)"}
          </td>
        </tr>
      );
    }
    return (
      <center>
        <p style={{ fontSize: "36px" }}>Ads Performance</p>
        <br />
        <table>
          <tr>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              CampiagnName
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              PageLoad
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              MobileLoad
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              AppStore
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              PlayStore
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              PCLoad
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              Steam
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              DirectDownload
            </th>
          </tr>
          {displayItem}
        </table>
      </center>
    );
  }
}
