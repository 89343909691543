import React from "react";
import PromoCodeManager from "../../components/PromoCodeManager";
import Tabs from "../../components/Tabs";
import UrlManager from "../../components/UrlManager";

const AdminAdsManager = () => {
  const tabs = [
    {
      title: "Url Manager",
      content: <UrlManager />,
    },
    {
      title: "Promo Code Manager",
      content: <PromoCodeManager />,
    },
  ];

  return (
    <>
      <Tabs data={tabs} />
    </>
  );
};

export default AdminAdsManager;
