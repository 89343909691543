import React, { Component } from "react";

class FitforceInstallPC extends Component {
  componentDidMount() {
    window.location.href = "http://bit.ly/2JzlsvF";
  }

  render() {
    return (
      <div>
        <center>
          <h style={{ fontSize: "48px" }}>วิธีการติดตั้งบน PC</h>
          <br />
          <p style={{ fontSize: "24px" }}>
            ไฟล์จากถูกดาวน์โหลดโดยอัตโนมัติ หากไม่ได้รับไฟล์ <a href="http://bit.ly/2JzlsvF">คลิกที่นี่</a>
          </p>
          <br />
          <img style={{ height: "400px", width: "auto" }} alt="step1" src={require("../../img/step1.png")} />
          <img style={{ height: "400px", width: "auto" }} alt="step2" src={require("../../img/step2.png")} />
          <br />
          <p style={{ fontSize: "24px" }}>
            คลิกที่ไฟล์ RunsterSetup.exe จากนั้น เลือก More info จากนั้นคลิก Run anyway เพื่อทำการติดตั้ง
            Runster
          </p>
          <p style={{ fontSize: "24px" }}>
            เมื่อเกมถูกติดตั้ง และ ดาวโหลด Patch จนเสร็จ ก็สนุกกับเกมของพวกเราได้เลย!
          </p>
        </center>
      </div>
    );
  }
}

export default FitforceInstallPC;
