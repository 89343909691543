import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import Favicon from "react-favicon";
import Modal from "react-modal";
import Select from "react-select";
import Cookies from "universal-cookie";
import FitnowNav from "../../components/FitnowNav";
import "../../css/Home.css";
import { countryOptions } from "../../helper/CountryList";
import { IndustryOptions } from "../../helper/IndustryList";
import { logActivity } from "../../helper/LogActivity";
import { YearOption } from "../../helper/YearList";

const cookies = new Cookies();

var campaignID;
var cookieID = "";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10000,
    backgroundColor: "#4D5361",
    borderColor: "#fff",
    borderWidth: 3,
    borderRadius: "5vh",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "#000b",
  },
};

export default class Fitnow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPortriat: window.innerWidth < window.innerHeight,
      isMobile: window.innerWidth <= 480,
      isShowModal: false,
      isCompleteForm: false,
      isIncorrectEmail: false,
      email: null,
      countryOption: null,
      yearOption: null,
      indrustryOption: null,
    };

    document.title = "fitnow";
    campaignID = "FitNow";

    cookieID = cookies.get("fitforceCookieID");

    this.timer = 0;
    this.sendFormCountDown = this.sendFormCountDown.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    if (cookieID !== undefined) {
      logActivity("DownloadPage", campaignID, cookieID);
    } else {
      fetch("/api/gen-cookie", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          cookies.set("fitforceCookieID", data.CookieID, { path: "/" });
          cookieID = cookies.get("fitforceCookieID");
          logActivity("DownloadPage", campaignID, cookieID);
        });
    }
  }

  resize = () => {
    this.setState({
      isMobile: window.innerWidth <= 480,
      isPortriat: window.innerWidth < window.innerHeight,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  openModal(source) {
    logActivity("OpenPreRegister_" + source, campaignID, cookieID);
    this.setState({ isShowModal: true, isCompleteForm: false });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendForm() {
    var isValidEmail = this.validateEmail(this.state.email);

    this.setState({ isIncorrectEmail: !isValidEmail });
    if (!isValidEmail) return;

    var _this = this;

    fetch("/api/pre-register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CookieID: cookieID,
        CampaignID: campaignID,
        Email: this.state.email,
        Country: this.state.countryOption.label,
        YearOfBirth: this.state.yearOption.value,
        Job: this.state.indrustryOption.value,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        _this.setState({ isCompleteForm: true });
        _this.timer = setInterval(_this.sendFormCountDown, 2000);
      });
  }

  sendFormCountDown() {
    clearInterval(this.timer);
    this.setState({ isShowModal: false });
  }

  handleCountryChange = (countryOption) => {
    this.setState({ countryOption });
  };

  handleYearChange = (yearOption) => {
    this.setState({ yearOption });
  };

  handleIndustryChange = (indrustryOption) => {
    this.setState({ indrustryOption });
  };

  render() {
    const { countryOption, yearOption, indrustryOption } = this.state;

    return (
      <div style={{ width: "100%", minHeight: "100vh", backgroundColor: "#fff", overflow: "hidden" }}>
        <Favicon url={require("../../img/FitNow/fireicon.png")} />
        <FitnowNav />
        {!this.state.isMobile && (
          <div>
            <div style={{ backgroundColor: "#D94829", height: "100vh" }}>
              <div style={{ height: "6vw", maxHeight: 50 }} />
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  paddingLeft: "8vw",
                  paddingRight: "8vw",
                  height: "90vh",
                }}
              >
                <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
                  <div style={{ flex: 1 }} />
                  <p
                    style={{
                      fontFamily: "Rubik",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "max(5vw, 7vh)",
                      lineHeight: 1.2,
                    }}
                  >
                    Simplest
                    <br />
                    way to get in shape.
                  </p>
                  <div style={{ height: "max(1vw, 3vh)" }} />
                  <p style={{ fontFamily: "Roboto", color: "#fff", fontSize: "max(1.5vw, 2.5vh)" }}>
                    Join our pre-access community.
                  </p>
                  <div style={{ height: "max(0.5vw, 1.5vh)" }} />
                  <span
                    onClick={() => this.openModal("heroImage")}
                    style={{ flexDirection: "row", display: "flex" }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "5vw",
                        padding: "1vh",
                        paddingLeft: "max(2vw, 3vh)",
                        paddingRight: "max(2vw, 3vh)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ flex: 1 }} />
                      <p
                        style={{
                          fontFamily: "Rubik",
                          fontSize: "max(1.5vw, 2.5vh)",
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        <b>SIGN UP</b>
                      </p>
                      <div style={{ flex: 1 }} />
                    </div>
                    <div style={{ flex: 1 }} />
                  </span>
                  <div style={{ flex: 1 }} />
                </div>
                <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
                  <divs style={{ flex: 1 }} />
                  <img
                    style={{
                      height: "95vh",
                      width: "54.55vh",
                      maxWidth: "40vw",
                      maxHeight: "69.6vw",
                      zIndex: 2,
                    }}
                    alt="FitNow"
                    src={require("../../img/FitNow/landingMobile.png")}
                  />
                  <divs style={{ flex: 1 }} />
                </div>
              </div>
              <img
                style={{
                  width: "40vw",
                  height: "63.754vw",
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  zIndex: 1,
                }}
                alt="FitNow"
                src={require("../../img/FitNow/FireLogoW 1.png")}
              />
            </div>
            <div style={{ padding: "5vw", display: "flex", flexDirection: "row" }}>
              <img
                style={{
                  marginLeft: "7vw",
                  height: "80vh",
                  width: "54.222vh",
                  maxWidth: "40vw",
                  maxHeight: "59vw",
                }}
                alt="FitNow"
                src={require("../../img/FitNow/exerciseGroup.png")}
              />
              <div style={{ flex: 1, marginLeft: "10vw", display: "flex", flexDirection: "column" }}>
                <div style={{ flex: 1 }} />
                <p style={{ fontFamily: "Rubik", fontWeight: "bold", fontSize: "max(2.5vw, 3.5vh)" }}>
                  Personalized workout plan
                </p>
                <p style={{ fontFamily: "Roboto", fontSize: "max(1.25vw, 1.75vh)", width: "30vw" }}>
                  You pick your exercise goal. We pick the right workout and dietary plan to match with that
                  goal. No more complex exercise planning required.
                </p>
                <div style={{ flex: 1 }} />
                <p style={{ fontFamily: "Roboto", fontSize: "max(1.25vw, 2vh)" }}>
                  Join our pre-access community.
                </p>
                <div style={{ height: "0.5vw" }} />
                <span
                  onClick={() => this.openModal("personalize")}
                  style={{ flexDirection: "row", display: "flex" }}
                >
                  <div
                    style={{
                      backgroundColor: "#D94829",
                      borderRadius: "5vw",
                      padding: "1vh",
                      paddingLeft: "max(2vw, 3vh)",
                      paddingRight: "max(2vw, 3vh)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ flex: 1 }} />
                    <p
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "max(1.5vw, 2.5vh)",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      <b>SIGN UP</b>
                    </p>
                    <div style={{ flex: 1 }} />
                  </div>
                  <div style={{ flex: 1 }} />
                </span>
                <div style={{ flex: 1 }} />
              </div>
            </div>
          </div>
        )}
        {this.state.isMobile && (
          <div style={{ width: "100%", minHeight: "145vh" }}>
            <div style={{ backgroundColor: "#D94829", width: "100%" }}>
              <div style={{ height: "6vh", maxHeight: 50 }} />
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  paddingLeft: "15vw",
                  paddingRight: "15vw",
                }}
              >
                <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
                  <div style={{ flex: 1 }} />
                  <p
                    style={{
                      fontFamily: "Rubik",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "max(3vw, 4.25vh)",
                      lineHeight: 1.2,
                    }}
                  >
                    Simplest way <br /> to get in shape.
                  </p>
                  <div style={{ height: "max(1vw, 3vh)" }} />
                  <p style={{ fontFamily: "Roboto", color: "#fff", fontSize: "max(1vw, 2vh)" }}>
                    Join our pre-access community.
                  </p>
                  <div style={{ height: "max(0.5vw, 1.5vh)" }} />
                  <span
                    onClick={() => this.openModal("heroImage")}
                    style={{ flexDirection: "row", display: "flex" }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "5vw",
                        padding: "1vh",
                        paddingLeft: "max(2vw, 3vh)",
                        paddingRight: "max(2vw, 3vh)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ flex: 1 }} />
                      <p
                        style={{
                          fontFamily: "Rubik",
                          fontSize: "max(1.5vw, 2vh)",
                          color: "#000",
                          textAlign: "center",
                        }}
                      >
                        <b>SIGN UP</b>
                      </p>
                      <div style={{ flex: 1 }} />
                    </div>
                    <div style={{ flex: 1 }} />
                  </span>
                  <div style={{ flex: 1 }} />
                </div>
              </div>
              <img
                style={{ width: "100%", height: "auto", position: "absolute" }}
                alt="FitNow"
                src={require("../../img/FitNow/landingMobile.png")}
              />
              <div
                style={{
                  paddingLeft: "10vw",
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: "3vh",
                  backgroundColor: "#fff",
                  marginTop: "86.7vw",
                }}
              >
                <p
                  style={{
                    font: "Rubik",
                    fontWeight: "bold",
                    fontSize: "max(2.5vw, 3.5vh)",
                    marginTop: "86.7vw",
                  }}
                >
                  Personalized <br /> workout plan
                </p>
                <div style={{ height: "2vh" }} />
                <p style={{ font: "Roboto", fontSize: "max(1.25vw, 1.75vh)" }}>
                  You pick your exercise goal. We pick the right workout and dietary plan to match with that
                  goal. No more complex exercise planning required.
                </p>
                <p style={{ font: "Roboto", fontSize: "max(1.25vw, 2vh)" }}>Join our pre-access community.</p>
                <div style={{ height: "1vh" }} />
                <span
                  onClick={() => this.openModal("personalize")}
                  style={{ flexDirection: "row", display: "flex" }}
                >
                  <div
                    style={{
                      backgroundColor: "#D94829",
                      borderRadius: "5vw",
                      padding: "1vh",
                      paddingLeft: "max(2vw, 3vh)",
                      paddingRight: "max(2vw, 3vh)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ flex: 1 }} />
                    <p
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "max(1.5vw, 2.5vh)",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      <b>SIGN UP</b>
                    </p>
                    <div style={{ flex: 1 }} />
                  </div>
                  <div style={{ flex: 1 }} />
                </span>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            backgroundImage: `url(${require("../../img/FitNow/backgroundExercise.png")})`,
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            padding: "8vw",
            backgroundPosition: "center center",
          }}
        >
          <div style={{ height: "45vh" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                fontFamily: "Rubik",
                color: "#fff",
                fontWeight: "bold",
                fontSize: "max(3.5vw, 4vh)",
                lineHeight: 1.2,
              }}
            >
              Get fit anytime, anywhare
            </p>
            <div style={{ height: "max(1vw, 2vh)" }} />
            <p style={{ fontFamily: "Roboto", color: "#fff", fontSize: "max(1.5vw, 2vh)", width: "50vw" }}>
              Your house is your gym. It’s simple and effective.
            </p>
            <div style={{ height: "max(3vw, 3vh)" }} />
            <span onClick={() => this.openModal("anytime")} style={{ flexDirection: "row", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "5vw",
                  padding: "1vh",
                  paddingLeft: "max(2vw, 3vh)",
                  paddingRight: "max(2vw, 3vh)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "max(1.5vw, 2.5vh)",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  <b>SIGN UP</b>
                </p>
                <div style={{ flex: 1 }} />
              </div>
              <div style={{ flex: 1 }} />
            </span>
          </div>
        </div>
        <div
          style={{
            padding: "max(5vw, 4vh)",
            display: "flex",
            flexDirection: this.state.isMobile ? "column" : "row",
          }}
        >
          <div style={{ flex: 1 }} />
          <img
            style={{
              marginLeft: this.state.isMobile ? 0 : "min(4vw, 4vh)",
              height: "80vh",
              width: "86.272vh",
              maxWidth: this.state.isMobile ? "80vw" : "50vw",
              maxHeight: this.state.isMobile ? "86.727vw" : "53.92vw",
            }}
            alt="FitNow"
            src={require("../../img/FitNow/meal.png")}
          />
          <div style={{ flex: 1, marginLeft: "min(8vw, 12vh)", display: "flex", flexDirection: "column" }}>
            <div style={{ height: "max(2.5vw, 2vh)" }} />
            <p style={{ font: "Rubik", fontWeight: "bold", fontSize: "max(2.5vw, 2.5vh)" }}>
              Customize your meal plans
            </p>
            <div style={{ height: "max(1.25vw, 1vh)" }} />
            <p
              style={{
                font: "Roboto",
                fontSize: "max(1.25vw, 1.75vh)",
                width: this.state.isMobile ? "80vw" : "30vw",
              }}
            >
              A healthy eating plan to reach your wellness goals without a strict diet.
            </p>
            <div style={{ height: "max(2.5vw, 2vh)" }} />
            <p style={{ font: "Roboto", fontSize: "max(1.25vw, 2vh)" }}>Join our pre-access community.</p>
            <div style={{ height: "max(1.25vw, 1vh)" }} />
            <div style={{ height: "0.5vw" }} />
            <span
              onClick={() => this.openModal("mealplan")}
              style={{ flexDirection: "row", display: "flex" }}
            >
              <div
                style={{
                  backgroundColor: "#D94829",
                  borderRadius: "5vw",
                  padding: "1vh",
                  paddingLeft: "max(2vw, 3vh)",
                  paddingRight: "max(2vw, 3vh)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "max(1.5vw, 2.5vh)",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  <b>SIGN UP</b>
                </p>
                <div style={{ flex: 1 }} />
              </div>
              <div style={{ flex: 1 }} />
            </span>
          </div>
          <div style={{ flex: 1 }} />
        </div>
        <div style={{ backgroundColor: "#4D5361" }}>
          <div style={{ height: "6vw", maxHeight: 50 }} />
          <div style={{ flexDirection: "row", display: "flex", paddingLeft: "8vw", paddingRight: "8vw" }}>
            <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
              <p
                style={{
                  font: "Rubik",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "max(3vw, 4vh)",
                  lineHeight: 1.3,
                }}
              >
                Exercise program designed by professional trainers and 50K users’ feedback
              </p>
              <div style={{ height: "max(3vw, 4vh)" }} />
              <span
                onClick={() => this.openModal("professional")}
                style={{ flexDirection: "row", display: "flex" }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "5vw",
                    padding: "1vh",
                    paddingLeft: "max(2vw, 3vh)",
                    paddingRight: "max(2vw, 3vh)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <p
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "max(1.5vw, 2.5vh)",
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    <b>SIGN UP</b>
                  </p>
                  <div style={{ flex: 1 }} />
                </div>
              </span>
              <div style={{ height: "max(3vw, 6vh)" }} />
              {!this.state.isMobile && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      style={{ width: "max(8vw, 10vh)", height: "max(8vw, 10vh)" }}
                      alt="FitNow"
                      src={require("../../img/FitNow/bowFace.png")}
                    />
                    <p
                      style={{
                        font: "Roboto",
                        fontSize: "max(1.25vw, 1.5vh)",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Ticha Lee
                    </p>
                  </div>
                  <div style={{ width: "3vw" }} />
                  <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                    <div style={{ flex: 1 }} />
                    <img
                      alt="FitNow"
                      src={require("../../img/FitNow/stars.png")}
                      style={{ width: "max(12vw, 15vh)", height: "max(2.16vw, 2.7vh" }}
                    />
                    <div style={{ height: "1vw" }} />
                    <p style={{ font: "Roboto", fontSize: "max(1vw, 1.25vh)", color: "#fff", width: "15vw" }}>
                      “This app is exceptional! I can get in shape in a few weeks.”
                    </p>
                    <div style={{ flex: 1 }} />
                  </div>
                </div>
              )}
              {this.state.isMobile && (
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        style={{ width: "15vh", height: "15vh" }}
                        alt="FitNow"
                        src={require("../../img/FitNow/bowFace.png")}
                      />
                    </div>
                    <div style={{ width: "7vw" }} />
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                      <div style={{ flex: 1 }} />
                      <p style={{ font: "Roboto", fontSize: "2.5vh", color: "#fff" }}>Ticha Lee</p>
                      <img
                        alt="FitNow"
                        src={require("../../img/FitNow/stars.png")}
                        style={{ width: "20vh", height: "3.6vh" }}
                      />
                      <div style={{ height: "1vw" }} />
                      <div style={{ flex: 1 }} />
                    </div>
                  </div>
                  <div style={{ height: "1vh" }} />
                  <p style={{ font: "Roboto", fontSize: "2.5vh", color: "#fff" }}>
                    “This app is exceptional! I can get in shape in a few weeks.”
                  </p>
                </div>
              )}
              <div style={{ height: "7vw" }} />
            </div>
            {!this.state.isMobile && (
              <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
                <divs style={{ flex: 1 }} />
                <img
                  style={{
                    maxHeight: "52.6vw",
                    maxWidth: "45vw",
                    height: "90vh",
                    width: "78vh",
                    marginTop: "2vw",
                    marginLeft: "5vw",
                  }}
                  alt="FitNow"
                  src={require("../../img/FitNow/fitnessDuo.png")}
                />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            padding: "max(2.5vw, 4vh)",
            display: "flex",
            flexDirection: this.state.isMobile ? "column" : "row",
          }}
        >
          <div style={{ flex: 1 }} />
          {!this.state.isMobile && (
            <img
              style={{ height: "80vh", width: "46.13vh", maxWidth: "50vw", maxHeight: "86.71vw" }}
              alt="FitNow"
              src={require("../../img/FitNow/landingMobile.png")}
            />
          )}
          <div style={{ marginLeft: "min(8vw, 12vh)", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 1 }} />
            <p
              style={{
                font: "Rubik",
                fontWeight: "bold",
                fontSize: "max(3vw, 3vh)",
                lineHeight: 1.3,
                maxWidth: "70vh",
              }}
            >
              Join our happy <br /> pre-early access <br /> community!
            </p>
            <div style={{ height: "max(1.25vw, 1vh)" }} />
            <div style={{ height: "0.5vw" }} />
            <span
              onClick={() => this.openModal("joinCommunity")}
              style={{ flexDirection: "row", display: "flex" }}
            >
              <div
                style={{
                  backgroundColor: "#D94829",
                  borderRadius: "5vw",
                  padding: "1vh",
                  paddingLeft: "max(2vw, 3vh)",
                  paddingRight: "max(2vw, 3vh)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "max(1.5vw, 2.5vh)",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  <b>SIGN UP</b>
                </p>
                <div style={{ flex: 1 }} />
              </div>
              <div style={{ flex: 1 }} />
            </span>
            <div style={{ flex: 1 }} />
          </div>
          {this.state.isMobile && (
            <img
              style={{ height: "118vw", width: "80vw", alignSelf: "center" }}
              alt="FitNow"
              src={require("../../img/FitNow/exerciseGroup.png")}
            />
          )}
          <div style={{ flex: 1 }} />
        </div>
        <div style={{ backgroundColor: "#242A38", padding: "3vw" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }} />
            <img
              style={{ width: "clamp(35px, 5vw, 55px)", height: "clamp(52px, 7.45vw, 82px)", padding: 3 }}
              alt="FitNow"
              src={require("../../img/FitNow/firelogo 2.png")}
            />
            <div style={{ width: "1vw", maxWidth: 7 }} />
            <p style={{ fontSize: "clamp(30px, 4vw, 45px)", fontFamily: "Rubik", color: "#fff" }}>
              <b>fitnow</b>
            </p>
            <div style={{ flex: 1 }} />
          </div>
        </div>
        <Modal
          isOpen={this.state.isShowModal}
          onRequestClose={() => this.setState({ isShowModal: false })}
          style={customStyles}
        >
          {!this.state.isCompleteForm && (
            <div
              style={{
                minHeight: this.state.isPortriat ? "75vh" : "min(40vw, 80vh)",
                minWidth: this.state.isPortriat ? "75vw" : "70vw",
                flexDirection: this.state.isPortriat ? "column" : "row",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: this.state.isPortriat ? "" : 1,
                  marginLeft: this.state.isPortriat ? "" : "2vw",
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: this.state.isPortriat ? "3vh" : "max(2.5vw, 3.5vh)",
                    lineHeight: 1.2,
                    width: this.state.isPortriat ? "" : "max(10vw, 16vh)",
                    textAlign: this.state.isPortriat ? "center" : "",
                  }}
                >
                  <b>Get your pre-early access</b>
                </p>
                <div style={{ height: "max(1vw, 1vh" }} />
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: this.state.isPortriat ? "1.5vh" : "max(1.5vw, 2vh)",
                    lineHeight: 1.3,
                    width: this.state.isPortriat ? "" : "max(20vw, 25vh)",
                    textAlign: this.state.isPortriat ? "center" : "",
                  }}
                >
                  and help us find someone like you easier
                </p>
                <div style={{ flex: 1 }} />
                {!this.state.isPortriat && (
                  <img
                    style={{
                      width: "min(25vw, 60vh)",
                      height: "min(15.37vw, 36.88vh)",
                      borderRadius: "min(2vw, 4.8vh)",
                    }}
                    alt="FitNow"
                    src={require("../../img/FitNow/YogaGroup.jpg")}
                  />
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <div style={{ height: "max(1.5vw, 3vh" }} />
                <Select
                  placeholder={"Select Country"}
                  value={countryOption}
                  onChange={this.handleCountryChange}
                  options={countryOptions}
                />
                <div style={{ height: "max(0.7vw, 0.7vh" }} />
                <Select
                  placeholder={"Select Year of Birth"}
                  value={yearOption}
                  onChange={this.handleYearChange}
                  options={YearOption}
                />
                <div style={{ height: "max(0.7vw, 0.7vh" }} />
                <Select
                  placeholder={"Select Work Industry"}
                  value={indrustryOption}
                  onChange={this.handleIndustryChange}
                  options={IndustryOptions}
                  isSearchable={false}
                />
                <div style={{ height: "max(2.5vw, 5vh" }} />
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "max(0.8vw, 14)",
                    textAlign: "center",
                  }}
                >
                  Your email address*
                </p>
                <input
                  style={{ fontFamily: "Roboto", fontSize: 18, padding: 6, borderRadius: 5 }}
                  type="email"
                  value={this.state.email}
                  placeholder={"youremail@example.com"}
                  onChange={this.handleEmailChange}
                />
                {this.state.isIncorrectEmail && (
                  <p style={{ color: "#FFA694", textAlign: "center" }}>*wrong email</p>
                )}
                <div style={{ flex: 1 }} />
                <center>
                  {this.state.isPortriat && (
                    <img
                      style={{ height: "12vh", width: "8.052vh", marginBottom: "1vh" }}
                      alt="FitNow"
                      src={require("../../img/FitNow/firelogo 2.png")}
                    />
                  )}
                  <span onClick={() => this.sendForm()} style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ flex: 1 }} />
                    <div
                      style={{
                        backgroundColor: "#DB4729",
                        borderRadius: "5vw",
                        padding: "max(0.5vw, 0.5vh)",
                        paddingLeft: "max(2vw, 2vh)",
                        paddingRight: "max(2vw, 2vh)",
                      }}
                    >
                      <p style={{ color: "#fff", fontFamily: "Rubik", fontSize: "max(1.2vw, 2vh)" }}>
                        <b>SEND FORM</b>
                      </p>
                    </div>
                    <div style={{ flex: 1 }} />
                  </span>
                  <div style={{ height: "1vh" }} />
                  <span
                    onClick={() => this.setState({ isShowModal: false })}
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "max(1vw, 2vh)",
                      color: "#fff",
                      textDecoration: "underline",
                    }}
                  >
                    Cancel
                  </span>
                  <div style={{ height: "3vh" }} />
                </center>
              </div>
            </div>
          )}
          {this.state.isCompleteForm && (
            <div
              style={{
                height: "70vh",
                width: "70vw",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }} />
              <center>
                <img
                  style={{ height: "15vh", width: "10.065vh" }}
                  alt="FitNow"
                  src={require("../../img/FitNow/firelogo 2.png")}
                />
                <p
                  style={{ color: "#fff", fontWeight: 900, fontFamily: "Roboto", fontSize: "max(2vw, 3vh)" }}
                >
                  THANK YOU!
                </p>
                <div style={{ height: "max(1vw, 2vh)" }} />
                {!this.state.isPortriat && (
                  <p style={{ color: "#fff", fontFamily: "Roboto", fontSize: "max(1.25vw, 1.75vh)" }}>
                    We appreciate your paticipation.
                  </p>
                )}
                <p style={{ color: "#fff", fontFamily: "Roboto", fontSize: "max(1.25vw, 1.75vh)" }}>
                  We will contact you back soonish!
                </p>
              </center>
              <div style={{ flex: 1 }} />
            </div>
          )}
        </Modal>
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
    );
  }
}
