import React, { PureComponent } from "react";

const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const addDay = (dateString, addedDays) => {
  var date = Date.parse(dateString);
  return formatDate(date + 60 * 60 * 1000 * 24 * addedDays);
};

const WeekRetentionTable = (retention) => {
  var retentionLog = [];
  for (var key in retention) {
    var weeklyActive = retention[key][key];
    retentionLog.push(
      <tr>
        <td>{key}</td>
        <td>{weeklyActive.toString()}</td>
        <td>
          {retention[key][parseInt(key) + 1] > 0
            ? ((retention[key][parseInt(key) + 1] / weeklyActive) * 100).toFixed(2) + " %"
            : "-"}
        </td>
        <td>
          {retention[key][parseInt(key) + 2] > 0
            ? ((retention[key][parseInt(key) + 2] / weeklyActive) * 100).toFixed(2) + " %"
            : "-"}
        </td>
        <td>
          {retention[key][parseInt(key) + 3] > 0
            ? ((retention[key][parseInt(key) + 3] / weeklyActive) * 100).toFixed(2) + " %"
            : "-"}
        </td>
        <td>
          {retention[key][parseInt(key) + 4] > 0
            ? ((retention[key][parseInt(key) + 4] / weeklyActive) * 100).toFixed(2) + " %"
            : "-"}
        </td>
      </tr>
    );
  }
  return (
    <table>
      <tr>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>Weeks</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>New users</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>Week1</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>Week2</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>Week3</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>Week4</th>
      </tr>
      {retentionLog}
    </table>
  );
};

const DailyRetentionTable = (retention) => {
  var retentionLog = [];
  var sortedKey = [];
  for (var mapKey in retention) {
    sortedKey.push(mapKey);
  }
  sortedKey.sort();
  sortedKey.forEach((key) => {
    var todayActive = retention[key][key];
    var r2 = retention[key][addDay(key, 1)];
    var r3 = retention[key][addDay(key, 2)];
    var r4 = retention[key][addDay(key, 3)];
    var r5 = retention[key][addDay(key, 4)];
    var r6 = retention[key][addDay(key, 5)];
    var r7 = retention[key][addDay(key, 6)];
    var r14 = retention[key][addDay(key, 13)];
    var r30 = retention[key][addDay(key, 29)];
    retentionLog.push(
      <tr>
        <td>{key}</td>
        <td>{todayActive.toString()}</td>
        <td>{r2 > 0 ? ((r2 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
        <td>{r3 > 0 ? ((r3 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
        <td>{r4 > 0 ? ((r4 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
        <td>{r5 > 0 ? ((r5 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
        <td>{r6 > 0 ? ((r6 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
        <td>{r7 > 0 ? ((r7 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
        <td>{r14 > 0 ? ((r14 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
        <td>{r30 > 0 ? ((r30 / todayActive) * 100).toFixed(1) + " %" : "-"}</td>
      </tr>
    );
  });
  return (
    <table>
      <tr>
        <th style={{ minWidth: "150px", backgroundColor: "#bb4444", color: "#fff" }}>Date</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>New users</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R2</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R3</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R4</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R5</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R6</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R7</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R14</th>
        <th style={{ minWidth: "100px", backgroundColor: "#bb4444", color: "#fff" }}>R30</th>
      </tr>
      {retentionLog}
    </table>
  );
};

export default class PaidRetention extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      retention: {},
      paidRetention: {},
      dayRetention: {},
    };
    var _this = this;
    fetch("/api/retention", {
      method: "POST",
      timeout: 0,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var formatted = {};
        for (let i = 0; i < data.Retention["content"].length; i++) {
          if (formatted[data.Retention["content"][i]["_id"]["start"]["weeks"]] == null) {
            formatted[data.Retention["content"][i]["_id"]["start"]["weeks"]] = {};
          }
          formatted[data.Retention["content"][i]["_id"]["start"]["weeks"]][
            data.Retention["content"][i]["_id"]["playing"]["weeks"]
          ] = data.Retention["content"][i].count;
        }
        var formattedPaidRetention = {};
        for (let i = 0; i < data.paidRetention["content"].length; i++) {
          if (formattedPaidRetention[data.paidRetention["content"][i]["_id"]["start"]["weeks"]] == null) {
            formattedPaidRetention[data.paidRetention["content"][i]["_id"]["start"]["weeks"]] = {};
          }
          formattedPaidRetention[data.paidRetention["content"][i]["_id"]["start"]["weeks"]][
            data.paidRetention["content"][i]["_id"]["playing"]["weeks"]
          ] = data.paidRetention["content"][i].count;
        }
        var formattedDateRetention = {};
        for (let i = 0; i < data.dailyRetention["content"].length; i++) {
          if (formattedDateRetention[data.dailyRetention["content"][i]["_id"]["start"]["days"]] == null) {
            formattedDateRetention[data.dailyRetention["content"][i]["_id"]["start"]["days"]] = {};
          }
          formattedDateRetention[data.dailyRetention["content"][i]["_id"]["start"]["days"]][
            data.dailyRetention["content"][i]["_id"]["playing"]["days"]
          ] = data.dailyRetention["content"][i].count;
        }
        _this.setState({
          retention: formatted,
          paidRetention: formattedPaidRetention,
          dayRetention: formattedDateRetention,
        });
      })
      .catch(function (error) {
        _this.props.history.push("/login");
      });
  }

  render() {
    return (
      <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <p style={{ fontSize: "36px" }}>Retention</p>
        <br />
        <p>
          <b>Retention View</b>
        </p>
        <br />
        {WeekRetentionTable(this.state.retention)}
        <br />
        <p>
          <b>Paid Retention View</b>
        </p>
        <br />
        {WeekRetentionTable(this.state.paidRetention)}
        <br />
        <p>
          <b>Daily Active View</b>
        </p>
        <br />
        {DailyRetentionTable(this.state.dayRetention)}
      </div>
    );
  }
}
