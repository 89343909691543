import { XyzTransition, XyzTransitionGroup } from "@animxyz/react";
import React from "react";
import { Helmet } from "react-helmet";
import StaticFooter from "../../components/StaticFooter";
import StaticHeader from "../../components/StaticHeader"
import { useSearchParams } from 'react-router-dom';

const description = `Fitforce 2, the ultimate mobile fitness game! Experience the future of exercise.`;
const title = `Fitforce 2 | Mobile exercise game`;

const Fitforce2Landing = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const language = searchParams.get('hl') || "English"

  //TODO: find the way to make rich text
    var gamesTextL1 = "Wanna see what our games look like? "
    var gamesTextL2 = "Check them out below!"
    var phoneTitle = "A SMARTPHONE IS ENOUGH"
    var phoneDescription = "No extra devices needed. Our AI technology uses your front camera to track your exercise for game control and provides a health report, including data like calories burned and muscles worked."

    var stageTitle = "1,000+ stages to explore"
    var stageDescription = "Say goodbye to boring workouts. With our stage customization system, we offer a wide variety of stages for you to explore in each game."

    var planTitle = "Maximize your results with a WORKOUT PLAN."
    var planDescription = "A workout can't be great without a schedule, and we've got you covered. Just press start and enjoy your exercise session! You can also customize it for the best workout tailored to you."

    if(language == "Thai")
    {
      gamesTextL1 = "ไหนเกมเป็นยังไง?"
      gamesTextL2 = "ลองดูด้านล่างได้เลย !"
      phoneTitle = "ใช้แค่มือถือเครื่องเดียว !"
      phoneDescription = "ไม่ต้องใช้ อุปกรณ์เสริมใดๆ ด้วยเทคโนโลยี AI ของเรา แค่ใช้กล้องหน้า ตรวจจับการออกกำลังกายของคุณ ก็สามารถควบคุมเกม ได้เลย และเรายังมี รายงานผลลัพธ์ทางสุขภาพ เช่น แคลอรี่ หรือ กล้ามเนื้อที่ใช้ ให้อีกด้วย!"
      
      stageTitle = "ด่านเป็น 1,000+ เลยหรอพี่?"
      stageDescription = "ลาก่อนการออกกำลังกายแสนน่าเบื่อ ด้วยระบบปรับแต่งด่านของเรา ทำให้คุณได้ พบกับความหลากหลายของด่านต่างๆ และสนุกกับการออกกำลังกายได้มากขึ้น"
      
      planTitle = "ไปสู่ผลลัพธ์สูงสุดด้วย แผนฟิตเนส !"
      planDescription = "ไม่ต้องปวดหัวอีกต่อไป ว่าต้องออกกำลังกายอะไรวันไหน ? แค่กดเริ่ม และก็สนุกกับ เซ็ตออกกำลังกาย ของวันนั้นๆ ได้เลย และคุณยังสามารถปรับแต่ง แผนฟิตเนส ของคุณได้ดั่งใจ เพื่อสร้างการออกกำลังกายที่ดีที่สุดสำหรับคุณ"
    }

  return (
    <div className="font-kanit min-h-screen w-full flex flex-col justify-between">
      <StaticHeader/>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="og:title" property="og:title" content={title}/>
        <meta name="og:description" property="og:description" content={description} />
        <meta name="og:image" property="og:image" content="/img/fitforce2logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="/img/fitforce2logo.png" />
      </Helmet>
    <div class="relative w-full bg-color bg-[#2e3756]">
        <img src={require("../../img/Fitforce2/bg_nologo.webp")} alt="Background Image" class="object-cover object-center w-full h-full min-h-96 -mb-2" />
        <div class="absolute inset-0 flex flex-col items-center justify-end">
            <img class="w-72 lg:w-1/3" src={require("../../img/Fitforce2/ff2herologo.png")}/>
            <div class="flex flex-row items-center justify-center">
                <a
                href="https://play.google.com/store/apps/details?id=com.RunnexTech.Fitforce2"
                target="_blank"
                className="cursor-pointer text-center w-24 lg:w-1/5"
                rel="noreferrer">
                    <img src={require("../../img/Fitforce2/pngegg1.png")} class="w-full" />
                </a>
                <a
                href="https://apps.apple.com/th/app/fitforce2/id1607830236"
                target="_blank"
                className="cursor-pointer text-center w-24 lg:w-1/5"
                rel="noreferrer">
                    <img src={require("../../img/Fitforce2/pngegg2.png")} class="w-full" />
                </a>
            </div>
        </div>
    </div>
    <div className="bg-gradient-to-b from-[#2e3756] via-[#313067] to-[#393778] w-screen">
        <div className="bg-repeat-y pt-10 w-screen bg-center bg-contain lg:bg-cover text-white" style={{ backgroundImage: `url(${require("../../img/Fitforce2/margintextdeco.png")})` }}>
          <div className="mb-[8vh]">
            <div className="flex flex-col justify-center items-center">
                <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
                  <p className="text-center text-3xl md:text-4xl lg:text-5xl p-6 textshadow">{gamesTextL1}<br/>{gamesTextL2}</p>
                </XyzTransition>
                <br></br>
                <div className="w-[90vw] lg:w-[60vw] xl:w-[55vw] p-1 relative flex flex-row justify-center items-center bg-gradient-to-br from-[#35FDE5] via-[#9697FB] to-[#35FDE5]">
                    <img className="absolute -left-[1.5%] -top-[1.5%] w-[20%]" src={require("../../img/Fitforce2/topLeft.png")}  />
                    <img className="absolute -right-[1.5%] -bottom-[1.5%] w-[20%]" src={require("../../img/Fitforce2/bottomRight.png")}  />
                    <img className="absolute -right-[17.5%] -top-[25%] w-[35%] z-20" src={require("../../img/Fitforce2/small_03_Front.png")}  />
                    <img className="absolute -left-[17.5%] -bottom-[25%] w-[35%] z-20" src={require("../../img/Fitforce2/enemybuffheal_01_Front.png")}  />
                    <video className="w-full justify-center items-center z-10" controls autoPlay playsInline muted loop>
                        <source src={require("../../video/Web-teaser.mp4")} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>

      <div className="flex flex-col items-center space-y-5">
        <div className="flex flex-col items-center w-[80vw] lg:flex-row-reverse justify-center lg:items-start space-y-2 lg:space-y-0">  
          <div className="flex flex-col items-center lg:items-start lg:w-[50vh] lg:mt-10">
            <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
            <p className="text-3xl md:text-4xl xl:text-5xl mb-2 textshadow lg:mb-6">{phoneTitle}</p>
            </XyzTransition>
            <p className="text-base md:text-2xl xl:text-3xl font-light textshadow">{phoneDescription}</p>
          </div>
          <img className="w-[50vw] lg:w-auto lg:h-[50vh] lg:mr-[5vw]" src={require("../../img/Fitforce2/DARA.png")}/>
        </div>
        
        <div className="flex flex-col items-center w-[80vw] lg:flex-row justify-center lg:items-start pb-5  space-y-3 lg:space-y-0">  
          <div className="flex flex-col items-center lg:items-start lg:w-[50vh] lg:mt-40 lg:mr-[5vw]">
            <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
            <p className="text-3xl md:text-4xl xl:text-5xl mb-2 textshadow lg:mb-6">{stageTitle}</p>
            </XyzTransition>
            <p className="text-base md:text-2xl xl:text-3xl font-light textshadow">{stageDescription}</p>
          </div>
          <img className="w-[50vw] flex lg:hidden" src={require("../../img/Fitforce2/Game.png")}/>
          <img className="max-h-[60vh] max-w-[45vw] hidden lg:flex" src={require("../../img/Fitforce2/GameL.png")}/>
        </div>
        
        <div className="flex flex-col items-center w-[80vw] lg:flex-row-reverse justify-center lg:items-start pb-10  space-y-2 lg:space-y-0">  
          <div className="flex flex-col items-center lg:items-start lg:w-[50vh] lg:mt-10">
            <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
            <p className="text-3xl md:text-4xl xl:text-5xl mb-2 textshadow lg:mb-6">{planTitle}</p>
            </XyzTransition>
            <p className="text-base md:text-2xl xl:text-3xl font-light textshadow">{planDescription}</p>
          </div>
          <img className="w-[50vw] lg:w-auto lg:h-[50vh] lg:mr-[5vw]" src={require("../../img/Fitforce2/Program.png")}/>
        </div>
      </div>
        </div>
    </div>
      <StaticFooter />
    </div>
  );
};

export default Fitforce2Landing;
