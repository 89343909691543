import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { redirectApi } from "../api";

const RedirectToURL = () => {
  const { key } = useParams();

  useEffect(() => {
    document.title = "Fitforce 2";
  }, []);

  useEffect(() => {
    if (key) {
      redirectApi
        .increase(key)
        .then((r) => {
          const redirectURL = r.data?.redirectURL;
          if (redirectURL) {
            window.location.href = redirectURL;
          } else {
            window.location.href = "/";
          }
        })
        .catch(() => {
          window.location.href = "/";
        });
    }
  }, [key]);

  return null;
};

export default RedirectToURL;
