import React, { useState } from "react";

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative border rounded-lg my-2">
      <button
        className="flex justify-between items-center w-full p-4 text-left text-sky-500 hover:text-sky-600 focus:outline-none focus:shadow-outline"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>
          <span className="text-gray-500 text-xs mr-4">Series: </span>
          {title}
        </p>
        <svg
          className={`w-6 h-6 transition-all transform ${isOpen ? "rotate-0" : "rotate-90"}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      <div
        className={`p-4 bg-gray-100 border-t border-gray-200 ${
          isOpen ? "block" : "hidden"
        } transition duration-300 ease-in-out`}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
