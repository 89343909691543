import React, { PureComponent } from "react";
import { Area, AreaChart, CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";

export default class Overview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newUser: [],
      activePlayer: [],
      registerByDate: [],
      Purchase: [],
      Account: 0,
      ActiveUser: 0,
      newAccount: 0,
      dailyActive: 0,
      lastActivate: "-",
      perchasePercent: 0,
      avgPurchase: 0,
      retention: {},
    };
    // fetch('/api/get-active-users', {
    //     method: 'POST',
    //     credentials: 'include',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     }
    // }).then(function(response) {
    //     return response.json()
    // }).then(function(data) {
    //     var activePlayer = []
    //     for(var i = 0; i < data.ActiveUsers.content.length; i++){
    //       var set3 = new Set(data.ActiveUsers.content[i].players);
    //       var set7 = new Set(data.ActiveUsers.content[i].players);
    //       var set30 = new Set(data.ActiveUsers.content[i].players);
    //       for(var j = 1; j < 30; j++){
    //         if(i - j >= 0){
    //           if(j < 3) data.ActiveUsers.content[i- j].players.forEach(item => set3.add(item))
    //           if(j < 7) data.ActiveUsers.content[i- j].players.forEach(item => set7.add(item))
    //           if(j < 30) data.ActiveUsers.content[i- j].players.forEach(item => set30.add(item))
    //         }
    //       }
    //       activePlayer.push({
    //         "_id":data.ActiveUsers.content[i]._id,
    //         "active1":  data.ActiveUsers.content[i].players.length,
    //         "active3": set3.size,
    //         "active7": set7.size,
    //         "active30": set30.size,
    //       })
    //     }
    //     var sumPurchase7Days = 0
    //     var peopleWhoPurchaseIn7Days = data.lastNDayPurchase.content.length
    //     var allUser = data.AllUser.content[0].value
    //     for(var i = 0; i < data.lastNDayPurchase.content.length;i++){
    //       sumPurchase7Days += data.lastNDayPurchase.content[i].revenue
    //     }
    //     var avgPurchase = sumPurchase7Days / peopleWhoPurchaseIn7Days
    //     var perchasePercent = (peopleWhoPurchaseIn7Days / allUser) * 100

    //     _this.setState({
    //       activePlayer: activePlayer,
    //       newUser: data.NewUsers.content,
    //       Purchase: data.Purchase.content,
    //       sumPurchase7Days: sumPurchase7Days,
    //       peopleWhoPurchaseIn7Days: peopleWhoPurchaseIn7Days,
    //       allUser: allUser,
    //       avgPurchase: avgPurchase,
    //       perchasePercent: perchasePercent,
    //     })
    // }).catch(function(error) {
    //     _this.props.history.push('/login')
    // });
  }

  render() {
    return (
      <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <p style={{ fontSize: "36px" }}>Overview</p>
        <br />
        <p>{"CumulativeRevenue: " + this.state.sumPurchase7Days + " THB"}</p>
        <p>{"CumulativeUsers: " + this.state.allUser}</p>
        <p>{"CumulativePurchaser: " + this.state.peopleWhoPurchaseIn7Days}</p>
        <p>{"AveragePurchase: " + this.state.avgPurchase.toFixed(2) + " THB"}</p>
        <p>{"PaidPercentage: " + this.state.perchasePercent.toFixed(2) + " %"}</p>
        <br />
        <br />
        <p>
          <b>Active users</b>
        </p>
        <LineChart
          width={1000}
          height={400}
          data={this.state.activePlayer}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="active1" stroke="#8884d8" />
          <Line type="monotone" dataKey="active3" stroke="#82ca9d" />
          <Line type="monotone" dataKey="active7" stroke="#ff7300" />
          <Line type="monotone" dataKey="active30" stroke="#413ea0" />
        </LineChart>
        <br />
        <p>
          <b>New Users</b>
        </p>
        <AreaChart
          width={1000}
          height={400}
          data={this.state.newUser}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="nNewPlayer" stackId="1" stroke="#8884d8" fill="#8884d8" />
        </AreaChart>
        <br />
        <p>
          <b>Purchase Data</b>
        </p>
        <AreaChart
          width={1000}
          height={400}
          data={this.state.Purchase}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="newPurchase" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        </AreaChart>
      </div>
    );
  }
}
