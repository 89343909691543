import React from "react";
import { useSearchParams } from 'react-router-dom';

const StaticHeader = () => {
  
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const pressLang = () => {
    setShowDropdown(!showDropdown)
  };

  const setLang = (lang) => {
    setShowDropdown(false)
    setSearchParams({["hl"]: lang})   
  }

  const language = searchParams.get('hl') || "English"

  var redeemText = "Redeem"
  var supportText = "Support"
  var downloadText = "Download"

  if(language == "Thai")
  {
    redeemText = "โค้ดพิเศษ"
    supportText = "ช่วยเหลือ"
    downloadText = "ดาวน์โหลด"
  }

  return (
    <nav class="bg-gradient-to-b from-[#5859A0] via-[#464780] to-[#20213A] dpt-2 py-2 fixed w-full z-40 top-0 start-0 text-white px-6 lg:px-10 lg:py-4">
      <div className="flex flex-1 items-center space-x-4 lg:space-x-8">
        <div className="hidden md:flex flex-none justify-center pr-6">
          <a
            href={`/?hl=${language}`}
            className="cursor-pointer text-center"
            rel="noreferrer"
          >
            <img className="h-8 lg:h-[1.75vw] lg:min-h-8" alt="logo" src="/img/fitforce2logo.png" />
          </a>
        </div>
        <div className="flex-none"> 
          <a
            href={`/redeem-code?hl=${language}`}
            className="cursor-pointer text-center text-base lg:text-xl textshadow"
            rel="noreferrer"
          >
             {redeemText}
          </a>
        </div>
        <div className="flex-none">
          <a
            href={`/support?hl=${language}`}
            className="cursor-pointer text-center text-base lg:text-xl textshadow"
            rel="noreferrer"
          >
            {supportText}
          </a>
        </div>
        <div className="flex-1"></div>
        <div className="flex-none rounded-full px-4 py-1 bg-[#000000aa] lg:px-6 lg:py-2">
          <a
            href="/get-app"
            target="_blank"
            className="cursor-pointer text-center text-base lg:text-xl textshadow"
            rel="noreferrer"
          >
            {downloadText}
          </a>
        </div>
        <div class="sm:relative inline-block text-left">
          <div>
            <button type="button" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white op px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true"
              onClick={() => pressLang()}>
              <img class="w-4" src={(language == "Thai") ? require("../img/Th.png") : require("../img/En.png")}/>
              <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                <path fill-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          { showDropdown ? (
          <div class="absolute right-0 z-10 mt-2 w-screen sm:w-40 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="py-1" role="none">
              <a class="flex justify-center items-center px-4 py-2 text-gray-700 space-x-2" role="menuitem" tabindex="-1" id="menu-item-0"
                onClick={() => setLang("Engish")}>
                <img class="w-6" src={require("../img/En.png")}/>
                <p class="text-sm">ENGLISH</p>
              </a>
            </div>
            <div class="py-1" role="none">
              <a class="flex justify-center items-center px-4 py-2 text-sm text-gray-700 space-x-2" role="menuitem" tabindex="-1" id="menu-item-2"
                onClick={() => setLang("Thai")}>
                <img class="w-6" src={require("../img/Th.png")}/> 
                <p class="text-sm">ภาษาไทย</p>
              </a>
            </div>
          </div>
        ) : null}
        </div>
      </div>
    </nav>
  );
};

export default StaticHeader;