import React from "react";
import CardFAQ from "../../components/CardFAQ";
import StaticLayout from "../../layouts/StaticLayout";

const FAQ = () => {
  return (
    <StaticLayout title="คำถามที่พบบ่อย">
      <CardFAQ
        q="FitForce 2 คืออะไร"
        a="FitForce 2 เป็นแอปพลิเคชั่นเกมออกกำลังกาย ที่ต่อยอดมาจากเกมออกกำลังกาย FitForce ที่เราเคยปล่อยออกไปเมื่อปี 2020 บน Steam แต่!!! สิ่งที่เปลี่ยนไปและเป็นจุดสำคัญของ FitForce 2 ที่แตกต่างจาก FitForce ในภาคแรก ก็คือ การที่ FitForce 2 สามารถใช้งานบนมือถือได้ 100% โดยไม่ต้องมีอุปกรณ์เสริมใด ๆ ในการเล่น"
      />
      <CardFAQ
        q="FitForce 2 สามารถใช้งานบนอุปกรณ์ใดได้บ้าง"
        a="iPhone, iPod และอุปกรณ์ที่ใช้ระบบปฏิการ Android ทุกชนิด"
      />
      <CardFAQ
        q="อยากเข้าร่วมเล่นเกมออกกำลังกาย FitForce 2 รอบ Beta Test ต้องทำอย่างไร"
        a={
          <div>
            ลงทะเบียนได้ผ่านทาง
            <a
              className="ml-2 text-blue-500 underline"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdwIV88swXC6D6CWlnwkFayGIud4LtwYCWc8hlvjKpra51qRA/viewform"
              target="_blank"
              rel="noreferrer"
            >
              ลิ้งก์นี้
            </a>
            <br />
            และรับจำนวนจำกัด เพียงแค่ 500 คนเท่านั้น
          </div>
        }
      />
      <CardFAQ
        q="อยากเข้าร่วมเล่นเกมออกกำลังกาย FitForce 2 รอบ Beta Test ต้องทำอย่างไร"
        a={
          <div>
            รอรับอีเมลตอบกลับ พร้อมกับรายละเอียดวิธีการดาวน์โหลด FitForce 2 โดยทางเราจะส่งอีเมลตอบกลับทุกวัน
            โดยแบ่งเป็น 2 รอบ
            <br />
            <br /> รอบที่ 1 : 11.00 น.
            <br /> รอบที่ 2 : 17.00 น.
          </div>
        }
      />
      <CardFAQ
        q="กรอกฟอร์มสมัครเข้าร่วม Beta Test แล้ว แต่ยังไม่ได้รับอีเมลตอบกลับ ต้องทำอย่างไรต่อ ?"
        a={
          <div>
            หากคุณไม่ได้รับอีเมลตอบกลับภายในระยะเวลา 24 ชม. หลังจากกรอกฟอร์มลงทะเบียนเข้ามา
            คุณสามารถแจ้งกับเรื่องผ่านทาง
            <a
              className="ml-2 text-blue-500 underline"
              href="https://www.facebook.com/fitforce.th"
              target="_blank"
              rel="noreferrer"
            >
              FitForce2 Facebook fanpage
            </a>
          </div>
        }
      />
      <CardFAQ
        q="สามารถเสิร์ชแล้วดาวน์โหลด FitForce 2 Beta Test เองผ่าน AppStore หรือ PlayStore ได้หรือไม่ ?"
        a="คุณไม่สามารถดาวน์โหลดได้เองผ่าน AppStore และ PlayStore โดยตรงเนื่องจากการเปิดให้เข้าเล่น Beta Test รอบนี้ เป็นการเปิดให้เข้าร่วมเฉพาะคนที่ลงทะเบียนเท่านั้น"
      />
      <CardFAQ
        q="วิธีดาวน์โหลด FitForce 2 Beta Test หลังจากได้อีเมลตอบกลับ ทำอย่างไร ?"
        a="ขั้นตอนการดาวน์โหลดถูกระบุไว้ชัดเจนในอีเมลแล้ว สามารถทำตามขั้นตอนที่ระบุไว้ให้ได้เลย"
      />
      <CardFAQ
        q="ฉันสามารถเล่นตัว Beta ได้ถึงวันที่เท่าไหร่ ?"
        a="FitForce2 Beta Test นี้จะเปิดให้เข้าใช้งานได้ถึงวันที่ 10 กุมภาพันธ์ 2565 นี้เท่านั้น"
      />
      <CardFAQ
        q="มีค่าใช้จ่ายในการเข้าร่วมหรือไม่ ?"
        a="เราเปิดให้ทดลองได้ฟรี โดยไม่มีค่าใช้จ่ายในการเข้าร่วม FitForce2 รอบ Beta Test"
      />
      <CardFAQ
        q="ถ้าฉันพบปัญหาในการเข้าเล่นเกมที่ทำให้ไมสามารถเล่นเกมได้ ฉันสามารถติดต่อ หรือแจ้งปัญหาได้ที่ไหน และอย่างไร ?"
        a={
          <div>
            หากเป็นปัญหาเกี่ยวกับการเข้าเล่นเกม / ดาวน์โหลด / เปิดใช้งานแอปเกม FitForce 2
            สามารถติดต่อแจ้งปัญหา หรือขอความช่วยเหลือได้ที่
            <a
              className="ml-2 text-blue-500 underline"
              href="https://www.facebook.com/fitforce.th"
              target="_blank"
              rel="noreferrer"
            >
              FitForce2 Facebook fanpage
            </a>
          </div>
        }
      />
      <CardFAQ
        q="Spec ขั้นต่ำในการเล่น FitForce2"
        a={
          <div>
            การจะเล่น FitForce2 ต้องมี
            <div className="flex items-center">
              <div className="w-1 h-1 mx-2 rounded-lg bg-black"></div> Android 8.0/ Android Oreo
            </div>
            <div className="flex items-center">
              <div className="w-1 h-1 mx-2 rounded-lg bg-black"></div> iPhone 7 ขึ้นไป
            </div>
          </div>
        }
      />
    </StaticLayout>
  );
};

export default FAQ;
