import React, { PureComponent } from "react";
import { fallDown as Menu } from "react-burger-menu";

import { changeLang } from "../helper/RoutingHelper";

import { withTranslation } from "react-i18next";

class Navbar extends PureComponent {
  render() {
    const { t } = this.props;
    var cidText = this.props.campaignID === null ? "" : "&cid=" + this.props.campaignID;
    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          flexDirection: "row",
          backgroundColor: "#1e2223",
          width: "100vw",
          alignItems: "center",
          padding: this.props.isMobile ? "2vw" : "2px",
          paddingLeft: "5vw",
          paddingRight: "5vw",
          zIndex: 10,
        }}
      >
        <a href={"/?hl=" + this.props.lang + cidText}>
          <img
            style={{ maxWidth: 100, width: "15vw", height: "auto" }}
            alt="Fitforce_Logo"
            src={require("../img/FitforceHome/Fitforce_Logo.png")}
          />
        </a>
        <div style={{ flex: 1 }} />
        <span
          style={{ display: !this.props.isMobile && this.props.isGuideToDownload ? "flex" : "none" }}
          onClick={() => this.props.onPressDownload()}
        >
          <img
            style={{ maxWidth: 220, width: "30vw", height: "8.5vw", maxHeight: 63 }}
            alt="Downloadbt"
            src={require("../img/FitforceHome/Downloadbt.png")}
          />
        </span>
        <div style={{ flex: 1 }} />
        <span
          style={{ marginRight: this.props.isMobile ? "10px" : "" }}
          onClick={() => changeLang(this.props.lang === "th" ? "en" : "th", this.props.lang)}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <img
              style={{ maxWidth: 20, maxHeight: 20, width: "6vw", height: "6vw" }}
              alt="Icon_material"
              src={require("../img/FitforceDownload/Icon_material-language.png")}
            />
            <p
              style={{ color: this.props.lang === "th" ? "#777" : "#fff", fontWeight: "2vw", marginLeft: 5 }}
            >
              EN
            </p>
            <div style={{ width: 2, maxHeight: 20, height: "6vw", backgroundColor: "#777", margin: 5 }} />
            <p style={{ color: this.props.lang === "th" ? "#FFF" : "#777", fontWeight: "2vw" }}>ไทย</p>
          </div>
        </span>
        {!this.props.isMobile && (
          <a className="ml-3" href={"https://www.facebook.com/fitforce." + this.props.lang}>
            <img
              style={{ maxWidth: 30, width: "6vw", height: "6vw", maxHeight: 30 }}
              alt="fb_icon"
              src={require("../img/FitforceHome/fb_icon.png")}
            />
          </a>
        )}
        {this.props.isMobile && (
          <Menu right>
            <a
              style={{
                color: "#fff",
                fontSize: "5vw",
                marginTop: "10vw",
                borderWidth: 0,
                fontFamily: this.props.lang === "th" ? "Kanit" : "Roboto",
              }}
              href="/"
            >
              {t("Home")}
            </a>
            <div style={{ width: "100%", height: 1, backgroundColor: "#f0722e", margin: 5 }} />
            <a
              style={{
                color: "#fff",
                fontSize: "5vw",
                fontFamily: this.props.lang === "th" ? "Kanit" : "Roboto",
              }}
              className="menu-item"
              href={"/how-to-install?hl=" + this.props.lang + cidText}
            >
              {t("Download")}
            </a>
            <div style={{ width: "100%", height: 1, backgroundColor: "#f0722e", margin: 5 }} />
            <a
              style={{
                color: "#fff",
                fontSize: "5vw",
                fontFamily: this.props.lang === "th" ? "Kanit" : "Roboto",
              }}
              className="menu-item"
              href={"https://www.facebook.com/fitforce." + this.props.lang}
            >
              {t("Facebook")}
            </a>
            <div style={{ width: "100%", height: 1, backgroundColor: "#f0722e", margin: 5 }} />
          </Menu>
        )}
      </div>
    );
  }
}

export default withTranslation()(Navbar);
