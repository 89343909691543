import React from "react";
import CardInstallation from "../../components/CardInstallation";
import StaticLayout from "../../layouts/StaticLayout";

const CBTInstallAndroid = () => {
  return (
    <StaticLayout
      title={
        <div>
          3 ขั้นตอน <br />
          ติดตั้งแอปพลิเคชัน (Android)
        </div>
      }
      titleCenter
    >
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-wrap justify-center">
          <CardInstallation title="1. เข้าไปที่อีเมลจากทีมงาน Runnex แล้วกดดาวน์โหลด">
            <img src={require("../../img/Fitforce2/Howto Android 1.png")} alt="android-step1" />
          </CardInstallation>
          <CardInstallation title="2. ดาวน์โหลดและติดตั้งแอปพลิเคชัน">
            <img src={require("../../img/Fitforce2/Howto Android 2.png")} alt="android-step2" />
          </CardInstallation>
          <CardInstallation title="3. เมื่อเข้าแอปพลิเคชัน FITFORCE2 ให้กรอก รหัส Beta Test ที่ได้จากอีเมลของเรา แล้วเริ่มเล่นได้เลย!">
            <img src={require("../../img/Fitforce2/Howto Android 3.png")} alt="android-step3" />
          </CardInstallation>
        </div>
      </div>
    </StaticLayout>
  );
};

export default CBTInstallAndroid;
