import React from "react";

const CardFAQ = ({ q, a }) => {
  return (
    <div className="rounded-lg p-8 bg-gray-100 mb-4">
      <div className="flex space-x-5 items-baseline">
        <div className="bg-red-500 w-10 h-10 text-white flex-shrink-0 text-xl flex items-center justify-center">
          Q
        </div>
        <div className="text-red-500 font-bold">{q}</div>
      </div>
      <div className="flex space-x-5 items-baseline mt-1">
        <div className="bg-blue-500 w-10 h-10 text-white flex-shrink-0 text-xl flex items-center justify-center">
          A
        </div>
        <div>{a}</div>
      </div>
    </div>
  );
};

export default CardFAQ;
