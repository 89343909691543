import React, { useState } from "react";
import StaticLayout from "../../layouts/StaticLayout";
import { useSearchParams } from 'react-router-dom';
import { XyzTransition, XyzTransitionGroup } from "@animxyz/react";

const Support = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const language = searchParams.get('hl') || "English"

    var title = "WE'VE GOT YOU"
    var description = "We'd love to help. Send us an email, we will reply as soon as possible."
    var email = "Email"
    var supportMail = "RunnexTechnology@gmail.com"

    if(language == "Thai")
    {
        title = "เราเข้าใจคุณ"
        description = "เรายินดีอย่างยิ่งที่จะได้ช่วยคุณ หากพบปัญหาอะไร ลองอีเมลมาหาเราสิ เราจะพยายามตอบกลับอย่างเร็วที่สุด"
        email = "อีเมล"
    }
    
  return (
    <StaticLayout title="Support" showTitle ={false}>
        <div className="text-white mt-[10vh] lg:mt-[15vh]">
            <div className="flex flex-col items-center space-y-10 lg:space-y-5">
                <div className="flex flex-col items-center w-[80vw] lg:flex-row justify-center lg:items-start lg:-mb-[5vw]">  
                    <img className="w-[50vw] lg:w-auto lg:h-[50vh] lg:mr-[5vw]" src={require("../../img/Fitforce2/support01.png")}/>
                    <div className="flex flex-col items-center lg:items-start lg:w-[50vh] lg:mt-20">
                        <XyzTransition appearVisible xyz="fade small out-down out-rotate-right">
                        <p className="text-3xl lg:text-5xl mb-1 textshadow lg:mb-6">{title}</p>
                        </XyzTransition>
                        <p className="text-base lg:text-3xl font-light textshadow">{description}</p>
                        <div className="flex flex-row mt-10 space-x-2">
                            <img className="h-[10vh]" src={require("../../img/Fitforce2/support02.png")}/>
                            <div className="flex flex-col">
                                <p className="font-semibold text-lg lg:text-2xl textshadow">{email}</p>
                                <p className="text-md lg:text-lg textshadow">{supportMail}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </StaticLayout>
  );
};

export default Support;
