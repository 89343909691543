import { useFormik } from "formik";
import React from "react";
import useAuth from "../../hooks/useAuth";

const AdminLogin = () => {
  const { login } = useAuth();

  const formik = useFormik({
    initialValues: {
      Username: "",
      Password: "",
    },
    onSubmit: login,
  });

  return (
    <section className="bg-gradient-to-b from-indigo-600 to-gray-200 min-h-screen">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24">
        <div className="flex justify-center mb-8">
          <img className="h-12" alt="logo" src="/img/fitforce2logo.png" />
        </div>
        <div className="justify-center mx-auto text-left align-bottom transition-all transform bg-white rounded-lg sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="grid flex-wrap items-center justify-center grid-cols-1 mx-auto shadow-xl lg:grid-cols-2 rounded-xl">
            <div className="w-full px-6 py-3">
              <div>
                <div className="mt-3 text-left sm:mt-5">
                  <div className="inline-flex items-center w-full">
                    <h3 className="text-lg font-bold text-neutral-600 l eading-6 lg:text-5xl">Sign in</h3>
                  </div>
                  <div className="mt-4 text-base text-gray-500">
                    <p>Fitforce2 administrator system</p>
                  </div>
                </div>
              </div>
              <div className="mt-6 space-y-2">
                <div>
                  <label htmlFor="Username" className="sr-only">
                    Username
                  </label>
                  <input
                    type="text"
                    name="Username"
                    onChange={formik.handleChange}
                    value={formik.values.Username}
                    className="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    placeholder="Enter your Username"
                  />
                </div>
                <div>
                  <label htmlFor="Password" className="sr-only">
                    Password
                  </label>
                  <input
                    type="password"
                    name="Password"
                    onChange={formik.handleChange}
                    value={formik.values.Password}
                    className="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    placeholder="Enter your Password"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-8 lg:space-y-2">
                <button
                  onClick={formik.handleSubmit}
                  type="button"
                  className="flex items-center justify-center w-full px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Login
                </button>
              </div>
            </div>
            <div className="order-first hidden w-full lg:block p-3">
              <img
                className="object-cover h-full bg-cover rounded-lg round"
                alt="login"
                src={require("../../img/Fitforce2/ff2-pfp.jpg")}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
