import React, { PureComponent } from "react";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";

var top5Currencies = {};

export default class UserByCurrency extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      interestedCurrencies: ["", "", "", "", "", ""],
      currencyList: [],
      activeCurrencies: [],
      newCurrencies: [],
    };
    this.getCurrencyDistribution = this.getCurrencyDistribution.bind(this);
    this.getCurrenciesByActiveUser = this.getCurrenciesByActiveUser.bind(this);

    this.getCurrencyDistribution();
  }

  getCurrencyDistribution() {
    var _this = this;
    fetch("/api/user-currency-distribution", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var currencyList = {};
        data["currencyDistribution"].content.forEach((element) => {
          var currencyName = element["_id"]["currency"];
          if (currencyList[currencyName] === undefined) {
            currencyList[currencyName] = { ios: 0, android: 0, sum: 0 };
          }
          currencyList[currencyName][element["_id"]["os"]] = element["count"];
          currencyList[currencyName]["sum"] += element["count"];
        });
        var sortable = [];
        for (var item in currencyList) {
          sortable.push([item, currencyList[item], currencyList[item].sum]);
        }
        sortable.sort(function (a, b) {
          return -(a[2] - b[2]);
        });
        var interestCurrenciesArr = [];
        for (var i = 0; i < 5; i++) {
          top5Currencies[sortable[i][0]] = true;
          interestCurrenciesArr.push(sortable[i][0] !== "" ? sortable[i][0] : "Unknown");
        }
        interestCurrenciesArr.push("etc");
        _this.getCurrenciesByActiveUser();
        _this.setState({
          currencyList: sortable,
          interestedCurrencies: interestCurrenciesArr,
        });
      });
  }

  getCurrenciesByActiveUser() {
    var _this = this;
    fetch("/api/currency-active-user", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var currencyByDate = {};
        var dataName = [];
        data["currencyByActiveUser"].content.forEach((element) => {
          var date = element["_id"]["date"];
          if (currencyByDate[date] === undefined) {
            dataName.push(date);
            currencyByDate[date] = {};
          }
          var key = element["_id"]["currency"];
          if (!top5Currencies[key]) {
            key = "etc";
          } else if (key === "") {
            key = "Unknown";
          }
          if (currencyByDate[date][key] === undefined) {
            currencyByDate[date][key] = 0;
          }
          currencyByDate[date][key] = currencyByDate[date][key] + element["nNewPlayer"];
        });
        var formattedData = [];
        dataName.sort();
        for (var i = 0; i < dataName.length; i++) {
          var dateKey = dataName[i];
          formattedData.push({
            ...currencyByDate[dateKey],
            date: dateKey,
          });
        }
        _this.setState({ activeCurrencies: formattedData });
        _this.getCurrenciesByNewUser();
      });
  }

  getCurrenciesByNewUser() {
    var _this = this;
    fetch("/api/currency-new-user", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var currencyByDate = {};
        var dataName = [];
        data["currencyByNewUser"].content.forEach((element) => {
          var date = element["_id"]["date"];
          if (currencyByDate[date] === undefined) {
            dataName.push(date);
            currencyByDate[date] = {};
          }
          var key = element["_id"]["currency"];
          if (!top5Currencies[key]) {
            key = "etc";
          } else if (key === "") {
            key = "Unknown";
          }
          if (currencyByDate[date][key] === undefined) {
            currencyByDate[date][key] = 0;
          }
          currencyByDate[date][key] = currencyByDate[date][key] + element["nNewPlayer"];
        });
        var formattedData = [];
        dataName.sort();
        for (var i = 0; i < dataName.length; i++) {
          var dateKey = dataName[i];
          formattedData.push({
            ...currencyByDate[dateKey],
            date: dateKey,
          });
        }
        _this.setState({ newCurrencies: formattedData });
      });
  }

  render() {
    var displayItem = [];
    this.state.currencyList.forEach((item) => {
      displayItem.push(
        <tr>
          <td style={{ textAlign: "right" }}>{item[0] !== "" ? item[0] : "Unknown"}</td>
          <td style={{ textAlign: "right" }}>{item[1].ios}</td>
          <td style={{ textAlign: "right" }}>{item[1].android}</td>
          <td style={{ textAlign: "right" }}>{item[1].sum}</td>
        </tr>
      );
    });
    return (
      <center>
        <p style={{ fontSize: "36px", textAlign: "center" }}>User Currency</p>
        <br />
        <p>Active user currency</p>
        <LineChart
          width={600}
          height={300}
          data={this.state.activeCurrencies}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line type="monotone" dataKey={this.state.interestedCurrencies[0]} stroke="#810081" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[1]} stroke="#f2d58f" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[2]} stroke="#ff7777" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[3]} stroke="#3fdbcb" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[4]} stroke="#f6d4cf" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[5]} stroke="#333333" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
        </LineChart>
        <br />
        <p>New user currency</p>
        <LineChart
          width={600}
          height={300}
          data={this.state.newCurrencies}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line type="monotone" dataKey={this.state.interestedCurrencies[0]} stroke="#810081" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[1]} stroke="#f2d58f" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[2]} stroke="#ff7777" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[3]} stroke="#3fdbcb" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[4]} stroke="#f6d4cf" />
          <Line type="monotone" dataKey={this.state.interestedCurrencies[5]} stroke="#333333" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
        </LineChart>
        <br />
        <p>All time currency distribution</p>
        <br />
        <table>
          <tr>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              Currency
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              #iOS
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              #Android
            </th>
            <th style={{ minWidth: "150px", textAlign: "right", backgroundColor: "#bb4444", color: "#fff" }}>
              #All
            </th>
          </tr>
          {displayItem}
        </table>
      </center>
    );
  }
}
