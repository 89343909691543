import React from "react";

const QuizOverview = () => {
  return (
    <>
      <div>
        To be Implemented
      </div>
    </>
  );
};

export default QuizOverview;
