const en = {
  "Welcome to React": "Welcome to react-i18next",
  "INERACTIVE FITNESS IN YOUR HAND": "INERACTIVE FITNESS IN YOUR HAND",
  "No pricy devices needed. Just connect your smartphone to a PC and enjoy the emersive experience.":
    "No pricy devices needed. Just connect your smartphone to a PC and enjoy the immersive experience.",
  "PHONE AS CONTROLLER": "PHONE AS CONTROLLER",
  "EXERCISE TRACKING": "EXERCISE TRACKING",
  "LOTS OF MINI-GAMES": "LOTS OF MINI-GAMES",
  "PHONE AS A CONTROLLER": "PHONE AS A CONTROLLER",
  "Detect your motion from smartphone connected to PC to make emersive exercise experience.":
    "Detect your motion from smartphone connected to PC to make emersive exercise experience.",
  "Show your daily exercise result and track your progress in health log":
    "Show your daily exercise result and track your progress in health log",
  "VARIOUS MODE": "VARIOUS MODE",
  "CLASSIC MODE": "CLASSIC MODE",
  "Use real exercise posture to control a various mini-games.":
    "Use real exercise posture to control a various mini-games.",
  "PARTY MODE": "PARTY MODE",
  "It's better to have an exercise partner, Party mode is the answer.":
    "It's better to have an exercise partner, Party mode is the answer.",
  "PROGRAM MODE": "PROGRAM MODE",
  "Design your own exercise set to met your fitness goal.":
    "Design your own exercise set to achieve your fitness goal.",
  "BOSS FIGHT MODE": "BOSS FIGHT MODE",
  "Conquer the exercise enemies and get your rewards.": "Conquer the exercise enemies and get your rewards.",
  "LOT OF MINIGAMES AWAIT!": "LOT OF MINIGAMES AWAIT!",
  "ABOUT US": "ABOUT US",
  "Runnex Technology co., ltd.": "Runnex Technology co., ltd.",
  "We are a small group of passionate people who aim to bring the fitness balance back to this world, with our technology and design, everything possible":
    "We are a small group of passionate people who aim to bring fitness balance to this world. With our technology and design, everything is possible",
  "CONTACT US": "CONTACT US",
  Home: "Home",
  Download: "Download",
  STEP: "STEP",
  "install on your pc": "INSTALL ON YOUR PC",
  "or get it on": "or get it on",
  "install on your phone": "INSTALL ON YOUR PHONE",
  "install on your mobile": "INSTALL ON YOUR MOBILE",
  or: "or",
  "2 EASY STEPS TO DOWNLOAD": "2 EASY STEPS TO DOWNLOAD",
  "You're ready! Let's get fit with FITFORCE": "You're ready! Let's get fit with FITFORCE",
  "play on": "play on",
  "Scan QR code and download it on store": "Scan QR code and download it on store",
};

export default en;
