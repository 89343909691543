import React from "react";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

const CopyToClipBoardIcon = ({ text }) => {
  return (
    <Tooltip title="copied!" position="right" trigger="click" arrow duration={0}>
      <svg
        onClick={() => navigator.clipboard.writeText(text)}
        xmlns="http://www.w3.org/2000/svg"
        className="ml-2 icon icon-tabler icon-tabler-copy cursor-pointer"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
        <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
      </svg>
    </Tooltip>
  );
};

export default CopyToClipBoardIcon;
