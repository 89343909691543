const th = {
  "Welcome to React": "ยินดีต้อนรับเข้าสู่ react-i18next",
  "INERACTIVE FITNESS IN YOUR HAND": "ออกกำลังกาย เล่นได้ที่บ้านแค่คอมกับมือถือ",
  "No pricy devices needed. Just connect your smartphone to a PC and enjoy the emersive experience.":
    "ไม่ต้องใช้อุปกรณ์ราคาแพง แค่เชื่อมต่อสมาร์ทโฟนเข้ากับคอมพิวเตอร์ ก็สนุกกับการออกกำลังกายแบบล้ำๆ ได้",
  "PHONE AS CONTROLLER": "ใช้สมาร์ทโฟนควบคุม",
  "EXERCISE TRACKING": "บันทึกผลออกกำลังกาย",
  "LOTS OF MINI-GAMES": "มีมินิเกมมากมาย",
  "PHONE AS A CONTROLLER": "ใช้สมาร์ทโฟนควบคุม",
  "Detect your motion from smartphone connected to PC to make emersive exercise experience.":
    "ตรวจจับการออกกำลังกายของคุณด้วยสมาร์ทโฟน คุณจะเล่นเกมไปพร้อมๆกับออกกำลังกายได้แบบล้ำๆ ได้เหงื่อ และได้สุขภาพ",
  "Show your daily exercise result and track your progress in health log":
    "แสดงผลลัพธ์การออกกำลังกายจากทุกเกมที่คุณเล่น และแสดงผลรวมรายวันได้",
  "VARIOUS MODE": "โหมดต่างๆ มากมาย",
  "CLASSIC MODE": "โหมดคลาสสิก",
  "Use real exercise posture to control a various mini-games.":
    "มินิเกมที่มีท่าออกกำลังกายหลากหลาย ใช้เวลาไม่นานแต่ว่าเหนื่อยและสนุกสุดๆ",
  "PARTY MODE": "โหมดปาร์ตี้",
  "It's better to have an exercise partner, Party mode is the answer.":
    "เพิ่มความท้าทายด้วยโหมดปาร์ตี้ ออกกำลังกายไปด้วยกันกับเพื่อนหรือครอบครัวก็ยิ่งสนุก",
  "PROGRAM MODE": "โหมดโปรแกรม",
  "Design your own exercise set to met your fitness goal.":
    "ออกแบบเซ็ตการออกกำลังกายและเวลาให้เหมาะกับคุณ ด้วยมินิเกมต่างๆ",
  "BOSS FIGHT MODE": "โหมดสู้บอส",
  "Conquer the exercise enemies and get your rewards.":
    "ตะลุยด่านพิชิตมอนสเตอร์ด้วยการออกกำลังกายแบบฟูลบอดี้สุดมันส์ สนุกสะใจ แถมยังเบิร์นแคลโหด",
  "LOT OF MINIGAMES AWAIT!": "มินิเกมมากมายรอคุณอยู่!",
  "ABOUT US": "เกี่ยวกับเรา",
  "Runnex Technology co., ltd.": "บจก. รันเน็กซ์ เทคโนโลยี",
  "We are a small group of passionate people who aim to bring the fitness balance back to this world, with our technology and design, everything possible":
    "เราคือกลุ่มคนเล็กๆ ที่มีความมุ่งมั่นที่จะทำให้ทุกคนมีสุขภาพที่ดีขึ้นด้วยการออกกำลังกายที่ทำได้ง่ายๆ เราเชื่อว่าทุกสิ่งเป็นไปได้ด้วยเทคโนโลยี และการออกแบบของเรา",
  "CONTACT US": "ติดต่อเรา",
  Home: "หน้าหลัก",
  Download: "ดาวน์โหลด",
  STEP: "ขั้นตอนที่",
  "install on your pc": "ติดตั้งลงบน PC",
  "or get it on": "หรือ ดาวน์โหลดที่",
  "install on your phone": "ติดตั้งลงมือถือ",
  "install on your mobile": "ติดตั้งลงมือถือ",
  or: "หรือ",
  "2 EASY STEPS TO DOWNLOAD": "2 ขั้นตอนดาวน์โหลดง่ายๆ",
  "You're ready! Let's get fit with FITFORCE": "คุณพร้อมแล้ว! เริ่มฟิตกับ FITFORCE ได้เลย",
  "play on": "เล่นได้ที่",
  "Scan QR code and download it on store": "สแกน QR code และดาวน์โหลดได้บน store",
};

export default th;
