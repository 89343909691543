import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { redirectApi } from "../api";
import CopyToClipBoardIcon from "../components/CopyToClipBoardIcon";
import DataRefresher from "../components/DataRefresher";

const makeFitforceLink = (key) => {
  return `${window.location.origin}/r/${key}`;
};

const UrlManager = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);

  const formik = useFormik({
    initialValues: {
      Name: "",
      RedirectURL: "",
    },
    onSubmit: (values) => {
      setAdding(true);
      redirectApi.create(values).then((r) => {
        setAdding(false);
        formik.resetForm();
        fetchData();
      });
    },
  });

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      setLoading(true);
      redirectApi.delete(id).then((r) => {
        fetchData();
        setLoading(false);
      });
    }
  };

  const fetchData = () => {
    setLoading(true);
    redirectApi.getAll().then((r) => {
      setData(r.data || []);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "Source name / Target url",
      selector: (row) => (
        <div>
          <div className="font-bold">{row.name}</div>
          <div className="flex">
            <div className="text-xs mt-1 w-[350px]">{row.redirecturl}</div>
            <CopyToClipBoardIcon text={row.redirecturl} />
          </div>
        </div>
      ),
      width: "400px",
      sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
      sortable: true,
    },
    {
      name: "Ads url",
      selector: (row) => (
        <div className="flex items-center">
          <div>{makeFitforceLink(row.key)}</div>
          <CopyToClipBoardIcon text={makeFitforceLink(row.key)} />
        </div>
      ),

      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="flex space-x-1">
            <button
              onClick={() => handleDelete(row._id)}
              type="button"
              className="inline-block my-2 px-3 py-2 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-red-800"
            >
              Delete
            </button>
          </div>
        );
      },
      width: "100px",
    },
    { name: "Used Count", selector: (row) => row.usedcount, width: "120px", sortable: true },
  ];

  return (
    <>
      <DataTable
        title={
          <div className="flex justify-between">
            <div>Ads Manager</div>
            <DataRefresher refetch={fetchData} loading={loading} />
          </div>
        }
        columns={columns}
        data={data}
        disabled={loading}
      />
      {!adding ? (
        <button
          onClick={() => setAdding(true)}
          type="button"
          className="mt-10 m-4 px-6 py-2 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
        >
          + Add new record
        </button>
      ) : (
        <div className="mt-10 flex flex-col border border-gray-400 m-4 p-4 w-96 rounded-lg">
          <input
            type="text"
            name="Name"
            onChange={formik.handleChange}
            value={formik.values.Name}
            placeholder="Source name e.g. Facebook"
            className="block w-full px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
          />
          <input
            type="text"
            name="RedirectURL"
            onChange={formik.handleChange}
            value={formik.values.RedirectURL}
            placeholder="Target url e.g. https://facebook.com/fitforce2"
            className="block w-full mt-2 px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
          />
          <div className="flex">
            <button
              onClick={() => setAdding(false)}
              type="button"
              className="mt-3 ml-auto px-6 py-2 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
            >
              Cancel
            </button>
            <button
              onClick={() => formik.handleSubmit()}
              type="button"
              className="mt-3 ml-2 px-6 py-2 border-2 border-teal-500 text-teal-500 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default UrlManager;
