import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { adminApi } from "../../api";
import DataRefresher from "../../components/DataRefresher";
import { customTableStyles } from "../../helper/misc";

const AdminActivationCode = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment());

  const fetchData = () => {
    setLoading(true);
    adminApi.getCBTUsers().then((r) => {
      setData(r.data || []);
      setLastUpdated(moment());
      setLoading(false);
      setInitialized(true);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filteredData = useMemo(() => {
    if (searchText === "") return data;
    return data.filter((item) => item.email.toLowerCase().includes(searchText.toLowerCase()));
  }, [data, searchText]);

  const columns = [
    { name: "Email", selector: (row) => row.email, sortable: true },
    {
      name: "Activation Code",
      selector: (row) => row.activationCode,
      width: "150px",
      sortable: true,
      wrap: true,
    },
    { name: "Age", selector: (row) => row.age, width: "70px", sortable: true },
    { name: "Job", selector: (row) => row.job, width: "120px", sortable: true, wrap: true },
    { name: "Gender", selector: (row) => row.gender, width: "90px", sortable: true },
  ];

  return (
    <div className="max-w-2xl mx-auto">
      <input
        type="text"
        className="float-right border px-2 m-2"
        placeholder="email search"
        onChange={(e) => setSearchText(e.target.value)}
      />
      <DataTable
        title={
          <div className="flex justify-between">
            <div>Activation Code Mapping</div>
            <DataRefresher refetch={fetchData} loading={loading} lastUpdated={lastUpdated} />
          </div>
        }
        columns={columns}
        data={filteredData}
        progressPending={!initialized}
        disabled={loading}
        pagination
        customStyles={customTableStyles}
      />
    </div>
  );
};

export default AdminActivationCode;
