import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const menuItems = [
  {
    name: "CBT Overview",
    path: "/admin/overview",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-chart-area-line"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <polyline points="4 19 8 13 12 15 16 10 20 14 20 19 4 19"></polyline>
        <polyline points="4 12 7 8 11 10 16 4 20 8"></polyline>
      </svg>
    ),
  },
  {
    name: "CBT Registration",
    path: "/admin/cbt-registration",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-checkup-list"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
        <rect x="9" y="3" width="6" height="4" rx="2"></rect>
        <path d="M9 14h.01"></path>
        <path d="M9 17h.01"></path>
        <path d="M12 16l1 1l3 -3"></path>
      </svg>
    ),
  },
  {
    name: "CBT Retention",
    path: "/admin/retention",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-arrow-back"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
      </svg>
    ),
  },
  {
    name: "CBT Activation Code",
    path: "/admin/activation-code",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-directions"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 21v-4" />
        <path d="M12 13v-4" />
        <path d="M12 5v-2" />
        <path d="M10 21h4" />
        <path d="M8 5v4h11l2 -2l-2 -2z" />
        <path d="M14 13v4h-8l-2 -2l2 -2z" />
      </svg>
    ),
  },
  {
    name: "CBT Feedback",
    path: "/admin/cbt-feedbacks",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-message-circle"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
        <path d="M12 12l0 .01" />
        <path d="M8 12l0 .01" />
        <path d="M16 12l0 .01" />
      </svg>
    ),
  },
  {
    name: "Ads Manager",
    path: "/admin/ads-manager",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-ad-2"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M11.933 5h-6.933v16h13v-8" />
        <path d="M14 17h-5" />
        <path d="M9 13h5v-4h-5z" />
        <path d="M15 5v-2" />
        <path d="M18 6l2 -2" />
        <path d="M19 9h2" />
      </svg>
    ),
  },
  {
    name: "Article Manager",
    path: "/admin/articles",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-article"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
        <path d="M7 8h10"></path>
        <path d="M7 12h10"></path>
        <path d="M7 16h10"></path>
      </svg>
    ),
  },
  {
    name: "Quiz Manager",
    path: "/admin/questions",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-message-circle"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
        <path d="M12 12l0 .01" />
        <path d="M8 12l0 .01" />
        <path d="M16 12l0 .01" />
      </svg>
    ),
  },
];

const privatePaths = menuItems.map((item) => item.path);
const isPrivate = (path) => privatePaths.some((x) => path.startsWith(x)) || path === "/admin";

const AdminLayout = ({ children }) => {
  const { getShownName, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (isPrivate(location.pathname)) document.title = "Fitforce 2 Admin";
  }, [location.pathname]);

  if (!isPrivate(location.pathname)) return children;

  return (
    <div className="flex overflow-hidden bg-white">
      <div
        className={isMenuOpen ? "flex fixed flex-shrink-0 w-64" : "w-0 -translate-x-64 transition-transform"}
      >
        <div className="flex flex-col w-64 h-screen">
          <div className="flex flex-col flex-grow pt-5 overflow-y-auto border-r bg-neutral-800">
            <div className="flex flex-col items-center flex-shrink-0 px-4">
              <a href="./index.html" className="px-8 text-left focus:outline-none">
                <h2 className="block p-2 text-xl font-medium tracking-tighter transition duration-500 ease-in-out transform cursor-pointer text-neutral-200 hover:text-neutral-200">
                  Fitforce2 Admin
                </h2>
              </a>
              <button className="hidden rounded-lg focus:outline-none focus:shadow-outline">
                <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col flex-grow px-4 mt-5">
              <nav className="flex-1 space-y-1 bg-neutral-800">
                <ul>
                  {menuItems.map((item, index) => (
                    <li key={index} className="relative">
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "inline-flex items-center w-full px-4 py-2 mt-1 text-base transition duration-500 ease-in-out transform border rounded-lg text-neutral-200 bg-neutral-900 border-neutral-900 focus:shadow-outline"
                            : "inline-flex items-center w-full px-4 py-2 mt-1 text-sm transition duration-500 ease-in-out transform border rounded-lg text-neutral-400 border-neutral-800 hover:border-neutral-800 focus:shadow-outline hover:bg-neutral-900"
                        }
                        to={item.path}
                      >
                        {item.icon}
                        <span className="ml-4"> {item.name}</span>
                      </NavLink>
                      {!item.new ? null : (
                        <div className="absolute right-0 top-4 text-white bg-red-500 text-[8px] py-[2px] px-1 rounded-md">
                          NEW
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div className="flex flex-shrink-0 p-4 px-4 bg-neutral-900 relative">
              <span className="flex-shrink-0 block w-full group">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block rounded-full h-9 w-9"
                      src={require("../img/Fitforce2/ff2-pfp-trans-sm.png")}
                      alt="profile"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-neutral-200">{getShownName()}</p>
                    <p className="text-xs text-red-600 cursor-pointer" onClick={logout}>
                      Logout
                    </p>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={isMenuOpen ? "w-64" : "w-0 transition-all"}></div>
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 focus:outline-none min-h-screen">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="icon icon-tabler icon-tabler-menu-2 cursor-pointer ml-4 mt-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <line x1="4" y1="6" x2="20" y2="6"></line>
              <line x1="4" y1="12" x2="20" y2="12"></line>
              <line x1="4" y1="18" x2="20" y2="18"></line>
            </svg>
          </div>
          <div className="py-6">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">{children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
