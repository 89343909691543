import React, { PureComponent } from "react";

export default class FitnowNav extends PureComponent {
  render() {
    return (
      <div
        style={{
          display: "flex",
          position: "fixed",
          flexDirection: "row",
          backgroundColor: "#fff",
          width: "100%",
          alignItems: "center",
          padding: this.props.isMobile ? "2vw" : "2px",
          paddingLeft: "5vw",
          paddingRight: "5vw",
          zIndex: 10,
        }}
      >
        <img
          style={{ maxWidth: 40, width: "5vw", height: "7.45vw", maxHeight: 60, padding: 3 }}
          alt="firelogo"
          src={require("../img/FitNow/firelogo 2.png")}
        />
        <div style={{ width: "1vw", maxWidth: 7 }} />
        <p style={{ fontSize: "clamp(16px, 4vw, 40px)", fontFamily: "Rubik" }}>
          <b>fitnow</b>
        </p>
        <div style={{ flex: 1 }} />
      </div>
    );
  }
}
