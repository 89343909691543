import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { adminApi } from "../../api";
import DataRefresher from "../../components/DataRefresher";
import { customTableStyles, formatDateTime } from "../../helper/misc";

const AdminActivationCode = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment());

  const fetchData = () => {
    setLoading(true);
    adminApi.getCBTFeedbacks().then((r) => {
      setData(r.data || []);
      setLastUpdated(moment());
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getRatingColor = (rating) => {
    if (rating >= 5) return " bg-green-300";
    if (rating >= 4) return " bg-green-200";
    if (rating >= 3) return " bg-yellow-200";
    if (rating >= 2) return " bg-yellow-200";
    if (rating >= 1) return " bg-red-300";
    return " bg-red-300";
  };

  const columns = [
    {
      name: "Player Info",
      selector: (row) => (
        <div className="p-2">
          <div className="mb-1 text-sm">
            <span className="mr-1">{row.playerinfo?.job}</span>
            <span className="mr-1">({row.playerinfo?.gender})</span>
            <span className="mr-1">({row.playerinfo?.age})</span>
          </div>
          <p className="text-xs text-gray-400">
            <b>OS</b>: {row.playerinfo?.osVersion}
          </p>
          <p className="text-xs text-gray-400">
            <b>Device</b>: {row.playerinfo?.deviceModel}
          </p>
        </div>
      ),
      minWidth: "250px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Registered Reason",
      selector: (row) => row.playerinfo?.registeredReason || "-",
      minWidth: "100px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Rating",
      selector: (row) => (
        <div className={"px-3 py-2 rounded-full" + getRatingColor(row.rating)}>
          <p className="text-xl">{row.rating}</p>
        </div>
      ),
      width: "100px",
      sortable: true,
      sortFunction: (a, b) => a.rating - b.rating,
    },
    {
      name: "Comment",
      selector: (row) => row?.comment || "-",
      minWidth: "200px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Suggestion",
      selector: (row) => row?.suggestion || "-",
      minWidth: "200px",
      sortable: true,
      wrap: true,
    },
    {
      name: "Timestamp",
      selector: (row) => formatDateTime(row.timestamp),
      minWidth: "150px",
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div>
      <DataTable
        title={
          <div className="flex justify-between">
            <div>CBT Feedbacks</div>
            <DataRefresher refetch={fetchData} loading={loading} lastUpdated={lastUpdated} />
          </div>
        }
        columns={columns}
        data={data}
        disabled={loading}
        pagination
        customStyles={customTableStyles}
      />
    </div>
  );
};

export default AdminActivationCode;
