import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { NavLink, useNavigate } from "react-router-dom";
import { articleApi } from "../../api";
import DataRefresher from "../../components/DataRefresher";
import { makeArticleUrl } from "../../helper/misc";

const AdminArticleList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    articleApi.getAll().then((r) => {
      setData(r.data || []);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { name: "Title", selector: (row) => row.title },
    { name: "Url", selector: (row) => makeArticleUrl(row.slug) },
    { name: "ViewCount", selector: (row) => row.viewCount, width: "100px" },
  ];

  return (
    <div className="max-w-2xl mx-auto">
      <DataTable
        title={
          <div className="flex justify-between">
            <div>Article Manager</div>
            <DataRefresher refetch={fetchData} loading={loading} />
          </div>
        }
        onRowClicked={(row) => navigate(`/admin/articles/${row._id}`)}
        pointerOnHover={true}
        highlightOnHover={true}
        className="mb-10"
        columns={columns}
        data={data}
        disabled={loading}
      />
      <NavLink
        to="/admin/articles/create"
        className="px-6 py-2 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
      >
        Create New Article
      </NavLink>
    </div>
  );
};

export default AdminArticleList;
