import React, { PureComponent } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

const renderExperienceTable = (data, firstColumnName, isBig) => {
  var displayItem = [];
  for (var key in data) {
    displayItem.push(
      <tr>
        <td style={{ textAlign: "right", fontSize: isBig ? "16px" : "14px" }}>{key}</td>
        <td style={{ textAlign: "right", fontSize: isBig ? "16px" : "14px" }}>{data[key].startCount}</td>
        <td style={{ textAlign: "right", fontSize: isBig ? "16px" : "14px" }}>
          {data[key].endCount + " (" + ((data[key].endCount / data[key].startCount) * 100).toFixed(0) + "%)"}
        </td>
        <td style={{ textAlign: "right", fontSize: isBig ? "16px" : "14px" }}>{data[key].startUnique}</td>
        <td style={{ textAlign: "right", fontSize: isBig ? "16px" : "14px" }}>
          {data[key].endUnique +
            " (" +
            ((data[key].endUnique / data[key].startUnique) * 100).toFixed(0) +
            "%)"}
        </td>
      </tr>
    );
  }
  return (
    <table>
      <tr>
        <th
          style={{
            minWidth: isBig ? "150px" : "125px",
            textAlign: "right",
            backgroundColor: "#bb4444",
            color: "#fff",
            fontSize: isBig ? "16px" : "14px",
          }}
        >
          {firstColumnName}
        </th>
        <th
          style={{
            minWidth: isBig ? "150px" : "125px",
            textAlign: "right",
            backgroundColor: "#bb4444",
            color: "#fff",
            fontSize: isBig ? "16px" : "14px",
          }}
        >
          #GamePlayed
        </th>
        <th
          style={{
            minWidth: isBig ? "150px" : "125px",
            textAlign: "right",
            backgroundColor: "#bb4444",
            color: "#fff",
            fontSize: isBig ? "16px" : "14px",
          }}
        >
          #GameEnd
        </th>
        <th
          style={{
            minWidth: isBig ? "150px" : "125px",
            textAlign: "right",
            backgroundColor: "#bb4444",
            color: "#fff",
            fontSize: isBig ? "16px" : "14px",
          }}
        >
          #UniquePlayer
        </th>
        <th
          style={{
            minWidth: isBig ? "150px" : "125px",
            textAlign: "right",
            backgroundColor: "#bb4444",
            color: "#fff",
            fontSize: isBig ? "16px" : "14px",
          }}
        >
          #UniqueEnd
        </th>
      </tr>
      {displayItem}
    </table>
  );
};

export default class GameExp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gameExp: {},
      favGame: {},
    };
    var _this = this;
    fetch("/api/game-exp", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var formattedData = {};
        var gameSeperatedGameExp = {};
        data["gameExp"]["content"].forEach((element) => {
          var gameName = element["_id"]["gamename"];
          var week = element["_id"]["weeks"]["weeks"];
          if (formattedData[gameName] === undefined) {
            formattedData[gameName] = { startCount: 0, endCount: 0, startUnique: 0, endUnique: 0 };
          }
          if (gameSeperatedGameExp[gameName] === undefined) {
            gameSeperatedGameExp[gameName] = {};
          }
          if (gameSeperatedGameExp[gameName][week] === undefined) {
            gameSeperatedGameExp[gameName][week] = {
              startCount: 0,
              endCount: 0,
              startUnique: 0,
              endUnique: 0,
            };
          }
          if (element["_id"]["messagetype"] === "START_GAME") {
            formattedData[gameName]["startCount"] += element["count"];
            gameSeperatedGameExp[gameName][week]["startCount"] = element["count"];
          } else if (element["_id"]["messagetype"] === "END_GAME") {
            formattedData[gameName]["endCount"] += element["count"];
            gameSeperatedGameExp[gameName][week]["endCount"] = element["count"];
          }
        });

        data["gameUniqueExp"]["content"].forEach((element) => {
          var gameName = element["_id"]["gamename"];
          var week = element["_id"]["weeks"]["weeks"];
          if (element["_id"]["messagetype"] === "START_GAME") {
            formattedData[gameName]["startUnique"] += element["count"];
            gameSeperatedGameExp[gameName][week]["startUnique"] = element["count"];
          } else if (element["_id"]["messagetype"] === "END_GAME") {
            formattedData[gameName]["endUnique"] += element["count"];
            gameSeperatedGameExp[gameName][week]["endUnique"] = element["count"];
          }
        });
        _this.setState({
          gameExp: formattedData,
          gameSeperatedGameExp: gameSeperatedGameExp,
        });
      });

    fetch("/api/fav-game-target", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var favGames = {};
        var gameList = [];
        data["femaleFavGame"].content.forEach((element) => {
          var gameName = element["_id"]["gamename"];
          if (favGames[gameName] === undefined) {
            gameList.push(gameName);
            favGames[gameName] = { female: 0, male: 0, girl: 0 };
          }
          favGames[gameName]["female"] = element.count;
        });
        data["girlFavGame"].content.forEach((element) => {
          var gameName = element["_id"]["gamename"];
          if (favGames[gameName] === undefined) {
            gameList.push(gameName);
            favGames[gameName] = { female: 0, male: 0, girl: 0 };
          }
          favGames[gameName]["girl"] = element.count;
        });
        data["maleFavGame"].content.forEach((element) => {
          var gameName = element["_id"]["gamename"];
          if (favGames[gameName] === undefined) {
            gameList.push(gameName);
            favGames[gameName] = { female: 0, male: 0, girl: 0 };
          }
          favGames[gameName]["male"] = element.count;
        });
        var formattedData = [];
        gameList.sort();
        for (var i = 0; i < gameList.length; i++) {
          var gameName = gameList[i];
          formattedData.push({
            ...favGames[gameName],
            gameName: gameName,
          });
        }
        _this.setState({ favGame: formattedData });
      });
  }

  render() {
    var extraTable = [];
    for (var key in this.state.gameSeperatedGameExp) {
      extraTable.push(
        <div style={{ padding: "30px" }}>
          <p style={{ fontSize: "18px" }}>{key}</p>
          <div style={{ height: "15px" }} />
          {renderExperienceTable(this.state.gameSeperatedGameExp[key], "Weeks", false)}
        </div>
      );
    }
    return (
      <center>
        <p style={{ fontSize: "36px" }}>Game Experience</p>
        <br />
        {renderExperienceTable(this.state.gameExp, "GameName", true)}
        <br />
        <BarChart width={1000} height={500} data={this.state.favGame}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="gameName" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="male" fill="#8884d8" />
          <Bar dataKey="female" fill="#82ca9d" />
          <Bar dataKey="girl" fill="#f6d4cf" />
        </BarChart>
        <br />
        <div
          style={{
            flexWrap: "wrap",
            alignItems: "flex-start",
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {extraTable}
        </div>
      </center>
    );
  }
}
