import React, { Component } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import { isAndroid, isIOS } from "react-device-detect";
import ReactPixel from "react-facebook-pixel";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import Cookies from "universal-cookie";
import Navbar from "../../components/Navbar";
import "../../css/Home.css";
import "../../css/sliderStyles.css";
import i18n from "../../i18n";

import { withTranslation } from "react-i18next";
import { logActivity } from "../../helper/LogActivity";
import { changeLang } from "../../helper/RoutingHelper";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const slider = (isMobile) => (
  <AutoplaySlider
    style={{ width: isMobile ? "70vw" : "35vw", height: isMobile ? "78.63vw" : "39.315vw" }}
    play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={2000}
  >
    <div data-src={require("../../img/FitforceHome/minigame1.png")} />
    <div data-src={require("../../img/FitforceHome/minigame2.png")} />
    <div data-src={require("../../img/FitforceHome/minigame3.png")} />
    <div data-src={require("../../img/FitforceHome/minigame4.png")} />
  </AutoplaySlider>
);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10000,
    backgroundColor: "#2c3334",
    borderColor: "#b65f30",
    borderRadius: 0,
    paddingLeft: "6vh",
    paddingRight: "6vh",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "#000b",
  },
};

const cookies = new Cookies();

var campaignID;
var cookieID = "";
var lang = "en";

const advancedMatching = {};
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

class Fitforce extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.outerWidth <= 480,
      isShowModal: false,
    };

    ReactPixel.init("978982935925680", advancedMatching, options);

    var queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    campaignID = urlParams.get("cid");
    lang = urlParams.get("hl");

    if (i18n.language !== lang && lang != null) {
      lang = lang.split("-")[0];
      i18n.changeLanguage(lang);
    } else if (lang === null) {
      var language = window.navigator.userLanguage || window.navigator.language;
      changeLang(language.split("-")[0]);
    }

    cookieID = cookies.get("fitforceCookieID");

    if (cookieID !== undefined) {
      logActivity("LoadPage", campaignID, cookieID);
    } else {
      fetch("/api/gen-cookie", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          cookies.set("fitforceCookieID", data.CookieID, { path: "/" });
          cookieID = cookies.get("fitforceCookieID");
          logActivity("LoadPage", campaignID, cookieID);
        });
    }
  }

  resize = () => {
    this.setState({ isMobile: window.outerWidth <= 480 });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  onPressDownload() {
    if (isIOS) {
      logActivity("ToAppStore", campaignID, cookieID);
      window.location = "https://itunes.apple.com/us/app/fitforce/id1402662894?mt=8";
    } else if (isAndroid) {
      logActivity("ToPlayStore", campaignID, cookieID);
      window.location = "https://play.google.com/store/apps/details?id=com.runster&hl=en";
    } else {
      logActivity("OpenDownloadPopup", campaignID, cookieID);
      this.setState({ isShowModal: true });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{ width: "100%", maxWidth: "100vw", backgroundColor: "#1e2223" }}>
        <Navbar
          onPressDownload={() => this.onPressDownload()}
          campaignID={campaignID}
          lang={lang}
          isGuideToDownload={true}
          isMobile={this.state.isMobile}
        />
        <div
          style={{
            height: "200vh",
            position: "fixed",
            width: 1,
            backgroundColor: "#b65f30",
            zIndex: 2,
            marginLeft: "8vw",
          }}
        />
        <div
          style={{
            height: "200vh",
            position: "fixed",
            width: 1,
            backgroundColor: "#b65f30",
            zIndex: 2,
            marginLeft: `calc(8vw + 7px)`,
          }}
        />
        <div
          style={{
            height: "200vh",
            position: "fixed",
            width: 1,
            backgroundColor: "#b65f30",
            zIndex: 2,
            marginLeft: "92vw",
          }}
        />
        <div
          style={{
            backgroundColor: "#2c3335",
            width: "100%",
            flexDirection: this.state.isMobile ? "column" : "row",
            display: "flex",
            paddingTop: this.state.isMobile ? 60 : "10vh",
          }}
        >
          <div
            style={
              this.state.isMobile
                ? {
                    backgroundImage: `url(${require("../../img/FitforceHome/pcDemo.png")})`,
                    width: "123.5vw",
                    height: "75.0405vw",
                    zIndex: 2,
                    "background-repeat": "no-repeat",
                    "background-size": "contain",
                    paddingLeft: "13.125vw",
                    paddingTop: "2.77vw",
                    marginLeft: "-11.75vw",
                  }
                : {
                    backgroundImage: `url(${require("../../img/FitforceHome/pcDemo.png")})`,
                    width: "65vw",
                    height: "39.495vw",
                    zIndex: 2,
                    "background-repeat": "no-repeat",
                    "background-size": "contain",
                    paddingLeft: "6.9vw",
                    paddingTop: "1.425vw",
                    marginLeft: "5vw",
                    marginTop: 50,
                    marginBottom: "-4vw",
                  }
            }
          >
            <ReactPlayer
              width={this.state.isMobile ? "94.848vw" : "49.92vw"}
              height={this.state.isMobile ? "44.46vw" : "23.4vw"}
              url={require("../../video/FitforcePromo.mp4")}
              playing={true}
              muted={true}
              loop={true}
              stopOnUnmount={true}
            />
          </div>
          <div
            style={
              this.state.isMobile
                ? {
                    width: "40vw",
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "13vw",
                    paddingBottom: "5vw",
                  }
                : { width: "25vw", marginLeft: "-7vw", display: "flex", flexDirection: "column" }
            }
          >
            <div style={{ flex: 1 }} />
            <div style={{ flexDirection: "column", zIndex: 2 }}>
              <img
                style={{ width: this.state.isMobile ? "35vw" : "12vw", height: "auto", zIndex: 3 }}
                alt="Fitforce_Logo"
                src={require("../../img/FitforceHome/Fitforce_Logo.png")}
              />
              <p
                style={
                  this.state.isMobile
                    ? {
                        marginTop: 10,
                        width: "40vw",
                        fontSize: "5.5vw",
                        fontWeight: "bold",
                        color: "#fff",
                        lineHeight: 1.3,
                        textShadow: "5px 8px 5px #111111",
                        zIndex: 3,
                        fontFamily: lang === "th" ? "Kanit" : "Roboto",
                      }
                    : {
                        marginTop: 10,
                        fontSize: "3.5vw",
                        fontWeight: "bold",
                        color: "#fff",
                        lineHeight: 1.2,
                        textShadow: "5px 8px 5px #111111",
                        zIndex: 3,
                        fontFamily: lang === "th" ? "Kanit" : "Roboto",
                      }
                }
              >
                {t("INTERACTIVE FITNESS IN YOUR HAND")}
              </p>
              <p
                style={{
                  fontSize: this.state.isMobile ? "3.5vw" : "1.5vw",
                  color: "#fff",
                  marginTop: 10,
                  zIndex: 3,
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t(
                  "No pricy devices needed. Just connect your smartphone to a PC and enjoy the emersive experience."
                )}
              </p>
              <span style={{ zIndex: 3 }} onClick={() => this.onPressDownload()}>
                <img
                  style={{ width: 350, height: "auto", marginLeft: -10, marginTop: 10 }}
                  alt="Downloadbt"
                  src={require("../../img/FitforceHome/Downloadbt.png")}
                />
              </span>
            </div>
            <img
              style={{
                width: "40vw",
                height: "67.82vw",
                marginTop: "10vw",
                marginLeft: "10vw",
                display: !this.state.isMobile ? "none" : "flex",
                zIndex: 3,
              }}
              alt="fitforcemobile"
              src={require("../../img/FitforceHome/fitforcemobile.png")}
            />
            <div style={{ flex: 1 }} />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <img
            style={
              this.state.isMobile
                ? {
                    float: "right",
                    marginTop: "-170vw",
                    width: "120vw",
                    height: "140vw",
                    zIndex: 1,
                    marginBottom: "-65vw",
                    marginLeft: "25vw",
                  }
                : {
                    float: "right",
                    marginTop: "-110vw",
                    width: "120vw",
                    height: "120vw",
                    zIndex: 1,
                    marginBottom: "-65vw",
                    marginLeft: "25vw",
                  }
            }
            alt="blur1"
            src={require("../../img/FitforceHome/blur1.png")}
          />
        </div>
        <img
          style={{
            width: "13vw",
            position: "relative",
            height: "auto",
            marginTop: "-15vw",
            marginLeft: "5vw",
            display: this.state.isMobile ? "none" : "float",
            zIndex: 3,
          }}
          alt="fitforcemobile"
          src={require("../../img/FitforceHome/fitforcemobile.png")}
        />
        <div
          style={{
            backgroundColor: "#1a1f20",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: 5,
            marginTop: this.state.isMobile ? 0 : "-8.5vw",
          }}
        >
          <div style={{ flex: 1 }} />
          <div
            style={{
              minWidth: "5.5vw",
              margin: this.state.isMobile ? "3vw" : 30,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ flex: 1 }} />
              <img
                style={{ width: "6vw", minWidth: 50, height: "6vw", minHeight: 50 }}
                alt="mobileIcon"
                src={require("../../img/FitforceHome/mobileIcon.png")}
              />
              <div style={{ flex: 1 }} />
            </div>
            <p
              style={{
                color: "#fff",
                textAlign: "center",
                fontSize: this.state.isMobile ? "2vw" : "1vw",
                fontWeight: "bold",
                width: this.state.isMobile ? "15vw" : "7.5vw",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t("PHONE AS CONTROLLER")}
            </p>
          </div>
          <div
            style={{
              minWidth: "5.5vw",
              margin: this.state.isMobile ? "3vw" : 30,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ flex: 1 }} />
              <img
                style={{ width: "6vw", minWidth: 50, height: "6vw", minHeight: 50 }}
                alt="exerciseIcon"
                src={require("../../img/FitforceHome/exerciseIcon.png")}
              />
              <div style={{ flex: 1 }} />
            </div>
            <p
              style={{
                color: "#fff",
                textAlign: "center",
                fontSize: this.state.isMobile ? "2vw" : "1vw",
                fontWeight: "bold",
                width: this.state.isMobile ? "15vw" : "7.5vw",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t("EXERCISE TRACKING")}
            </p>
          </div>
          <div
            style={{
              minWidth: "5.5vw",
              margin: this.state.isMobile ? "3vw" : 30,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ flex: 1 }} />
              <img
                style={{ width: "6vw", minWidth: 50, height: "6vw", minHeight: 50 }}
                alt="fireIcon"
                src={require("../../img/FitforceHome/fireIcon.png")}
              />
              <div style={{ flex: 1 }} />
            </div>
            <p
              style={{
                color: "#fff",
                textAlign: "center",
                fontSize: this.state.isMobile ? "2vw" : "1vw",
                fontWeight: "bold",
                width: this.state.isMobile ? "15vw" : "7.5vw",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t("VARIOUS MODE")}
            </p>
          </div>
          <div
            style={{
              minWidth: "5.5vw",
              margin: this.state.isMobile ? "3vw" : 30,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ flex: 1 }} />
              <img
                style={{ width: "6vw", minWidth: 50, height: "6vw", minHeight: 50 }}
                alt="controlIcon"
                src={require("../../img/FitforceHome/controlIcon.png")}
              />
              <div style={{ flex: 1 }} />
            </div>
            <p
              style={{
                color: "#fff",
                textAlign: "center",
                fontSize: this.state.isMobile ? "2vw" : "1vw",
                fontWeight: "bold",
                width: this.state.isMobile ? "15vw" : "7.5vw",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t("LOTS OF MINI-GAMES")}
            </p>
          </div>
          <div style={{ flex: 1 }} />
        </div>
        <div
          style={{
            backgroundColor: "#2c3335",
            flexDirection: "column",
            display: "flex",
            padding: "8vw",
            paddingLeft: `calc(8vw + 7px)`,
            zIndex: 3,
          }}
        >
          <p
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: this.state.isMobile ? "5.25vw" : "2.5vw",
              marginLeft: "3vw",
              zIndex: 3,
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("PHONE AS A CONTROLLER")}
          </p>
          <div
            style={{
              width: this.state.isMobile ? "75vw" : "37vw",
              backgroundColor: "#b65f30",
              height: 1,
              flexDirection: "row",
              display: "flex",
              zIndex: 3,
            }}
          >
            <div style={{ flex: 1 }} />
            <div
              style={{ width: 10, height: 10, borderRadius: 10, backgroundColor: "#b65f30", marginTop: -5 }}
            />
          </div>
          <p
            style={
              this.state.isMobile
                ? {
                    color: "#fff",
                    marginLeft: "3vw",
                    fontSize: this.state.isMobile ? "3.5vw" : "3vw",
                    width: "70vw",
                    zIndex: 3,
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }
                : {
                    color: "#fff",
                    marginLeft: "3vw",
                    fontSize: "1.5vw",
                    width: "33vw",
                    zIndex: 3,
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }
            }
          >
            {t("Detect your motion from smartphone connected to PC to make emersive exercise experience.")}
          </p>
          <img
            style={
              this.state.isMobile
                ? { alignSelf: "center", width: "40vw", height: "auto", margin: "7vw", zIndex: 3 }
                : { alignSelf: "flex-end", width: "18vw", height: "auto", marginTop: "-5vw", zIndex: 3 }
            }
            alt="phone_kcal_H"
            src={require("../../img/FitforceHome/phone_kcal_H.png")}
          />
          <p
            style={
              this.state.isMobile
                ? {
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "5.25vw",
                    marginRight: "3vw",
                    textAlign: "right",
                    zIndex: 3,
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }
                : {
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "2.5vw",
                    marginRight: "28vw",
                    textAlign: "right",
                    marginTop: "-15vw",
                    zIndex: 3,
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }
            }
          >
            {t("EXERCISE TRACKING")}
          </p>
          <div
            style={{
              width: this.state.isMobile ? "60vw" : "55vw",
              backgroundColor: "#b65f30",
              height: 1,
              flexDirection: "row",
              display: "flex",
              alignSelf: "flex-end",
              zIndex: 2,
            }}
          >
            <div
              style={{
                width: 10,
                height: 10,
                borderRadius: 10,
                backgroundColor: "#b65f30",
                marginTop: -5,
                zIndex: 3,
              }}
            />
          </div>
          <p
            style={
              this.state.isMobile
                ? {
                    color: "#fff",
                    marginRight: "3vw",
                    fontSize: this.state.isMobile ? "3.5vw" : "3vw",
                    width: "50vw",
                    alignSelf: "flex-end",
                    textAlign: "right",
                    zIndex: 3,
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }
                : {
                    color: "#fff",
                    fontSize: "1.5vw",
                    width: "25vw",
                    alignSelf: "flex-end",
                    textAlign: "right",
                    marginRight: "28vw",
                    zIndex: 3,
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }
            }
          >
            {t("Show your daily exercise result and track your progress in health log")}
          </p>
          <div style={{ display: "flex" }}>
            <img
              style={
                this.state.isMobile
                  ? {
                      float: "left",
                      marginTop: "-130vw",
                      width: "160vw",
                      height: "160vw",
                      zIndex: 1,
                      marginBottom: "-65vw",
                      marginLeft: "-25vw",
                    }
                  : {
                      float: "left",
                      marginTop: "-45vw",
                      width: "90vw",
                      height: "80vw",
                      zIndex: 1,
                      marginBottom: "-65vw",
                      marginLeft: "-35vw",
                    }
              }
              alt="blur2"
              src={require("../../img/FitforceHome/blur2.png")}
            />
          </div>
          <p
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: this.state.isMobile ? "5.25vw" : "2.5vw",
              marginLeft: "3vw",
              marginTop: "15vw",
              zIndex: 3,
              fontFamily: lang === "th" ? "Kanit" : "Roboto",
            }}
          >
            {t("VARIOUS MODE")}
          </p>
          <div
            style={{
              width: this.state.isMobile ? "50vw" : "25vw",
              backgroundColor: "#b65f30",
              height: 1,
              flexDirection: "row",
              display: "flex",
              zIndex: 3,
            }}
          >
            <div style={{ flex: 1 }} />
            <div
              style={{
                width: 10,
                height: 10,
                borderRadius: 10,
                backgroundColor: "#b65f30",
                marginTop: -5,
                zIndex: 3,
              }}
            />
          </div>
          <div
            style={{
              flexDirection: this.state.isMobile ? "column" : "row",
              display: "flex",
              alignItems: this.state.isMobile ? "center" : "flex-end",
            }}
          >
            <div style={{ flex: 1 }} />
            <div
              style={
                this.state.isMobile
                  ? {
                      flexDirection: "column",
                      display: "flex",
                      marginTop: "10vw",
                      width: "50vw",
                      alignItems: "center",
                    }
                  : {
                      flexDirection: "column",
                      display: "flex",
                      marginTop: "5vw",
                      width: "25vw",
                      alignItems: "center",
                    }
              }
            >
              <img
                style={{ width: this.state.isMobile ? "50vw" : "20vw", height: "auto", zIndex: 3 }}
                alt="mode1"
                src={require("../../img/FitforceHome/mode1.png")}
              />
              <p
                style={{
                  fontSize: this.state.isMobile ? "6vw" : "2vw",
                  color: "#f0722e",
                  textAlign: "center",
                  fontWeight: "bold",
                  zIndex: 3,
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("CLASSIC MODE")}
              </p>
              <p
                style={{
                  color: "#fff",
                  fontSize: this.state.isMobile ? "3.5vw" : "1.25vw",
                  textAlign: "center",
                  marginLeft: "2.5vw",
                  marginRight: "2.5vw",
                  zIndex: 3,
                  width: this.state.isMobile ? "48vw" : "16vw",
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("Use real exercise posture to control a various mini-games.")}
              </p>
            </div>
            <div
              style={
                this.state.isMobile
                  ? {
                      flexDirection: "column",
                      display: "flex",
                      marginTop: "10vw",
                      width: "50vw",
                      alignItems: "center",
                    }
                  : {
                      flexDirection: "column",
                      display: "flex",
                      marginTop: "5vw",
                      width: "25vw",
                      alignItems: "center",
                    }
              }
            >
              <img
                style={{ width: this.state.isMobile ? "50vw" : "20vw", height: "auto", zIndex: 3 }}
                alt="mode2"
                src={require("../../img/FitforceHome/mode2.png")}
              />
              <p
                style={{
                  fontSize: this.state.isMobile ? "6vw" : "2vw",
                  color: "#f0722e",
                  textAlign: "center",
                  fontWeight: "bold",
                  zIndex: 3,
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("PARTY MODE")}
              </p>
              <p
                style={{
                  color: "#fff",
                  fontSize: this.state.isMobile ? "3.5vw" : "1.25vw",
                  textAlign: "center",
                  zIndex: 3,
                  width: this.state.isMobile ? "48vw" : "16vw",
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("It's better to have an exercise partner, Party mode is the answer.")}
              </p>
            </div>
            <div
              style={
                this.state.isMobile
                  ? {
                      flexDirection: "column",
                      display: "flex",
                      marginTop: "10vw",
                      width: "50vw",
                      alignItems: "center",
                    }
                  : {
                      flexDirection: "column",
                      display: "flex",
                      marginTop: "5vw",
                      width: "25vw",
                      alignItems: "center",
                    }
              }
            >
              <img
                style={{ width: this.state.isMobile ? "50vw" : "20vw", height: "auto", zIndex: 3 }}
                alt="mode3"
                src={require("../../img/FitforceHome/mode3.png")}
              />
              <p
                style={{
                  fontSize: this.state.isMobile ? "6vw" : "2vw",
                  color: "#f0722e",
                  textAlign: "center",
                  fontWeight: "bold",
                  zIndex: 3,
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("PROGRAM MODE")}
              </p>
              <p
                style={{
                  color: "#fff",
                  fontSize: this.state.isMobile ? "3.5vw" : "1.25vw",
                  textAlign: "center",
                  zIndex: 3,
                  width: this.state.isMobile ? "48vw" : "16vw",
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("Design your own exercise set to met your fitness goal.")}
              </p>
            </div>
            <div style={{ flex: 1 }} />
          </div>
          <div style={{ display: "flex" }}>
            <img
              style={
                this.state.isMobile
                  ? { marginTop: "-35vw", width: "100vw", height: "70vw", zIndex: 0, marginLeft: "0" }
                  : { marginTop: "-35vw", width: "120vw", height: "80vw", zIndex: 0, marginLeft: "0" }
              }
              alt="blur3"
              src={require("../../img/FitforceHome/blur3.png")}
            />
          </div>
        </div>
        {this.state.isMobile && (
          <div>
            <div
              style={{
                "background-repeat": "no-repeat",
                backgroundImage: `url(${require("../../img/FitforceHome/bg_V2_completelayer.png")})`,
                zIndex: 2,
                "background-size": "cover",
                width: "102%",
                flexDirection: "column",
                display: "flex",
                marginTop: "-40vw",
                marginLeft: "-1vw",
              }}
            >
              <img
                style={{ zIndex: 3, width: "100%", height: "auto", marginTop: "-15vw" }}
                alt="BossImage"
                src={require("../../img/FitforceHome/BossImage.png")}
              />
              <p
                style={{
                  fontSize: "6vw",
                  color: "#f0722e",
                  textAlign: "center",
                  fontWeight: "bold",
                  zIndex: 3,
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("BOSS FIGHT MODE")}
              </p>
              <center>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "3.5vw",
                    textAlign: "center",
                    zIndex: 3,
                    width: this.state.isMobile ? "48vw" : "16vw",
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }}
                >
                  {t("Conquer the exercise enemies and get your rewards.")}
                </p>
              </center>
            </div>
            <div
              style={{
                backgroundColor: "#2c3335",
                padding: "5vw",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              {slider(this.state.isMobile)}
              <img
                style={{ width: "120vw", height: "auto", marginLeft: "40vw", marginTop: "-10vw" }}
                alt="blur6"
                src={require("../../img/FitforceHome/blur6.png")}
              />
              <p
                style={{
                  fontSize: "7vw",
                  color: "#fff",
                  width: "40vw",
                  fontWeight: "bold",
                  lineHeight: 1.25,
                  marginTop: "-59vw",
                  fontFamily: lang === "th" ? "Kanit" : "Roboto",
                }}
              >
                {t("LOT OF MINIGAMES AWAIT!")}
              </p>
              <div
                style={{
                  width: "67vw",
                  backgroundColor: "#b65f30",
                  height: 1,
                  flexDirection: "row",
                  display: "flex",
                  alignSelf: "flex-end",
                  zIndex: 2,
                  marginRight: "3vw",
                }}
              >
                <div
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10,
                    backgroundColor: "#b65f30",
                    marginTop: -5,
                    zIndex: 3,
                  }}
                />
              </div>
              <span onClick={() => this.onPressDownload()}>
                <img
                  style={{ width: "60vw", height: "17vw", margin: "2vw" }}
                  alt="Downloadbt"
                  src={require("../../img/FitforceHome/Downloadbt.png")}
                />
              </span>
            </div>
          </div>
        )}
        {!this.state.isMobile && (
          <div
            style={{
              "background-repeat": "no-repeat",
              backgroundImage: `url(${require("../../img/FitforceHome/bg_V2_completelayer.png")})`,
              zIndex: 2,
              "background-size": "cover",
              width: "102%",
              flexDirection: "column",
              display: "flex",
              marginTop: "-50vw",
              marginLeft: "-1vw",
              padding: "1vw",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ "margin-left": "10vw", marginTop: "5vw" }}>
                <p
                  style={{
                    fontSize: "2vw",
                    color: "#f0722e",
                    textAlign: "left",
                    fontWeight: "bold",
                    marginLeft: "3vw",
                    zIndex: 3,
                    width: "35vw",
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }}
                >
                  {t("BOSS FIGHT MODE")}
                </p>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "1.25vw",
                    textAlign: "left",
                    marginLeft: "3vw",
                    zIndex: 3,
                    width: "18vw",
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }}
                >
                  {t("Conquer the exercise enemies and get your rewards.")}
                </p>
                <img
                  style={{ width: "35vw", height: "auto" }}
                  alt="manbossp"
                  src={require("../../img/FitforceHome/manbossp.png")}
                />
              </div>
              <img
                style={{ zIndex: 3, width: "70vw", height: "56.44vw", "margin-left": "-5vw" }}
                alt="BossImage"
                src={require("../../img/FitforceHome/BossImage.png")}
              />
            </div>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#222",
                  padding: "1.5vw",
                  marginLeft: "12vw",
                  marginTop: "-8vw",
                  paddingBottom: "5vw",
                  zIndex: 3,
                }}
              >
                {slider(this.state.isMobile)}
              </div>
              <div style={{ flex: 1 }}>
                <img
                  style={{ width: "120vw", height: "auto", zIndex: -1, marginLeft: "5vw" }}
                  alt="blur6"
                  src={require("../../img/FitforceHome/blur6.png")}
                />
                <p
                  style={{
                    fontSize: "4vw",
                    marginTop: "-45vw",
                    marginLeft: "3vw",
                    color: "#fff",
                    fontWeight: "bold",
                    lineHeight: 1.3,
                    zIndex: 2,
                    width: "24.5vw",
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }}
                >
                  {t("LOT OF MINIGAMES AWAIT!")}
                </p>
                <div
                  style={{
                    width: "70vw",
                    height: 1,
                    backgroundColor: "#b65f30",
                    marginLeft: `calc(-42vw + 7px)`,
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      borderRadius: 10,
                      backgroundColor: "#b65f30",
                      marginTop: -5,
                    }}
                  />
                </div>
                <span style={{ zIndex: 2 }} onClick={() => this.onPressDownload()}>
                  <img
                    style={{ width: "30vw", height: "8.5vw", margin: "2vw", zIndex: 2 }}
                    alt="Downloadbt"
                    src={require("../../img/FitforceHome/Downloadbt.png")}
                  />
                </span>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            flexDirection: this.state.isMobile ? "column" : "row",
            display: "flex",
            backgroundColor: "#1e2223",
            padding: "5vw",
            paddingLeft: "15vw",
            paddingRight: "15vw",
            zIndex: 3,
          }}
        >
          <div style={{ flex: 1 }}>
            <p
              style={{
                fontSize: this.state.isMobile ? "4.5vw" : "1.5vw",
                color: "#fff",
                fontWeight: "bold",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t("ABOUT US")}
            </p>
            <p
              style={{
                fontSize: this.state.isMobile ? "4vw" : "1.25vw",
                marginTop: 10,
                color: "#fff",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t("Runnex Technology co., ltd.")}
            </p>
            <p
              style={{
                fontSize: this.state.isMobile ? "3vw" : "1vw",
                marginTop: 7,
                color: "#fff",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t(
                "We are a small group of passionate people who aim to bring the fitness balance back to this world, with our technology and design, everything possible"
              )}
            </p>
          </div>
          <div
            style={
              this.state.isMobile
                ? { width: "50vw", height: 1, backgroundColor: "#fff", marginTop: 20, marginBottom: 20 }
                : {
                    width: 1,
                    height: "15vw",
                    backgroundColor: "#fff",
                    margin: "1vw",
                    marginLeft: "5vw",
                    marginRight: "5vw",
                  }
            }
          />
          <div style={{ flex: 1 }}>
            <p
              style={{
                fontSize: this.state.isMobile ? "4.5vw" : "1.5vw",
                color: "#fff",
                fontWeight: "bold",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              {t("CONTACT US")}
            </p>
            <p
              style={{
                fontSize: this.state.isMobile ? "4vw" : "1.25vw",
                marginTop: 10,
                color: "#fff",
                fontFamily: lang === "th" ? "Kanit" : "Roboto",
              }}
            >
              RunnexTechnology@gmail.com
            </p>
            <div style={{ marginTop: 20 }}>
              <a href="https://www.facebook.com/fitforce.en" target="_blank" rel="noreferrer">
                <img
                  style={{
                    width: this.state.isMobile ? "6vw" : "2vw",
                    height: this.state.isMobile ? "6vw" : "2vw",
                  }}
                  alt="fb_icon"
                  src={require("../../img/FitforceHome/fb_icon.png")}
                />
              </a>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.isShowModal}
          onRequestClose={() => this.setState({ isShowModal: false })}
          style={customStyles}
        >
          <div style={{ display: "flex", flexDirection: "column", width: "80vh" }}>
            <div style={{ flexDirection: "row", display: "flex", marginRight: "-5vh", marginTop: "-1vh" }}>
              <div style={{ flex: 1 }} />
              <img
                onClick={() => this.setState({ isShowModal: false })}
                alt="X"
                src={require("../../img/FitforceDownload/popup/X.png")}
                style={{ height: "4vh", width: "4vh" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 3 }}>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      color: "#b65f30",
                      fontWeight: "bold",
                      fontSize: "2vh",
                      fontFamily: lang === "th" ? "Kanit" : "Roboto",
                    }}
                  >
                    {t("STEP")} 1
                  </p>
                </div>
                <p
                  style={{
                    color: "#b65f30",
                    fontWeight: "bold",
                    fontSize: "3.5vh",
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }}
                >
                  {t("install on your pc")}
                </p>
                <div style={{ height: "3vh" }} />
                <p style={{ color: "#fff", fontFamily: lang === "th" ? "Kanit" : "Roboto" }}>
                  {t("play on")}
                </p>
                <div style={{ height: "1vh" }} />
                <a
                  onClick={() => logActivity("ToSteam", campaignID, cookieID)}
                  href="https://store.steampowered.com/app/1081670/Fitforce/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "24vh", height: "8vh" }}
                    alt="Steam"
                    src={require("../../img/FitforceDownload/popup/Steam_1xsize.png")}
                  />
                </a>
                <p style={{ color: "#fff", fontFamily: lang === "th" ? "Kanit" : "Roboto" }}>{t("or")}</p>
                <div style={{ height: "1vh" }} />
                <a
                  onClick={() => logActivity("DirectDownload", campaignID, cookieID)}
                  href="http://fitforce.fun:8888/hfs/fitforcesetup.exe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "24vh", height: "8vh" }}
                    alt="DirectDownload"
                    src={require("../../img/FitforceDownload/popup/Download_1xsize.png")}
                  />
                </a>
              </div>
              <div
                style={{
                  width: 1,
                  height: "40vh",
                  backgroundColor: "#b65f30",
                  marinLeft: "4vh",
                  marginRight: "4vh",
                }}
              />
              <div style={{ flex: 2 }}>
                <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      color: "#b65f30",
                      fontWeight: "bold",
                      fontSize: "2vh",
                      fontFamily: lang === "th" ? "Kanit" : "Roboto",
                    }}
                  >
                    {t("STEP")} 2
                  </p>
                </div>
                <p
                  style={{
                    color: "#b65f30",
                    fontSize: "3.5vh",
                    fontWeight: "bold",
                    lineHeight: 1.2,
                    fontFamily: lang === "th" ? "Kanit" : "Roboto",
                  }}
                >
                  {t("install on your mobile")}
                </p>
                <div style={{ height: "2vh" }} />
                <img
                  alt="getFitforce"
                  src={require("../../img/FitforceDownload/popup/getFitforce.png")}
                  style={{ height: "18vh", width: "18vh" }}
                />
                <div style={{ height: "1vh" }} />
                <p style={{ color: "#fff", fontFamily: lang === "th" ? "Kanit" : "Roboto", width: "30vh" }}>
                  {t("Scan QR code and download it on store")}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(Fitforce);
