import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { adminApi } from "../api";
import Collapsible from "./Collapsible";
import PromoCodeForm from "./PromoCodeForm";

const PromoCodeManager = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    adminApi.getPromoCodes().then((r) => {
      setData(r.data?.data || []);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const seriesNames = useMemo(() => [...new Set(data.map((x) => x.seriesName))] || [], [data]);

  const columns = [
    { name: "Code", selector: (row) => row.code },
    { name: "CodeType", selector: (row) => row.codeType },
    { name: "Reward", selector: (row) => row.rewardType },
    { name: "Used / Limit", selector: (row) => `${row.usedCount}/${row.limitCount}` },
  ];

  return (
    <>
      {seriesNames.map((name, index) => {
        const seriesData = data.filter((x) => x.seriesName === name);
        return (
          <Collapsible key={index} title={name}>
            <DataTable columns={columns} data={seriesData} disabled={loading} />
          </Collapsible>
        );
      })}
      {loading && <div>Loading...</div>}
      <hr className="mx-12 my-8" />
      <PromoCodeForm refetch={fetchData} />
    </>
  );
};

export default PromoCodeManager;
