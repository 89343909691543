import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ChartDonutDefault = ({ data, title }) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [total, setTotal] = useState(0);

  const chartOptions = {
    chart: {
      animations: {
        speed: 400,
        animateGradually: {
          enabled: false,
        },
      },
      fontFamily: "inherit",
      foreColor: "inherit",
      height: "100%",
      type: "donut",
      sparkline: {
        enabled: true,
      },
    },
    colors: [
      "#4DC6BC",
      "#BA68C8",
      "#8D6E63",
      "#F9A825",
      "#F06292",
      "#4DD0E1",
      "#EED77D",
      "#64B5F6",
      "#A1887F",
      "#81C784",
    ],
    labels,
    plotOptions: {
      pie: {
        customScale: 0.9,
        expandOnClick: false,
        donut: {
          size: "70%",
        },
      },
    },
    series,
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
      active: {
        filter: {
          type: "none",
        },
      },
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      custom: ({ seriesIndex, w }) =>
        `<div class="flex items-center h-8 min-h-8 text-xs px-3">
            <div class="w-4 h-4 rounded-full" style="background-color: ${
              w.config.colors[seriesIndex]
            };"></div>
            <div class="ml-2 leading-none">${w.config.labels[seriesIndex]}:</div>
            <div class="ml-2 font-bold leading-none">
              ${Math.floor(w.config.series[seriesIndex])}%
            </div>
        </div>`,
    },
  };

  useEffect(() => {
    if (data) {
      const newTotal = data.reduce((acc, curr) => acc + curr.count, 0);
      const sortedData = data.sort((a, b) => (b?.count > a?.count ? 1 : -1));
      setTotal(newTotal);
      setLabels(sortedData.map((x) => x._id));
      setSeries(sortedData.map((x) => (x.count / newTotal) * 100));
    }
  }, [data]);

  return (
    <div className="flex flex-col flex-auto rounded-2xl overflow-hidden p-4 bg-gray-100 shadow-lg">
      <div className="flex flex-col sm:flex-row items-start justify-between">
        <p className="text-lg font-medium tracking-tight leading-6 truncate">{title}</p>
      </div>

      <div className="flex flex-col flex-auto mt-3 h-40">
        <ReactApexChart
          className="flex flex-auto items-center justify-center w-full h-full"
          options={chartOptions}
          series={series}
          type={chartOptions.chart.type}
          height={chartOptions.chart.height}
        />
      </div>
      <div className="h-36 flex flex-col justify-center">
        {series.slice(0, 7).map((dataset, i) => (
          <div className="grid grid-cols-4 text-sm" key={i}>
            <div className="flex items-center -ml-1 col-span-2">
              <div className="w-2 h-2 rounded-full" style={{ backgroundColor: chartOptions.colors[i] }} />
              <p className="flex-1 truncate">{labels[i]}</p>
            </div>
            <p className="font-medium text-right">{((total * dataset) / 100).toLocaleString("en-US")}</p>
            <p className="text-right text-gray-600">{Math.floor(dataset)}%</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartDonutDefault;
