import React, { Component } from "react";
import "../../css/Home.css";

class Runster extends Component {
  render() {
    var isMobile = window.innerWidth <= 480;
    if (isMobile) {
      return (
        <div className="bg">
          <div
            style={{
              height: "5vh",
              width: "100%",
              position: "fixed",
              top: 0,
              backgroundColor: "#000000aa",
              display: "flex",
              flexDirection: "row",
              paddingLeft: "5vw",
              paddingRight: "5vw",
            }}
          >
            <img
              alt="textlogo"
              src={require("../../img/textlogo.png")}
              style={{ height: "5vh", width: "auto" }}
            />
            <div style={{ flex: 1 }} />
            <a href="/runster" style={{ paddingTop: "1vh" }}>
              <h style={{ color: "#fff", fontSize: "2.5vh" }}>Home</h>
            </a>
            <div style={{ width: "5vw" }} />
            <a href="/runster/about-us" style={{ paddingTop: "1vh" }}>
              <h style={{ color: "#fff", fontSize: "2.5vh" }}>About us</h>
            </a>
          </div>
          <div style={{ height: "5vh" }} />
          <center>
            <img
              style={{ width: "80vw", height: "auto" }}
              alt="device_mock"
              src={require("../../img/device_mock-up.png")}
            />
            <h style={{ color: "#eee", fontSize: "7.5vw", lineHeight: "100%" }}>
              <span style={{ display: "block" }}>Work out at home with an</span>
              <span style={{ display: "block" }}>interactive online game</span>
            </h>
            <div style={{ height: "2.5vh" }} />
            <p style={{ color: "#fff", fontSize: "3.5vw", width: "70vw" }}>
              Get a whole new workout experience! Just use your PC & Smartphone.
            </p>
            <a href="https://store.steampowered.com/app/1081670/Runster/" target="_blank" rel="noreferrer">
              <img
                style={{ height: "auto", width: "50vw" }}
                alt="tryDemo"
                src={require("../../img/tryDemo.png")}
              />
            </a>
            <video width="100%" height="auto" autoPlay controls>
              <source src={require("../../video/Runster_Demo_Ver1.mp4")} type="video/mp4" />
            </video>
            <h style={{ color: "#eee", fontSize: "8vw", lineHeight: "100%" }}>new way to exercise</h>
            <p style={{ color: "#fff", fontSize: "3.5vw", width: "70vw" }}>
              Grab a phone and control your character by moving your whole body.
            </p>
            <div style={{ height: "3vh" }} />
            <div
              style={{
                backgroundColor: "#00000026",
                borderColor: "#707070",
                borderWidth: 1,
                flexDirection: "column",
              }}
            >
              <div style={{ height: "3vh" }} />
              <h style={{ color: "#fff", fontSize: "6vw" }}>3 main features</h>
              <div style={{ height: "2vh" }} />
              <div
                style={{
                  flexDirection: "row",
                  display: "inline-flex",
                  alignContent: "center",
                  alignSelf: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    alt="featureIcon1"
                    src={require("../../img/featureIcon1.png")}
                    style={{ width: "20vw", height: "20vw" }}
                  />
                  <p
                    style={{
                      width: "30vw",
                      color: "#fff",
                      paddingLeft: "2.5vw",
                      paddingRight: "2.5vw",
                      fontSize: "4vw",
                    }}
                  >
                    Control with your body
                  </p>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    alt="featureIcon2"
                    src={require("../../img/featureIcon2.png")}
                    style={{ width: "20vw", height: "20vw" }}
                  />
                  <p
                    style={{
                      width: "30vw",
                      color: "#fff",
                      paddingLeft: "2.5vw",
                      paddingRight: "2.5vw",
                      fontSize: "4vw",
                    }}
                  >
                    Health Tracker
                  </p>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    alt="featureIcon3"
                    src={require("../../img/featureIcon3.png")}
                    style={{ width: "20vw", height: "20vw" }}
                  />
                  <p
                    style={{
                      width: "30vw",
                      color: "#fff",
                      paddingLeft: "2.5vw",
                      paddingRight: "2.5vw",
                      fontSize: "4vw",
                    }}
                  >
                    Online with others
                  </p>
                </div>
              </div>
              <div style={{ height: "5vh" }} />
            </div>
            <div style={{ height: "4vh" }} />
            <div style={{ width: "5vw", height: "1.5vw", backgroundColor: "#fff", borderRadius: 30 }} />
            <div style={{ height: "4vh" }} />
            <h style={{ color: "#a3fcfc", fontSize: "7vw" }}>control with your body</h>
            <div style={{ height: "2vh" }} />
            <video
              src={require("../../video/control-with-your-body.mp4")}
              type="video/mp4"
              width="100%"
              height="auto"
              loop
              autoPlay
              muted
            ></video>
            <div style={{ height: "2vh" }} />
            <p style={{ color: "#fff", fontSize: "3.5vw", width: "60vw", textAlign: "left" }}>
              You control the game with your movements, using your smartphone as a controller!
            </p>
            <div style={{ height: "2vh" }} />
            <div style={{ alignSelf: "center", width: "fit-content" }}>
              <h
                style={{
                  color: "#fff",
                  fontSize: "4vw",
                  textAlign: "left",
                  alignSelf: "left",
                  width: "match-parent",
                }}
              >
                For smartphone (controller)
              </h>
              <br />
              <div
                style={{
                  flexDirection: "row",
                  display: "inline-flex",
                  alignContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <a href="https://apps.apple.com/us/app/runster/id1402662894" target="_blank" rel="noreferrer">
                  <img
                    style={{ width: "auto", height: "5vh" }}
                    alt="appStore"
                    src={require("../../img/appStore.png")}
                  />
                </a>
                <div style={{ width: "2vw" }} />
                <a
                  href="https://play.google.com/store/apps/details?id=com.runster&hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "auto", height: "5vh" }}
                    alt="googlePlay"
                    src={require("../../img/googlePlay.png")}
                  />
                </a>
              </div>
            </div>
            <div style={{ height: "4vh" }} />
            <div style={{ width: "5vw", height: "1.5vw", backgroundColor: "#fff", borderRadius: 30 }} />
            <div style={{ height: "4vh" }} />
            <h style={{ color: "#a3fcfc", fontSize: "7vw" }}>health tracker</h>
            <img
              alt="health"
              src={require("../../img/health-tracker.png")}
              style={{ width: "100%", height: "auto" }}
            />
            <div style={{ width: "65vw" }}>
              <p style={{ color: "#fff", fontSize: "3.5vw", textAlign: "left" }}>
                Show your health-related results and progress by using the built-in health tracker.
              </p>
              <p style={{ fontSize: "3.5vw", color: "#fff", textAlign: "left" }}>
                <img
                  alt="shoe"
                  src={require("../../img/shoe.png")}
                  style={{ width: "3.5vw", height: "3.5vw", marginRight: "2vw", marginTop: "1vw" }}
                />
                Steps counted while running
              </p>
              <p style={{ fontSize: "3.5vw", color: "#fff", textAlign: "left" }}>
                <img
                  alt="fire"
                  src={require("../../img/fire.png")}
                  style={{ width: "3.5vw", height: "3.5vw", marginRight: "2vw", marginTop: "1vw" }}
                />
                Estimated calories burned
              </p>
              <p style={{ fontSize: "3.5vw", color: "#fff", textAlign: "left" }}>
                <img
                  alt="clock"
                  src={require("../../img/clock.png")}
                  style={{ width: "3.5vw", height: "3.5vw", marginRight: "2vw", marginTop: "1vw" }}
                />
                Running distance
              </p>
            </div>
            <div style={{ height: "4vh" }} />
            <div style={{ width: "5vw", height: "2vw", backgroundColor: "#fff", borderRadius: 30 }} />
            <div style={{ height: "4vh" }} />
            <h style={{ color: "#a3fcfc", fontSize: "7vw" }}>Online with others</h>
            <p style={{ color: "#fff", fontSize: "3.5vw", width: "70vw" }}>
              Everyone can run together in 4v4 online battle mode.
            </p>
            <img
              alt="4v4"
              src={require("../../img/4v4.png")}
              style={{ width: "100%", height: "auto", margin: 0 }}
            />
            <div style={{ backgroundColor: "#041116" }}>
              <div style={{ position: "relative" }}>
                <img
                  alt="end"
                  src={require("../../img/end-character.png")}
                  style={{ marginTop: "-7px", width: "100%", height: "auto", display: "float" }}
                />
                <center
                  style={{
                    position: "absolute",
                    top: "8px",
                    width: "100%",
                    paddingLeft: "20vw",
                    paddingRight: "20vw",
                  }}
                >
                  <h style={{ color: "#fff", fontSize: "5vw", zIndex: 10, top: "10px" }}>
                    Free demo is available on{" "}
                    <img
                      alt="Steam_logo"
                      src={require("../../img/Steam_logo.png")}
                      style={{ height: "8vw", width: "auto" }}
                    />
                  </h>
                  <a
                    href="https://store.steampowered.com/app/1081670/Runster/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ height: "auto", width: "50vw" }}
                      alt="tryDemo"
                      src={require("../../img/tryDemo.png")}
                    />
                  </a>
                </center>
              </div>
              <h style={{ color: "#fff", fontSize: "4vw" }}>For smartphone (controller)</h>
              <br />
              <div
                style={{
                  flexDirection: "row",
                  display: "inline-flex",
                  alignContent: "center",
                  alignItems: "center",
                  alignSelf: "center",
                }}
              >
                <a href="https://apps.apple.com/us/app/runster/id1402662894" target="_blank" rel="noreferrer">
                  <img
                    style={{ width: "auto", height: "4vh" }}
                    alt="appStore"
                    src={require("../../img/appStore.png")}
                  />
                </a>
                <div style={{ width: "2vw" }} />
                <a
                  href="https://play.google.com/store/apps/details?id=com.runster&hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ width: "auto", height: "4vh" }}
                    alt="googlePlay"
                    src={require("../../img/googlePlay.png")}
                  />
                </a>
              </div>
              <br />
              <div style={{ height: "5vh" }} />
              <h style={{ color: "#fff", fontSize: "6vw" }}>contact us</h>
              <p style={{ color: "#fff", fontSize: "3.5vw" }}>RunsterOnline@gmail.com</p>
              <div style={{ height: "3vh" }} />
              <a href="https://www.facebook.com/RunsterOnline/" target="_blank" rel="noreferrer">
                <img
                  style={{ width: "10vw", height: "10vw" }}
                  alt="fbIcon"
                  src={require("../../img/fbIcon.png")}
                />
              </a>
              <br />
              <div style={{ height: "5vh" }} />
            </div>
          </center>
        </div>
      );
    } else {
      return (
        <div className="bg">
          <div
            style={{
              height: "8vh",
              zIndex: 10,
              width: "100%",
              position: "fixed",
              top: 0,
              backgroundColor: "#000000aa",
              display: "flex",
              flexDirection: "row",
              paddingLeft: "5vw",
              paddingRight: "5vw",
            }}
          >
            <img
              alt="textlogo"
              src={require("../../img/textlogo.png")}
              style={{ height: "8vh", width: "auto" }}
            />
            <div style={{ flex: 1 }} />
            <a href="/" style={{ paddingTop: "1.8vh" }}>
              <h style={{ color: "#fff", fontSize: "3.5vh" }}>Home</h>
            </a>
            <div style={{ width: "5vw" }} />
            <a href="/runster/about-us" style={{ paddingTop: "1.8vh" }}>
              <h style={{ color: "#fff", fontSize: "3.5vh" }}>About us</h>
            </a>
          </div>
          <div style={{ height: "7vh" }} />
          <div style={{ flexDirection: "row", display: "flex" }}>
            <img
              style={{ width: "65vw", height: "58.2vw", marginLeft: "-10vw" }}
              alt="device_mock"
              src={require("../../img/device_mock-up.png")}
            />
            <div style={{ flexDirection: "column", marginTop: "10vh", marginLeft: "2vw" }}>
              <h style={{ color: "#eee", fontSize: "5vw", lineHeight: "110%" }}>
                <span style={{ display: "block" }}>Work out at home</span>
                <span style={{ display: "block" }}>with an interactive</span>
                <span style={{ display: "block" }}>online game</span>
              </h>
              <div style={{ height: "2vh" }} />
              <p style={{ color: "#fff", fontSize: "2vw", width: "37vw" }}>
                Get a whole new workout experience! Just use your PC & Smartphone.
              </p>
              <div style={{ height: "2vh" }} />
              <a href="https://store.steampowered.com/app/1081670/Runster/" target="_blank" rel="noreferrer">
                <img
                  style={{ height: "auto", width: "25vw", marginLeft: "-2.5vw" }}
                  alt="tryDemo"
                  src={require("../../img/tryDemo.png")}
                />
              </a>
            </div>
          </div>
          <center>
            <div>
              <video width="80%" height="auto" autoPlay controls>
                <source src={require("../../video/Runster_Demo_Ver1.mp4")} type="video/mp4" />
              </video>
            </div>
            <h style={{ color: "#eee", fontSize: "4vw", lineHeight: "100%" }}>new way to exercise</h>
            <p style={{ color: "#fff", fontSize: "1.6vw", width: "40vw" }}>
              Grab a phone and control your character by moving your whole body.
            </p>
            <br />
            <div style={{ height: "5vh" }} />
            <div
              style={{
                backgroundColor: "#00000026",
                borderColor: "#707070",
                borderWidth: 1,
                flexDirection: "column",
                padding: "5vh",
                paddingTop: "3vh",
              }}
            >
              <h style={{ color: "#fff", fontSize: "4vw" }}>3 main features</h>
              <br />
              <div style={{ height: "2vh" }} />
              <div
                style={{
                  flexDirection: "row",
                  display: "inline-flex",
                  alignContent: "center",
                  alignSelf: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    alt="featureIcon1"
                    src={require("../../img/featureIcon1.png")}
                    style={{ width: "10vw", height: "10vw" }}
                  />
                  <p style={{ width: "20vw", color: "#fff", fontSize: "1.6vw" }}>Control with your body</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    alt="featureIcon2"
                    src={require("../../img/featureIcon2.png")}
                    style={{ width: "10vw", height: "10vw" }}
                  />
                  <p style={{ width: "20vw", color: "#fff", fontSize: "1.6vw" }}>Health tracker</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img
                    alt="featureIcon3"
                    src={require("../../img/featureIcon3.png")}
                    style={{ width: "10vw", height: "10vw" }}
                  />
                  <p style={{ width: "20vw", color: "#fff", fontSize: "1.6vw" }}>Online with others</p>
                </div>
              </div>
            </div>
            <br />
            <div style={{ height: "5vh" }} />
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div
                style={{ flexDirection: "column", padding: "8vh", paddingLeft: "10vw", paddingRight: "7vw" }}
              >
                <h style={{ color: "#a3fcfc", fontSize: "4vw", textAlign: "left", lineHeight: "110%" }}>
                  <span style={{ display: "block" }}>control with</span>
                  <span style={{ display: "block" }}>your body</span>
                </h>
                <div style={{ height: "5vh" }} />
                <p style={{ color: "#fff", fontSize: "1.6vw", textAlign: "left" }}>
                  You control the game with your movements, using your smartphone as a controller!
                </p>
                <br />
                <div style={{ height: "1vh" }} />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h style={{ color: "#fff", fontSize: "2vw" }}>For smartphone (controller)</h>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "inline-flex",
                      alignContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <a
                      href="https://apps.apple.com/us/app/runster/id1402662894"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "auto", height: "6vh" }}
                        alt="appStore"
                        src={require("../../img/appStore.png")}
                      />
                    </a>
                    <div style={{ width: "1vw" }} />
                    <a
                      href="https://play.google.com/store/apps/details?id=com.runster"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "auto", height: "6vh" }}
                        alt="googlePlay"
                        src={require("../../img/googlePlay.png")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <video
                width="60%"
                height="auto"
                src={require("../../video/control-with-your-body.mp4")}
                type="video/mp4"
                autoPlay
                muted
                loop
              />
            </div>
            <br />
            <div style={{ flexDirection: "row", display: "flex" }}>
              <img
                alt="health"
                src={require("../../img/health-tracker.png")}
                style={{ width: "60vw", height: "85.94vw" }}
              />
              <div style={{ flexDirection: "column", paddingTop: "30vh", paddingRight: "10vw" }}>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <h style={{ color: "#a3fcfc", fontSize: "4vw", textAlign: "left" }}>health tracker</h>
                  <div style={{ flex: 1 }} />
                </div>
                <div style={{}}>
                  <p style={{ color: "#fff", fontSize: "1.6vw", textAlign: "left" }}>
                    Show your health-related results and progress by using the built-in health tracker.
                  </p>
                  <p style={{ fontSize: "1.6vw", color: "#fff", textAlign: "left" }}>
                    <img
                      alt="shoe"
                      src={require("../../img/shoe.png")}
                      style={{ width: "2vw", height: "2vw", marginRight: "1vw", marginTop: "1vw" }}
                    />
                    Steps counted while running
                  </p>
                  <p style={{ fontSize: "1.6vw", color: "#fff", textAlign: "left" }}>
                    <img
                      alt="fire"
                      src={require("../../img/fire.png")}
                      style={{ width: "2vw", height: "2vw", marginRight: "1vw", marginTop: "1vw" }}
                    />
                    Estimated calories burned
                  </p>
                  <p style={{ fontSize: "1.6vw", color: "#fff", textAlign: "left" }}>
                    <img
                      alt="clock"
                      src={require("../../img/clock.png")}
                      style={{ width: "2vw", height: "2vw", marginRight: "1vw", marginTop: "1vw" }}
                    />
                    Running distance
                  </p>
                </div>
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <img
                alt="4v4full"
                src={require("../../img/4v4full.png")}
                style={{ width: "100%", height: "auto", display: "float" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "10vh",
                  width: "50%",
                  paddingLeft: "10vw",
                  alignItems: "left",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <h style={{ color: "#a3fcfc", fontSize: "4vw", textAlign: "left", width: "100%" }}>
                    Online with others
                  </h>
                  <div style={{ flex: 1 }} />
                </div>
                <div style={{ flexDirection: "row", display: "flex", alignContent: "flex-start" }}>
                  <p style={{ color: "#fff", fontSize: "1.6vw", textAlign: "left", width: "25vw" }}>
                    Everyone can run together in 4v4 online battle mode.
                  </p>
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "#041116" }}>
              <div style={{ position: "relative" }}>
                <img
                  alt="end"
                  src={require("../../img/end-character.png")}
                  style={{ marginTop: "-7px", width: "100%", height: "auto", display: "float" }}
                />
                <center
                  style={{
                    position: "absolute",
                    top: "8px",
                    width: "100%",
                    paddingLeft: "10vw",
                    paddingRight: "10vw",
                  }}
                >
                  <div style={{ height: "20vh" }} />
                  <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }} />
                    <h
                      style={{
                        color: "#fff",
                        fontSize: "3.5vw",
                        top: "10px",
                        lineHeight: "110%",
                        width: "38vw",
                      }}
                    >
                      Free demo is available on
                    </h>
                    <img
                      alt="Steam_logo"
                      src={require("../../img/Steam_logo.png")}
                      style={{ height: "6vw", width: "auto", marginTop: "-2vh" }}
                    />
                    <div style={{ flex: 1 }} />
                  </div>
                  <a
                    href="https://store.steampowered.com/app/1081670/Runster/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ height: "auto", width: "30vw" }}
                      alt="tryDemo"
                      src={require("../../img/tryDemo.png")}
                    />
                  </a>
                  <div>
                    <h style={{ color: "#fff", fontSize: "2vw" }}>For smartphone (controller)</h>
                    <br />
                    <div
                      style={{
                        flexDirection: "row",
                        display: "inline-flex",
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <a
                        href="https://apps.apple.com/us/app/runster/id1402662894"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{ width: "auto", height: "8vh" }}
                          alt="appStore"
                          src={require("../../img/appStore.png")}
                        />
                      </a>
                      <div style={{ width: "1vw" }} />
                      <a
                        href="https://play.google.com/store/apps/details?id=com.runster"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{ width: "auto", height: "8vh" }}
                          alt="googlePlay"
                          src={require("../../img/googlePlay.png")}
                        />
                      </a>
                    </div>
                    <br />
                  </div>
                </center>
              </div>
              <div style={{ height: "5vh" }} />
              <h style={{ color: "#fff", fontSize: "2vw" }}>contact us</h>
              <p style={{ color: "#fff", fontSize: "1.6vw" }}>RunsterOnline@gmail.com</p>
              <br />
              <a href="https://www.facebook.com/RunsterOnline" target="_blank" rel="noreferrer">
                <img
                  style={{ width: "4vw", height: "4vw" }}
                  alt="fbIcon"
                  src={require("../../img/fbIcon.png")}
                />
              </a>
              <br />
              <div style={{ height: "10vh" }} />
            </div>
          </center>
        </div>
      );
    }
  }
}

export default Runster;
