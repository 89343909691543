import React from "react";
import { useSearchParams } from 'react-router-dom';

const StaticFooter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const language = searchParams.get('hl') || "English"
  
  var termsText = "Terms of service"
  var pdpaText = "Privacy policy"
  
  if(language == "Thai")
  {
    termsText = "เงื่อนไขการให้บริการ"
    pdpaText = "นโยบายความเป็นส่วนตัว"
  }

  return (
    <div className="bg-[#373340] pt-14 py-6 lg:py-12 flex justify-center text-white">
      <div className="flex flex-col md:flex-row items-center space-y-2 lg:space-y-5">
        <div className="flex justify-center">
          <img className="h-12" alt="logo" src="/img/fitforce2logo.png" />
        </div>
        <div className="w-10 v-5"></div>
        <div>
          <a
            href={`/terms?hl=${language}`}
            target="_blank"
            className="cursor-pointer text-center text-base lg:text-xl textshadow"
            rel="noreferrer"
          >
            {termsText}
          </a>
        </div>
        <div className="w-10 v-5"></div>
        <div>
          <a
            href={`/privacy-policy?hl=${language}`}
            target="_blank"
            className="cursor-pointer text-center text-base lg:text-xl textshadow"
            rel="noreferrer"
          >
            {pdpaText}
          </a>
        </div>
        <div className="w-10 v-5"></div>
        <div className="flex flex-row justify-center space-x-3">
          <div>
            <a
              href="https://www.facebook.com/fitforce.th"
              target="_blank"
              className="cursor-pointer text-center"
              rel="noreferrer"
            >
              <img className="h-7 lg:h-10" alt="fb-logo2" src={require("../img/fbIcon.png")}/>
            </a>
          </div>
          <div>
            <a
              href="https://www.tiktok.com/@fitforce2.th"
              target="_blank"
              className="cursor-pointer text-center"
              rel="noreferrer"
            >
              <img className="h-8 lg:h-11" alt="fb-logo2" src={require("../img/TikTok.png")}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticFooter;
