import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { quizApi } from "../api";
import DataRefresher from "./DataRefresher";
import { useFormik } from "formik";
import { customTableStyles } from "../helper/misc";

const QuestionManager = () => {
  const [data, setData] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(moment());

  const fetchData = () => {
    setLoading(true);
    quizApi.getAll().then((r) => {
      setData(r.data || []);
      setLastUpdated(moment());
      setLoading(false);
      setInitialized(true);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createFormik = useFormik({
    initialValues: {
      Question: "",
      Category: "",
      Options: [{ text: "", score: "" }],
    },
    onSubmit: (values) => {
      handleCreate(values);
    },
  });

  const updateFormik = useFormik({
    initialValues: {
      Id: "",
      Question: "",
      Category: "",
      Options: [{ text: "", score: "" }],
    },
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  const handleCreate = (values) => {
    const requestData = {
      question: values.Question,
      category: values.Category,
      options: values.Options.map((option) => ({
        text: option.text,
        score: parseInt(option.score),
      })),
    };
    quizApi.create(requestData).then((r) => {
      createFormik.resetForm();
      fetchData();
    });
  };

  const handleUpdate = (values) => {
    const requestData = {
      question: values.Question,
      category: values.Category,
      options: values.Options.map((option) => ({
        text: option.text,
        score: parseInt(option.score),
      })),
    };
    quizApi.update(values.Id, requestData).then((r) => {
      updateFormik.resetForm();
      fetchData();
    });
  };


  const handleDelete = (id) => {
    setLoading(true);
    quizApi.delete(id).then((r) => {
      fetchData();
    });
  };

  const columns = [
    { name: "Id", selector: (row) => row._id, sortable: true },
    { name: "Question", selector: (row) => row.question, sortable: true },
    { name: "Category", selector: (row) => row.category, sortable: true },
    // ...[1, 2, 3, 4].map((index) => ({
    //   name: `Option ${index}`,
    //   selector: (row) => (row.options[index - 1] ? row.options[index - 1].text : ""),
    //   sortable: true,
    //   wrap: true,
    // })),
    { name: "Action", cell: (row) => <ActionButtons row={row} />, sortable: true },
  ];

  const handleOptionTextChange = (index, event) => {
    const value = event.target.value;
    const options = creating ? createFormik.values.Options : updateFormik.values.Options;
    options[index].text = value;
    creating
      ? createFormik.setFieldValue("Options", [...options])
      : updateFormik.setFieldValue("Options", [...options]);
  };

  const handleOptionScoreChange = (index, event) => {
    const value = event.target.value;
    const options = creating ? createFormik.values.Options : updateFormik.values.Options;
    options[index].score = value;
    creating
      ? createFormik.setFieldValue("Options", [...options])
      : updateFormik.setFieldValue("Options", [...options]);
  };

  const handleAddOption = () => {
    const options = creating ? createFormik.values.Options : updateFormik.values.Options;
    const updatedOptions = [...options, { text: "", score: "" }];
    creating
      ? createFormik.setFieldValue("Options", updatedOptions)
      : updateFormik.setFieldValue("Options", updatedOptions);
  };

  const handleRemoveOption = (index) => {
    const options = creating ? createFormik.values.Options : updateFormik.values.Options;
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    creating
      ? createFormik.setFieldValue("Options", updatedOptions)
      : updateFormik.setFieldValue("Options", updatedOptions);
  };

  const ActionButtons = ({ row }) => {
    return (
      <>
        <button
          onClick={() =>
            updateFormik.setFieldValue("Id", row._id) &&
            updateFormik.setFieldValue("Question", row.question) &&
            updateFormik.setFieldValue("Category", row.category) &&
            updateFormik.setFieldValue("Options", row.options) &&
            setUpdating(row) &&
            setCreating(false)
          }
          type="button"
          className="inline-block my-2 px-3 py-2 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-teal-800"
        >
          Edit
        </button>
        <button
          onClick={() => handleDelete(row._id)}
          type="button"
          className="inline-block my-2 ml-2 px-3 py-2 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-red-800"
        >
          Delete
        </button>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="flex justify-between">
          <h1 className="text-3xl font-semibold text-gray-800">Question Manager</h1>
          <button
            onClick={() => setCreating(true) && setUpdating(null)}
            type="button"
            className="m-4 px-6 py-2 border-2 border-gray-400 text-gray-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 transition duration-150 ease-in-out"
          >
            + Create new question
          </button>
        </div>
        <DataTable
          title={
            <div className="flex justify-between">
              <DataRefresher refetch={fetchData} loading={loading} lastUpdated={lastUpdated} />
            </div>
          }
          columns={columns}
          data={data}
          progressPending={!initialized}
          disabled={loading}
          pagination
          customStyles={customTableStyles}
        />
        <div className="flex">
          {creating && (
            <div className="flex flex-col border border-gray-400 m-4 p-4 w-96 rounded-lg">
              <div>
                <h1 className="text-2xl font-semibold text-gray-800">Create new question</h1>
              </div>
              <input
                type="text"
                name="Question"
                onChange={createFormik.handleChange}
                value={createFormik.values.Question}
                placeholder="Question"
                className="block w-full px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
              />
              <input
                type="text"
                name="Category"
                onChange={createFormik.handleChange}
                value={createFormik.values.Category}
                placeholder="Category"
                className="block w-full mt-2 px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
              />
              {createFormik.values.Options.map((option, index) => (
                <div className="flex mt-2" key={index}>
                  <input
                    type="text"
                    name={`Options[${index}].text`}
                    onChange={(event) => handleOptionTextChange(index, event)}
                    value={option.text}
                    placeholder={`Option ${index + 1}`}
                    className="block w-full px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                  <input
                    type="text"
                    name={`Options[${index}].score`}
                    onChange={(event) => handleOptionScoreChange(index, event)}
                    value={option.score}
                    placeholder="Score"
                    className="block w-full ml-2 px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                  <button
                    onClick={() => handleRemoveOption(index)}
                    type="button"
                    className="ml-2 px-2 py-1 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-red-800"
                  >
                    X
                  </button>
                </div>
              ))}
              {createFormik.values.Options.length < 6 && (
                <button
                  onClick={handleAddOption}
                  type="button"
                  className="mt-2 px-4 py-2 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-teal-800"
                >
                  + Add Option
                </button>
              )}
              <button
                onClick={() => {
                  setCreating(false);
                  createFormik.resetForm();
                }}
                type="button"
                className="mt-4 px-4 py-2 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setCreating(false);
                  createFormik.handleSubmit();
                }}
                type="submit"
                className="mt-4 px-4 py-2 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-green-800"
              >
                Create
              </button>
            </div>
          )}
          {updating && (
            <div className="flex flex-col border border-gray-400 m-4 p-4 w-96 rounded-lg">
              <div>
                <h1 className="text-2xl font-semibold text-gray-800">Update question</h1>
              </div>
              <input
                type="text"
                name="Question"
                onChange={updateFormik.handleChange}
                value={updateFormik.values.Question}
                placeholder="Question"
                className="block w-full px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
              />
              <input
                type="text"
                name="Category"
                onChange={updateFormik.handleChange}
                value={updateFormik.values.Category}
                placeholder="Category"
                className="block w-full mt-2 px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
              />
              {updateFormik.values.Options.map((option, index) => (
                <div className="flex mt-2" key={index}>
                  <input
                    type="text"
                    name={`Options[${index}].text`}
                    onChange={(event) => handleOptionTextChange(index, event)}
                    value={option.text}
                    placeholder={`Option ${index + 1}`}
                    className="block w-full px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                  <input
                    type="text"
                    name={`Options[${index}].score`}
                    onChange={(event) => handleOptionScoreChange(index, event)}
                    value={option.score}
                    placeholder="Score"
                    className="block w-full ml-2 px-5 py-3 border-gray-300 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                  <button
                    onClick={() => handleRemoveOption(index)}
                    type="button"
                    className="ml-2 px-2 py-1 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-red-800"
                  >
                    X
                  </button>
                </div>
              ))}
              {updateFormik.values.Options.length < 6 && (
                <button
                  onClick={handleAddOption}
                  type="button"
                  className="mt-2 px-4 py-2 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-teal-800"
                >
                  + Add Option
                </button>
              )}
              <button
                onClick={() => {
                  updateFormik.resetForm();
                  setUpdating(null);
                }}
                type="button"
                className="mt-4 px-4 py-2 bg-gray-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  updateFormik.handleSubmit();
                  setUpdating(null);
                }}
                type="submit"
                className="mt-4 px-4 py-2 bg-green-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-green-800"
              >
                Update
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QuestionManager;
