import moment from "moment";

export const customTableStyles = {
  headCells: {
    style: {
      backgroundColor: "#eee",
      fontWeight: "bold",
    },
  },
};

export const formatDateTime = (date) => {
  const momentDate = moment(date);
  if (momentDate.year() === 1) return "-";
  return momentDate.format("DD/MM/YYYY HH:mm");
};

export const makeSlug = (text) => {
  if (!text) return "";
  return text
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\wก-๙เแโใไ]+/g, "-");
};

export const makeArticleUrl = (slug) => {
  return window.location.origin + "/blogs/" + slug;
};

export const capitalizeObjectKeys = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
    return acc;
  }, {});
};
