export const getNameByID = (gameID) => {
  switch (gameID) {
    case "fitforce.g01":
      return "Running warrior";
    case "fitforce.g02":
      return "Rocket Sqauat";
    case "fitfroce.g03":
      return "Flappy dive";
    case "fitforce.g04":
      return "Unicycling";
    case "fitforce.g05":
      return "Balloon punch";
    case "fitforce.g06":
      return "Factory cutter";
    case "fitforce.g07":
      return "Climbing jack";
    case "fitforce.g08":
      return "Twisted tempo";
    default:
      return "Unknown";
  }
};
