import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import Favicon from "react-favicon";
import Modal from "react-modal";
import Select from "react-select";
import Cookies from "universal-cookie";
import "../../css/Home.css";
import { countryOptions } from "../../helper/CountryList";
import { IndustryOptions } from "../../helper/IndustryList";
import { logActivity } from "../../helper/LogActivity";
import { YearOption } from "../../helper/YearList";

const cookies = new Cookies();

var campaignID;
var cookieID = "";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10000,
    backgroundColor: "#2D2D2D",
    borderWidth: 0,
    borderRadius: "5vh",
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "#000b",
  },
};

export default class FitTroopToon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPortriat: window.innerWidth < window.innerHeight,
      isMobile: window.innerWidth <= 480,
      isShowModal: false,
      isCompleteForm: false,
      isIncorrectEmail: false,
      email: null,
      countryOption: null,
      yearOption: null,
      indrustryOption: null,
    };

    document.title = "FitRanger";
    campaignID = "FitRanger";

    cookieID = cookies.get("fitforceCookieID");

    this.timer = 0;
    this.sendFormCountDown = this.sendFormCountDown.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    if (cookieID !== undefined) {
      logActivity("DownloadPage", campaignID, cookieID);
    } else {
      fetch("/api/gen-cookie", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          cookies.set("fitforceCookieID", data.CookieID, { path: "/" });
          cookieID = cookies.get("fitforceCookieID");
          logActivity("DownloadPage", campaignID, cookieID);
        });
    }
  }

  resize = () => {
    this.setState({
      isMobile: window.innerWidth <= 480,
      isPortriat: window.innerWidth < window.innerHeight,
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  openModal(source) {
    logActivity("OpenPreRegister_" + source, campaignID, cookieID);
    this.setState({ isShowModal: true, isCompleteForm: false });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendForm() {
    var isValidEmail = this.validateEmail(this.state.email);

    this.setState({ isIncorrectEmail: !isValidEmail });
    if (!isValidEmail) return;

    var _this = this;

    fetch("/api/pre-register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CookieID: cookieID,
        CampaignID: campaignID,
        Email: this.state.email,
        Country: this.state.countryOption.label,
        YearOfBirth: this.state.yearOption.value,
        Job: this.state.indrustryOption.value,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        _this.setState({ isCompleteForm: true });
        _this.timer = setInterval(_this.sendFormCountDown, 2000);
      });
  }

  sendFormCountDown() {
    clearInterval(this.timer);
    this.setState({ isShowModal: false });
  }

  handleCountryChange = (countryOption) => {
    this.setState({ countryOption });
  };

  handleYearChange = (yearOption) => {
    this.setState({ yearOption });
  };

  handleIndustryChange = (indrustryOption) => {
    this.setState({ indrustryOption });
  };

  render() {
    const { countryOption, yearOption, indrustryOption } = this.state;

    return (
      <div style={{ width: "100%", minHeight: "100vh", backgroundColor: "#fff", overflow: "hidden" }}>
        <Favicon url={require("../../img/FitToon/FitRangerRedFire.png")} />
        <div
          style={{
            backgroundImage: `url(${require("../../img/FitToon/pcBG.jpg")})`,
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            padding: "5vw",
            paddingTop: "1vw",
            backgroundPosition: "center center",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            position: "relative",
            height: "100vh",
          }}
        >
          {!this.state.isPortriat && (
            <img
              style={{
                width: "clamp(60vh, 60vw, 102.61vh)",
                height: "clamp(52.63vh, 52.63vw, 90vh)",
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
              alt="HeroImgPC"
              src={require("../../img/FitToon/HeroImgPC.png")}
            />
          )}
          {this.state.isPortriat && (
            <img
              style={{
                width: "clamp(65vw, 44.56vh, 100vw)",
                height: "clamp(67.35vw, 50vh, 103.62vw)",
                position: "absolute",
                right: 0,
                bottom: 0,
                zIndex: 1,
              }}
              alt="HeroImgMobile"
              src={require("../../img/FitToon/HeroImgMobile.png")}
            />
          )}
          <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
            <img
              style={{ width: "max(15vw, 25vh)", height: "max(4.03vw, 6.045vh)", zIndex: 2 }}
              alt="Logo"
              src={require("../../img/FitToon/Logo.png")}
            />
            <div style={{ flex: 1 }} />
            <span
              onClick={() => this.openModal("nav")}
              style={{
                fontFamily: "Lato",
                color: "#fff",
                textDecoration: "underline",
                fontSize: "max(1.25vw, 2.5vh)",
              }}
            >
              Sign Up
            </span>
          </div>
          <div style={{ height: "max(6vw, 6vh)" }} />
          <div style={{ flexDirection: "row", display: "flex", flex: 1 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ flex: 1 }} />
              <p
                style={{
                  fontFamily: "Roboto-Black",
                  color: "#D84D00",
                  fontSize: !this.state.isPortriat ? "max(2.75vw, 5.5vh)" : "3.25vh",
                  lineHeight: 1.2,
                  width: "clamp(40vw, 70vh, 90vw)",
                  zIndex: 2,
                }}
              >
                INTERACTIVE <br /> AT-HOME WORKOUT GAME {!this.state.isPortriat && <br />} IN YOUR POCKET
              </p>
              <div style={{ height: !this.state.isPortriat ? "max(1.5vw, 2.5vh)" : "1.75vh" }} />
              <p
                style={{
                  fontFamily: "Roboto",
                  color: "#2b2b2b",
                  fontSize: !this.state.isPortriat ? "max(1vw, 2vh)" : "1.5vh",
                  width: !this.state.isPortriat ? "clamp(25vw, 40vh, 70vw)" : "70vw",
                  zIndex: 2,
                }}
              >
                At-home workout game to help your calisthenics, cardio and strength training skills with
                interactive games and movement tracking technology.
              </p>
              <div style={{ height: "max(2vw, 3vh)" }} />
              <p
                style={{
                  fontFamily: "Lato",
                  color: "#2b2b2b",
                  fontSize: !this.state.isPortriat ? "max(1.5vw, 2.5vh)" : "1.75vh",
                  zIndex: 2,
                }}
              >
                <b>Get your pre-early access for free</b>
              </p>
              <div style={{ height: "max(0.5vw, 1vh)" }} />
              <span
                onClick={() => this.openModal("heroImage")}
                style={{ flexDirection: "row", display: "flex", zIndex: 2 }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "5vw",
                    padding: "1vh",
                    paddingLeft: "max(2vw, 3vh)",
                    paddingRight: "max(2vw, 3vh)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <p
                    style={{
                      fontFamily: "Lato",
                      fontSize: "max(1.5vw, 2.5vh)",
                      color: "#FF8B00",
                      textAlign: "center",
                    }}
                  >
                    <b>SIGN UP NOW</b>
                  </p>
                  <div style={{ flex: 1 }} />
                </div>
                <div style={{ flex: 1 }} />
              </span>
              <div style={{ flex: this.state.isPortriat ? 9 : 2 }} />
            </div>
          </div>
        </div>

        <div
          style={{
            padding: this.state.isMobile ? "4vh" : "max(5vw, 3vh)",
            display: "flex",
            flexDirection: this.state.isMobile ? "column" : "row",
          }}
        >
          <div style={{ flex: 1 }} />
          {this.state.isMobile && (
            <img
              style={{ marginLeft: 0, height: "42.714vw", width: "86.272vw", marginBottom: "2vh" }}
              alt="CameraTracker"
              src={require("../../img/FitToon/CameraTracker.png")}
            />
          )}
          <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
            <div style={{ flex: 1 }} />
            <div
              style={{
                marginLeft: 0,
                display: "flex",
                flexDirection: "column",
                width: this.state.isMobile ? "60vw" : "",
              }}
            >
              <p
                style={{
                  font: "Montserrat",
                  fontWeight: 900,
                  fontSize: this.state.isMobile ? "3.5vh" : "max(2.5vw, 2.75vh)",
                  color: "#FF7121",
                  textAlign: "start",
                }}
              >
                Get fit, get fun
              </p>
              <p
                style={{
                  font: "Montserrat",
                  fontWeight: 900,
                  fontSize: this.state.isMobile ? "3.5vh" : "max(2.5vw, 2.75vh)",
                  color: "#2d2d2d",
                  marginTop: "max(-1.25vw, -1.25vh)",
                }}
              >
                in few seconds
              </p>
              <div style={{ height: "max(1.25vw, 1vh)" }} />
              <p
                style={{
                  font: "Lato",
                  fontSize: "max(1.5vw, 1.5vh)",
                  width: this.state.isMobile ? "80vw" : "clamp(23vw, 25vh, 45vw)",
                }}
              >
                1. Open application <br />
                2. Let camera detect you <br />
                3. Enjoy your exercise!
              </p>
            </div>
            <div style={{ flex: 1 }} />
          </div>
          {!this.state.isMobile && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ flex: 1 }} />
              <img
                style={{ marginLeft: 0, height: "24.76vw", width: "50vw" }}
                alt="CameraTracker"
                src={require("../../img/FitToon/CameraTracker.png")}
              />
              <div style={{ flex: 1 }} />
            </div>
          )}
          <div style={{ flex: 1 }} />
        </div>

        <div style={{ display: "flex", flexDirection: this.state.isMobile ? "column" : "row" }}>
          <img
            style={{
              height: this.state.isMobile ? "100vw" : "clamp(40vw, 27.82vh, 50vw)",
              width: this.state.isMobile ? "112.875vw" : "clamp(43.136vw, 30vh, 53.92vw)",
            }}
            alt="SquatDemo"
            src={require("../../img/FitToon/SquatDemo.png")}
          />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FF7121",
              padding: "5vw",
            }}
          >
            <div style={{ flex: 1 }} />
            <p style={{ font: "Montserrat", fontWeight: 900, fontSize: "max(2.5vw, 2.5vh)", color: "#fff" }}>
              Fun and easy
            </p>
            <div style={{ height: "max(1.25vw, 1vh)" }} />
            <p style={{ font: "Lato", fontSize: "max(1.25vw, 1.5vh)", color: "#fff" }}>
              Play games with your body and get your bodyweight game right and safe. Plus, all games are
              gym-beginner friendly.
            </p>
            <div style={{ height: "max(2.5vw, 2vh)" }} />
            <div style={{ height: "0.5vw" }} />
            <span onClick={() => this.openModal("funeasy")} style={{ flexDirection: "row", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "5vw",
                  padding: "1vh",
                  paddingLeft: "max(2vw, 2vh)",
                  paddingRight: "max(2vw, 2vh)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "max(1.5vw, 2vh)",
                    color: "#FF7121",
                    textAlign: "center",
                  }}
                >
                  <b>SIGN UP NOW</b>
                </p>
                <div style={{ flex: 1 }} />
              </div>
              <div style={{ flex: 1 }} />
            </span>
            <div style={{ flex: 1 }} />
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(${require("../../img/FitToon/HomeBG.png")})`,
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            display: "flex",
            flexDirection: this.state.isMobile ? "column" : "row",
            paddingTop: "3vw",
          }}
        >
          <div
            style={{ flex: 1, display: "flex", flexDirection: "column", padding: "5vw", paddingTop: "5vw" }}
          >
            <p style={{ font: "Montserrat", fontWeight: 900, fontSize: "max(2.5vw, 2.5vh)", color: "#000" }}>
              Freedom of exercise in your hands
            </p>
            <div style={{ height: "max(1.25vw, 1vh)" }} />
            <p
              style={{
                font: "Lato",
                fontSize: "max(1.25vw, 1.5vh)",
                width: this.state.isMobile ? "80vw" : "max(30vw, 30vh)",
              }}
            >
              Workout anytime, anywhere. All you need is just a smartphone. No gym equipment or pricey device
              required.
            </p>
            <div style={{ height: "max(2.5vw, 2vh)" }} />
            <div style={{ height: "0.5vw" }} />
            <span onClick={() => this.openModal("freedom")} style={{ flexDirection: "row", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "#FF7121",
                  borderRadius: "5vw",
                  padding: "1vh",
                  paddingLeft: "max(2vw, 2vh)",
                  paddingRight: "max(2vw, 2vh)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "max(1.5vw, 2vh)",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  <b>SIGN UP NOW</b>
                </p>
                <div style={{ flex: 1 }} />
              </div>
              <div style={{ flex: 1 }} />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: this.state.isMobile ? "row" : "column",
              marginTop: this.state.isMobile ? "3vh" : "",
            }}
          >
            <div style={{ flex: 1 }} />
            <img
              style={{
                marginLeft: 0,
                height: this.state.isMobile ? "65.86vw" : "32.93vw",
                width: this.state.isMobile ? "80vw" : "40vw",
              }}
              alt="FitGuy"
              src={require("../../img/FitToon/FitGuy.png")}
            />
            {this.state.isMobile && <div style={{ flex: 1 }} />}
          </div>
        </div>

        <div
          style={{
            padding: "max(3vw, 3vh)",
            display: "flex",
            flexDirection: this.state.isMobile ? "column" : "row",
          }}
        >
          <div style={{ flex: 1 }} />
          {!this.state.isMobile && (
            <img
              style={{ marginLeft: 0, height: "min(100vh, 79.33vw)", width: "min(37.82vh, 30vw)" }}
              alt="resultPC"
              src={require("../../img/FitToon/resultPC.png")}
            />
          )}
          {this.state.isMobile && (
            <img
              style={{ marginLeft: 0, height: "76.85vw", width: "80vw", alignSelf: "center" }}
              alt="resultMobile"
              src={require("../../img/FitToon/resultMobile.png")}
            />
          )}
          <div style={{ flex: 1, marginLeft: "min(8vw, 12vh)", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 1 }} />
            <div style={{ height: "max(2.5vw, 2vh)" }} />
            <p
              style={{
                font: "Montserrat",
                fontWeight: 900,
                color: "#FF7121",
                fontSize: "max(2.5vw, 2.5vh)",
                lineHeight: 1.2,
              }}
            >
              Customize your <br /> workout goal
            </p>
            <div style={{ height: "max(1.25vw, 1vh)" }} />
            <p
              style={{
                font: "Lato",
                fontSize: "max(1.25vw, 1.75vh)",
                width: this.state.isMobile ? "80vw" : "35vw",
              }}
            >
              You pick your exercise goal. We pick the right game and right intensity to match with that goal.
              No more complex exercise planning required. Enjoy!
            </p>
            <div style={{ height: "max(3vw, 4vh)" }} />
            <p
              style={{
                font: "Montserrat",
                fontWeight: 900,
                color: "#FF7121",
                fontSize: "max(2.5vw, 2.5vh)",
                lineHeight: 1.2,
              }}
            >
              Instant feedback
            </p>
            <div style={{ height: "max(1.25vw, 1vh)" }} />
            <p
              style={{
                font: "Lato",
                fontSize: "max(1.25vw, 1.75vh)",
                width: this.state.isMobile ? "80vw" : "35vw",
              }}
            >
              See instantly how many calories you burn and how well you do each time you play.
            </p>
            <div style={{ height: "max(2.5vw, 2vh)" }} />
            <p style={{ font: "Lato", fontWeight: "bold", fontSize: "max(1.25vw, 2vh)", color: "#2d2d2d" }}>
              Get your pre-early access for free
            </p>
            <div style={{ height: "max(0.7vw, 1vh)" }} />
            <div style={{ height: "0.5vw" }} />
            <span
              onClick={() => this.openModal("workoutgoal")}
              style={{ flexDirection: "row", display: "flex" }}
            >
              <div
                style={{
                  backgroundColor: "#FF7121",
                  borderRadius: "5vw",
                  padding: "1vh",
                  paddingLeft: "max(2vw, 3vh)",
                  paddingRight: "max(2vw, 3vh)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "max(1.5vw, 2.5vh)",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  <b>SIGN UP NOW</b>
                </p>
                <div style={{ flex: 1 }} />
              </div>
              <div style={{ flex: 1 }} />
            </span>
            <div style={{ flex: 1 }} />
          </div>
          <div style={{ flex: 1 }} />
        </div>

        <div style={{ backgroundColor: "#00BAD3" }}>
          <div
            style={{
              backgroundImage: this.state.isMobile
                ? `url(${require("../../img/FitToon/TrainersBgMb.png")})`
                : `url(${require("../../img/FitToon/TrainersBGPC.png")})`,
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              display: "flex",
            }}
          >
            <div
              style={{
                flexDirection: this.state.isMobile ? "column" : "row",
                display: "flex",
                paddingLeft: "8vw",
                paddingRight: "8vw",
                paddingTop: "max(4vw, 3vh)",
              }}
            >
              <div style={{ flexDirection: "column", display: "flex", flex: 1 }}>
                <p
                  style={{
                    font: "Montserrat",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "max(3vw, 4vh)",
                    lineHeight: 1.2,
                    marginTop: this.state.isMobile ? "" : "max(2vw, 3vh)",
                  }}
                >
                  Meet the new you <br /> within 14 days
                </p>
                <div style={{ height: "max(2vw, 2vh)" }} />
                <p
                  style={{
                    font: "Lato",
                    fontSize: "max(1.25vw, 1.75vh)",
                    width: this.state.isMobile ? "80vw" : "35vw",
                    color: "#fff",
                  }}
                >
                  Each workout program is designed by the professional trainers to give you a noticeable
                  change within your 14-day-straight commitment.
                </p>
                <div style={{ height: "max(3vw, 4vh)" }} />
                <span
                  onClick={() => this.openModal("14days")}
                  style={{ flexDirection: "row", display: "flex" }}
                >
                  <div
                    style={{
                      backgroundColor: "#FF7121",
                      borderRadius: "5vw",
                      padding: "1vh",
                      paddingLeft: "max(2vw, 3vh)",
                      paddingRight: "max(2vw, 3vh)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ flex: 1 }} />
                    <p
                      style={{
                        fontFamily: "Rubik",
                        fontSize: "max(1.5vw, 2.5vh)",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      <b>SIGN UP NOW</b>
                    </p>
                    <div style={{ flex: 1 }} />
                  </div>
                </span>
                <div style={{ height: "max(3vw, 6vh)" }} />
                <div style={{ height: "7vw" }} />
              </div>
              <div style={{ flexDirection: "column", display: "flex" }}>
                <divs style={{ flex: 1 }} />
                <img
                  style={{
                    height: this.state.isPortriat ? "74.12vw" : "clamp(41.69vh, 41.69vw, 83.385vh)",
                    width: this.state.isPortriat ? "80vw" : "clamp(45vh, 45vw, 90vh)",
                    marginTop: "2vw",
                    marginLeft: this.state.isMobile ? 0 : "5vw",
                  }}
                  alt="Trainercouple"
                  src={require("../../img/FitToon/Trainercouple.png")}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#2d2d2d" }}>
          <div
            style={{
              backgroundImage: `url(${require("../../img/FitToon/TgsBG.png")})`,
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              padding: "8vw",
              backgroundPosition: "center center",
            }}
          >
            <center>
              <img
                style={{
                  width: this.state.isMobile ? "60vw" : "30vw",
                  height: this.state.isMobile ? "16.26vw" : "8.13vw",
                }}
                alt="TaipeiGSH"
                src={require("../../img/FitToon/TaipeiGSH.png")}
              />
              <div style={{ height: "max(1vw, 2vh)" }} />
              <p
                style={{
                  fontFamily: "Roboto",
                  color: "#fff",
                  fontSize: "max(1.5vw, 2vh)",
                  width: this.state.isMobile ? "80vw" : "50vw",
                }}
              >
                Designed by the team, listed on the top 5 best innovation award from Taipei game show 2020 and
                50K users’ feedback
              </p>
              <div style={{ height: "max(3vw, 3vh)" }} />
              <span
                onClick={() => this.openModal("taipei")}
                style={{ flexDirection: "row", display: "flex" }}
              >
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "5vw",
                    padding: "1vh",
                    paddingLeft: "max(2vw, 3vh)",
                    paddingRight: "max(2vw, 3vh)",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "10vw",
                  }}
                >
                  <div style={{ flex: 1 }} />
                  <p
                    style={{
                      fontFamily: "Rubik",
                      fontSize: "max(1.5vw, 2.5vh)",
                      color: "#FF7121",
                      textAlign: "center",
                    }}
                  >
                    <b>SIGN UP NOW</b>
                  </p>
                  <div style={{ flex: 1 }} />
                </div>
                <div style={{ flex: 1 }} />
              </span>
            </center>
          </div>
        </div>

        <div
          style={{
            padding: "max(2.5vw, 4vh)",
            display: "flex",
            flexDirection: this.state.isMobile ? "column" : "row",
          }}
        >
          <div style={{ flex: 1 }} />
          {!this.state.isMobile && (
            <img
              style={{ height: "min(80vh, 92.24vw)", width: "min(43.37vh, 50vw)" }}
              alt="freeAccess"
              src={require("../../img/FitToon/freeAccess.png")}
            />
          )}
          <div style={{ marginLeft: "min(8vw, 12vh)", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 1 }} />
            <p
              style={{
                font: "Montserrat",
                fontWeight: 700,
                fontSize: "max(2.5vw, 3.5vh)",
                lineHeight: 1.3,
                color: "#2d2d2d",
              }}
            >
              GET YOUR <br /> PRE-EARLY ACCESS <br /> FOR FREE!
            </p>
            <div style={{ height: "max(1.25vw, 2vh)" }} />
            <div style={{ height: "0.5vw" }} />
            <span
              onClick={() => this.openModal("freeAccess")}
              style={{ flexDirection: "row", display: "flex" }}
            >
              <div
                style={{
                  backgroundColor: "#FF7121",
                  borderRadius: "5vw",
                  padding: "1vh",
                  paddingLeft: "max(2vw, 3vh)",
                  paddingRight: "max(2vw, 3vh)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1 }} />
                <p
                  style={{
                    fontFamily: "Rubik",
                    fontSize: "max(1.5vw, 2.5vh)",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  <b>SIGN UP NOW</b>
                </p>
                <div style={{ flex: 1 }} />
              </div>
              <div style={{ flex: 1 }} />
            </span>
            <div style={{ flex: 1 }} />
          </div>
          <div style={{ height: "max(1.25vw, 2vh)" }} />
          {this.state.isMobile && (
            <img
              style={{ height: "147.58vw", width: "80vw", alignSelf: "center" }}
              alt="freeAccess"
              src={require("../../img/FitToon/freeAccess.png")}
            />
          )}
          <div style={{ flex: 1 }} />
        </div>

        <div style={{ backgroundColor: "#2D2D2D", padding: "3vw" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }} />
            <img
              style={{ width: "max(15vw, 25vh)", height: "max(4.03vw, 6.045vh)", padding: 3 }}
              alt="logoColor"
              src={require("../../img/FitToon/logoColor.png")}
            />
            <div style={{ flex: 1 }} />
          </div>
          <div style={{ height: "max(4vw, 4vh)" }} />
        </div>

        <Modal
          isOpen={this.state.isShowModal}
          onRequestClose={() => this.setState({ isShowModal: false })}
          style={customStyles}
        >
          {!this.state.isCompleteForm && (
            <div
              style={{
                minHeight: this.state.isPortriat ? "75vh" : "min(40vw, 80vh)",
                minWidth: this.state.isPortriat ? "75vw" : "70vw",
                flexDirection: this.state.isPortriat ? "column" : "row",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: this.state.isPortriat ? "" : 1,
                  marginLeft: this.state.isPortriat ? "" : "2vw",
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontWeight: 700,
                    fontSize: this.state.isPortriat ? "3vh" : "max(2.5vw, 3.5vh)",
                    lineHeight: 1.2,
                    width: this.state.isPortriat ? "" : "max(10vw, 16vh)",
                    textAlign: this.state.isPortriat ? "center" : "",
                  }}
                >
                  <b>Get your pre-early access</b>
                </p>
                <div style={{ height: "max(1vw, 1vh" }} />
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: this.state.isPortriat ? "1.5vh" : "max(1.5vw, 2vh)",
                    lineHeight: 1.3,
                    width: this.state.isPortriat ? "" : "max(20vw, 25vh)",
                    textAlign: this.state.isPortriat ? "center" : "",
                  }}
                >
                  and help us find someone like you easier
                </p>
                <div style={{ flex: 1 }} />
                {!this.state.isPortriat && (
                  <center>
                    <img
                      style={{ width: "min(25vw, 60vh)", height: "min(15.15vw. 36.36vh)" }}
                      alt="VsDragon"
                      src={require("../../img/FitToon/VsDragon.png")}
                    />
                  </center>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <div style={{ height: "max(1.5vw, 3vh" }} />
                <Select
                  placeholder={"Select Country"}
                  value={countryOption}
                  onChange={this.handleCountryChange}
                  options={countryOptions}
                />
                <div style={{ height: "max(0.7vw, 0.7vh" }} />
                <Select
                  placeholder={"Select Year of Birth"}
                  value={yearOption}
                  onChange={this.handleYearChange}
                  options={YearOption}
                />
                <div style={{ height: "max(0.7vw, 0.7vh" }} />
                <Select
                  placeholder={"Select Work Industry"}
                  value={indrustryOption}
                  onChange={this.handleIndustryChange}
                  options={IndustryOptions}
                  isSearchable={false}
                />
                <div style={{ height: "max(2.5vw, 3vh" }} />
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Roboto",
                    fontSize: "max(0.8vw, 14)",
                    textAlign: "center",
                  }}
                >
                  Your email address*
                </p>
                <input
                  style={{ fontFamily: "Roboto", fontSize: 18, padding: 6, borderRadius: 5 }}
                  type="email"
                  value={this.state.email}
                  placeholder={"youremail@example.com"}
                  onChange={this.handleEmailChange}
                />
                {this.state.isIncorrectEmail && (
                  <p style={{ color: "#FFE600", textAlign: "center" }}>*wrong email</p>
                )}
                <div style={{ flex: 1 }} />
                <center>
                  {this.state.isPortriat && (
                    <img
                      style={{ width: "33vh", height: "20vh", marginBottom: "1vh" }}
                      alt="VsDragon"
                      src={require("../../img/FitToon/VsDragon.png")}
                    />
                  )}
                  <span onClick={() => this.sendForm()} style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ flex: 1 }} />
                    <div
                      style={{
                        backgroundColor: "#FF7121",
                        borderRadius: "5vw",
                        padding: "max(0.5vw, 0.5vh)",
                        paddingLeft: "max(2vw, 2vh)",
                        paddingRight: "max(2vw, 2vh)",
                      }}
                    >
                      <p style={{ color: "#fff", fontFamily: "Rubik", fontSize: "max(1.2vw, 2vh)" }}>
                        <b>SEND FORM</b>
                      </p>
                    </div>
                    <div style={{ flex: 1 }} />
                  </span>
                  <div style={{ height: "min(1vh, 1vw)" }} />
                  <span
                    onClick={() => this.setState({ isShowModal: false })}
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "max(1vw, 2vh)",
                      color: "#fff",
                      textDecoration: "underline",
                    }}
                  >
                    Cancel
                  </span>
                  <div style={{ height: "min(3vh, 3vw)" }} />
                </center>
              </div>
            </div>
          )}
          {this.state.isCompleteForm && (
            <div
              style={{
                height: "70vh",
                width: "70vw",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }} />
              <center>
                <img
                  style={{ height: "25vh", width: "12.375vh" }}
                  alt="FireHeadTy"
                  src={require("../../img/FitToon/FireHeadTy.png")}
                />
                <p
                  style={{ color: "#fff", fontWeight: 900, fontFamily: "Roboto", fontSize: "max(2vw, 3vh)" }}
                >
                  THANK YOU!
                </p>
                <div style={{ height: "max(1vw, 2vh)" }} />
                {!this.state.isPortriat && (
                  <p style={{ color: "#fff", fontFamily: "Roboto", fontSize: "max(1.25vw, 1.75vh)" }}>
                    We appreciate your paticipation.
                  </p>
                )}
                <p style={{ color: "#fff", fontFamily: "Roboto", fontSize: "max(1.25vw, 1.75vh)" }}>
                  We will contact you back soonish!
                </p>
              </center>
              <div style={{ flex: 1 }} />
            </div>
          )}
        </Modal>
        <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
      </div>
    );
  }
}
