export const replaceQueryParam = (param, newval, search) => {
  var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
  var query = search.replace(regex, "$1").replace(/&$/, "");

  return (query.length > 2 ? query + "&" : "?") + (newval ? param + "=" + newval : "");
};

export const changeLang = (newLang, oldLang) => {
  if (oldLang !== newLang) {
    var str = window.location.search;
    str = replaceQueryParam("hl", newLang, str);
    window.location = window.location.pathname + str;
  }
};
