import React, { Component } from "react";
import Cookies from "universal-cookie";
import "../../css/Home.css";

const cookies = new Cookies();

var campaignID;
var cookieID = "";

export default class FitforceHowToInstallV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 480,
    };
    var queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    campaignID = urlParams.get("cid");

    cookieID = cookies.get("fitforceCookieID");

    var _this = this;

    if (cookieID !== undefined) {
      this.logActivity("LoadPage");
    } else {
      fetch("/api/gen-cookie", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          cookies.set("fitforceCookieID", data.CookieID, { path: "/" });
          cookieID = cookies.get("fitforceCookieID");
          _this.logActivity("LoadPage");
        });
    }
  }

  logActivity(activity) {
    fetch("/api/log-page-activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CampaignID: campaignID,
        Activity: activity,
        CookieID: cookieID,
      }),
    });
  }

  resize = () => {
    this.setState({ isMobile: window.innerWidth <= 480 });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  mobileImage() {
    return (
      <img
        alt="Fitforce"
        src={require("../../img/Fitforce/fitforcemobile.png")}
        style={{ height: "25vmax", width: "auto" }}
      />
    );
  }

  mobileDetail() {
    return (
      <div style={{ flexDirection: "column" }}>
        <div style={{ height: "2vmin" }} />
        <h2 style={{ color: "#fff", textAlign: "center", fontSize: this.state.isMobile ? "16px" : "3vmin" }}>
          <b>STEP 1 -</b> Download Mobile app
        </h2>
        <p style={{ color: "#fff", textAlign: "center", fontSize: this.state.isMobile ? "12px" : "2vmin" }}>
          For detecting your body movement
        </p>
        <div style={{ height: "2vmin" }} />
        <div
          style={{
            flexDirection: "row",
            display: "inline-flex",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 1 }} />
          <a
            onClick={() => this.logActivity("ToAppStore")}
            href="https://apps.apple.com/us/app/fitforce/id1402662894"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: "auto", height: "8vmin" }}
              alt="appStore"
              src={require("../../img/appStore.png")}
            />
          </a>
          <div style={{ width: "2vmin" }} />
          <a
            onClick={() => this.logActivity("ToPlayStore")}
            href="https://play.google.com/store/apps/details?id=com.runster"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: "auto", height: "8vmin" }}
              alt="googlePlay"
              src={require("../../img/googlePlay.png")}
            />
          </a>
          <div style={{ flex: 1 }} />
        </div>
      </div>
    );
  }

  pcImage() {
    return (
      <img
        alt="Fitforce"
        src={require("../../img/Fitforce/fitforcepc.png")}
        style={{ height: "22vmax", width: "auto" }}
      />
    );
  }

  pcDetail() {
    return (
      <div style={{ flexDirection: "column" }}>
        <div style={{ height: "2vmin" }} />
        <h2 style={{ color: "#fff", textAlign: "center", fontSize: this.state.isMobile ? "16px" : "3vmin" }}>
          <b>STEP 2 -</b> Install on PC
        </h2>
        <p style={{ color: "#fff", textAlign: "center", fontSize: this.state.isMobile ? "12px" : "2vmin" }}>
          For visualizing the game
        </p>
        <div style={{ height: "2vmin" }} />
        <div
          style={{
            flexDirection: "row",
            display: "inline-flex",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 1 }} />
          <a
            onClick={() => this.logActivity("ToSteam")}
            href="https://store.steampowered.com/app/1081670/Fitforce/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: "auto", height: "8vmin" }}
              alt="Fitforce"
              src={require("../../img/Fitforce/getitonsteam.png")}
            />
          </a>
          <div style={{ width: "2vmin" }} />
          <a
            onClick={() => this.logActivity("DirectDownload")}
            href="http://fitforce.fun:8888/hfs/fitforcesetup.exe"
            target="_blank"
            rel="noreferrer"
          >
            <img
              style={{ width: "auto", height: "8vmin" }}
              alt="Fitforce"
              src={require("../../img/Fitforce/directdownload.png")}
            />
          </a>
          <div style={{ flex: 1 }} />
        </div>
      </div>
    );
  }

  connectImage() {
    return (
      <img
        alt="Fitforce"
        src={require("../../img/Fitforce/step3.png")}
        style={{ height: "25vmax", width: "auto" }}
      />
    );
  }

  connectDetail() {
    return (
      <div style={{ flexDirection: "column" }}>
        <div style={{ height: "2vmin" }} />
        <h2 style={{ color: "#fff", textAlign: "center", fontSize: this.state.isMobile ? "16px" : "3vmin" }}>
          <b>STEP 3 -</b> Enjoy !
        </h2>
        <p
          style={{
            color: "#fff",
            textAlign: "center",
            fontSize: this.state.isMobile ? "12px" : "2vmin",
            width: this.state.isMobile ? "70vw" : "60vmin",
          }}
        >
          Nothing left to do, just open both PC and mobile and enjoy the emersive exercise experience in your
          hand
        </p>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: "100%", "background-image": "linear-gradient(#ee7139, #2D3436)" }}>
        {this.state.isMobile && (
          <div>
            <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
              <div style={{ height: "2vmin" }} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "column",
                    display: "flex",
                    padding: "2vmin",
                  }}
                >
                  <div style={{ height: "3vmin" }} />
                  <img
                    alt="Fitforce"
                    src={require("../../img/Fitforce/Fitforce_Logo.png")}
                    style={{ width: "30vmax", maxWidth: "70vmin", height: "auto" }}
                  />
                  <div style={{ height: "2vmin" }} />
                  <h2
                    style={{
                      color: "#fff",
                      fontSize: this.state.isMobile ? "16px" : "3vmin",
                      fontWeight: "bold",
                    }}
                  >
                    Interactive Fitness in your hand
                  </h2>
                  <div style={{ height: "1vmin" }} />
                  <p
                    style={{
                      color: "#fff",
                      fontSize: this.state.isMobile ? "12px" : "2vmin",
                      width: "90vw",
                      textAlign: "center",
                    }}
                  >
                    No pricey devices needed. Just connect your smartphone to a PC and enjoy an immersive
                    exercise experience.
                  </p>
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: "#0009", padding: "2vmin" }}>
              <h2
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: this.state.isMobile ? "16px" : "2.5vmin",
                  fontWeight: "bold",
                }}
              >
                Free download in 3 steps
              </h2>
            </div>
          </div>
        )}
        {!this.state.isMobile && (
          <img
            alt="Fitforce"
            src={require("../../img/Fitforce/headder.jpg")}
            style={{ width: "100%", height: "auto" }}
          />
        )}
        <div>
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div style={{ height: "2vmax" }} />
            {this.mobileImage()}
            {this.mobileDetail()}
          </div>
          <div style={{ height: "5vmin" }} />
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div style={{ height: "2vmax" }} />
            {this.pcImage()}
            {this.pcDetail()}
          </div>
          <div style={{ height: "5vmin" }} />
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <div style={{ height: "2vmax" }} />
            {this.connectImage()}
            {this.connectDetail()}
          </div>
        </div>
        <div style={{ height: "5vmin" }} />
        <center style={{ backgroundColor: "#0006", flexDirection: "column", alignItems: "center" }}>
          <div style={{ height: "5vh" }} />
          <p
            style={{ color: "#fff", fontSize: this.state.isMobile ? "16px" : "2.5vmin", textAlign: "center" }}
          >
            Contact us
          </p>
          <p style={{ color: "#fff", fontSize: this.state.isMobile ? "12px" : "2vmin", textAlign: "center" }}>
            RunnexTechnology@gmail.com
          </p>
          <br />
          <a href="https://web.facebook.com/fitforce.en/" target="_blank" rel="noreferrer">
            <img
              style={{ width: "10vmin", height: "10vmin" }}
              alt="fbIcon"
              src={require("../../img/fbIcon.png")}
            />
          </a>
          <br />
          <div style={{ height: "10vh" }} />
        </center>
      </div>
    );
  }
}
