export const logActivity = (activity, campaignID, cookieID) => {
  fetch("/api/log-page-activity", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      CampaignID: campaignID,
      Activity: activity,
      CookieID: cookieID,
    }),
  });
};
