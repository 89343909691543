import React from "react";

const TextInput = ({ placeholder, className, disabled, name, value, onChange, error }) => {
  return (
    <div className={`flex ${className}`}>
      <div className="mb-1 w-full">
        <div className="input-group relative flex items-stretch w-full">
          <input
            type="search"
            className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-indigo-600 focus:outline-none disabled:text-slate-400 disabled:bg-slate-200"
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        {error ? <div className="text-red-400 text-sm m-1 mt-0">{error}</div> : <div className="h-2"></div>}
      </div>
    </div>
  );
};

export default TextInput;
