import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { adminApi } from "../../api";
import DataRefresher from "../../components/DataRefresher";
import { customTableStyles, formatDateTime } from "../../helper/misc";

const AdminCBTRegistration = () => {
  const [data, setData] = useState([]);
  const [copied, setCopied] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(moment());

  const handleSend = (id) => {
    setLoading(true);
    adminApi.sendActivationCode(id).then((r) => {
      data.find((x) => x._id === id).codeSentTime = r.data.cbtUser.codeSentTime;
      data.find((x) => x._id === id).sticking = true;
      setData([...data]);
      setLoading(false);
      alert("Activation code email sent successfully!");
    });
  };

  const handleResend = (id) => {
    if (window.confirm("Are you sure you want to resend the activation code email?")) {
      handleSend(id);
    }
  };

  const isRecentlySent = (codeSentTime) => {
    const momentDate = moment(codeSentTime);
    if (momentDate.year() === 1) return false;
    return momentDate.isAfter(moment().subtract(1, "day"));
  };

  const fetchData = () => {
    setLoading(true);
    adminApi.getCBTUsers().then((r) => {
      setData(r.data || []);
      setLastUpdated(moment());
      setLoading(false);
      setInitialized(true);
    });
  };

  const dataWaiting = useMemo(
    () =>
      data?.filter(
        (x) =>
          (formatDateTime(x?.activatedTime) === "-" && formatDateTime(x?.codeSentTime) === "-") || x?.sticking
      ),
    [data]
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopyAndroidEmails = () => {
    const emails = dataWaiting
      .filter((x) => x?.osPlatform === "Android")
      .map((x) => x.email)
      .join(",");
    setCopied(true);
    navigator.clipboard.writeText(emails);
    setTimeout(() => {
      setCopied(false);
    }, 80);
  };

  const columns = [
    { name: "#", selector: (_, index) => index + 1, width: "50px" },
    {
      name: "Name",
      selector: (row) => (
        <div
          onClick={() => {
            setCopied(true);
            navigator.clipboard.writeText(row.name);
            setTimeout(() => {
              setCopied(false);
            }, 80);
          }}
          className={copied ? "cursor-progress p-4" : "cursor-pointer p-4"}
        >
          {row.name || "-"}
        </div>
      ),
      minWidth: "100px",
      sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
      sortable: true,
      compact: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <div
          onClick={() => {
            setCopied(true);
            navigator.clipboard.writeText(row.email);
            setTimeout(() => {
              setCopied(false);
            }, 80);
          }}
          className={copied ? "cursor-progress p-4" : "cursor-pointer p-4"}
        >
          {row.email}
        </div>
      ),
      minWidth: "200px",
      sortFunction: (a, b) => (a.email > b.email ? 1 : -1),
      sortable: true,
      compact: true,
    },
    { name: "Age", selector: (row) => row.age, width: "70px", sortable: true },
    { name: "Job", selector: (row) => row.job, width: "120px", sortable: true, wrap: true },
    { name: "Gender", selector: (row) => row.gender, width: "90px", sortable: true },
    {
      name: "OS",
      selector: (row) => (
        <div>
          <p>{row.osPlatform}</p>
          <p>{row.osVersion}</p>
        </div>
      ),
      width: "80px",
      sortFunction: (a, b) => (a.osPlatform + a.osVersion > b.osPlatform + b.osVersion ? 1 : -1),
      sortable: true,
    },
    {
      name: "Registered Time",
      selector: (row) => formatDateTime(row.createdTime),
      sortable: true,
    },
    { name: "Activation Time", selector: (row) => formatDateTime(row.activatedTime), sortable: true },
    {
      name: "Action",
      selector: (row) => {
        if (formatDateTime(row.activatedTime) !== "-") return <p className="text-gray-400">Activated</p>;
        if (formatDateTime(row.codeSentTime) === "-")
          return (
            <button
              onClick={() => handleSend(row._id)}
              type="button"
              className="inline-block my-2 px-3 py-2 bg-teal-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-lg hover:bg-teal-800"
            >
              Send Code
            </button>
          );
        return (
          <div className="my-1">
            <p className="text-gray-400 text-xs">Code Sent at </p>
            <p className="text-gray-400 text-xs">{formatDateTime(row.codeSentTime)}</p>
            {!isRecentlySent(row.codeSentTime) && (
              <p onClick={() => handleResend(row._id)} className="text-blue-400 cursor-pointer">
                Resend
              </p>
            )}
          </div>
        );
      },
      sortFunction: (a, b) => {
        if (formatDateTime(a.activatedTime) !== "-") return 1;
        if (formatDateTime(b.activatedTime) !== "-") return -1;
        if (formatDateTime(a.codeSentTime) === "-") return -1;
        if (formatDateTime(b.codeSentTime) === "-") return 1;
        return a.codeSentTime > b.codeSentTime ? 1 : -1;
      },
      minWidth: "150px",
      sortable: true,
    },
  ];

  return (
    <>
      <div className="mb-4">
        <DataTable
          title={
            <div className="flex justify-between">
              <div className="flex">
                <div>Waiting</div>
                <div
                  className={`ml-8 px-2 py-1 text-xs self-center rounded border cursor-pointer ${
                    copied ? "cursor-progress" : "cursor-pointer"
                  }`}
                  onClick={handleCopyAndroidEmails}
                >
                  Copy all emails (Android)
                </div>
              </div>
              <DataRefresher refetch={fetchData} loading={loading} lastUpdated={lastUpdated} />
            </div>
          }
          columns={columns}
          data={dataWaiting}
          progressPending={!initialized}
          disabled={loading}
          pagination
          customStyles={customTableStyles}
        />
      </div>
      <div>
        <DataTable
          title={
            <div className="flex justify-between">
              <div>All History</div>
              <DataRefresher refetch={fetchData} loading={loading} lastUpdated={lastUpdated} />
            </div>
          }
          columns={columns}
          data={data}
          progressPending={!initialized}
          disabled={loading}
          pagination
          customStyles={customTableStyles}
        />
      </div>
    </>
  );
};

export default AdminCBTRegistration;
