import React, { useState, useEffect } from 'react';
import { quizApi } from "../../api";
import { FacebookShareButton } from 'react-share';

const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  const [results, setResults] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [scores, setScores] = useState([]);
  const [startQuiz, setStartQuiz] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const fetchData = () => {
    quizApi.getAll().then((r) => {
      setQuestions(r.data || []);
    });
    quizApi.getResult().then((r) => {
      setResults(r.data || []);
    });
  };

  useEffect(() => {
    document.title = "Fitforce 2 - Quiz";
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedAnswerIndex !== null) {
      const selectedOption = questions[activeQuestion].options[selectedAnswerIndex];
      const category = questions[activeQuestion].category;

      setScores((prevScores) => {
        const updatedScores = [...prevScores];
        const existingCategoryIndex = updatedScores.findIndex((score) => score.category === category);

        if (existingCategoryIndex !== -1) {
          updatedScores[existingCategoryIndex].score += selectedOption.score;
        } else {
          updatedScores.push({ category: category, score: selectedOption.score });
        }

        return updatedScores;
      });
    }
  }, [selectedAnswerIndex, activeQuestion]);

  const onClickNext = (index) => {
    if (selectedAnswerIndex === null) {
      return;
    }

    setPopUp(true);

    setTimeout(() => {
      setPopUp(false);

      setSelectedAnswerIndex(null);
      if (activeQuestion !== questions.length - 1) {
        setActiveQuestion((prev) => prev + 1);
      } else {
        setActiveQuestion(0);
        setShowResult(true);
      }
    }, 1000);
  };

  const onAnswerHovered = (index) => {
    setSelectedAnswerIndex(index);
  };

  const onClickBack = () => {
    if (activeQuestion !== 0) {
      setActiveQuestion((prev) => prev - 1);
    }
  };

  const mediaQuery = window.matchMedia("(max-width: 768px)");
  const isMobile = !mediaQuery.matches;

  return (
    <>
      {startQuiz ? (
        showResult ? renderResult() : renderQuestion()
      ) : (
        <div
          style={{
            backgroundImage: `url(${require("../../img/Quiz/background.png")})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            flexShrink: 0,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              fontFamily: "Kanit",
              fontSize: isMobile ? "30px" : "20px",
              color: "white",
              letterSpacing: "1.4px",
              textAlign: "center",
              marginTop: "3.5rem",
              padding: "0 2rem",
            }}
          >
            คุณเป็นคนประเภทไหน
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              height: isMobile ? "35rem" : "15rem",
              width: isMobile ? "90rem" : "40rem",
              marginTop: isMobile ? "0rem" : "1rem",
              backgroundImage: `url(${require("../../img/Quiz/quizCoverBox.png")})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <img src={require("../../img/Quiz/quizCover.png")} alt="quiz cover" style={{ width: isMobile ? "20rem" : "10rem", margin: isMobile ? "6.8rem 0 0 0" : "2rem 0 0 0" }} />
            <p style={{ fontFamily: "Kanit", fontSize: isMobile ? "24px" : "16px", color: "white", margin: isMobile ? "2rem 0 0 0" : "1.25rem 0 0 0", padding: isMobile ? "0 25rem" : "0 12rem" }}>มาตอบ 9 คำถามสั้นๆเพื่อหากันเถอะว่าคุณเป็นคนประเภทไหน ?</p>
          </div>
          <img onClick={() => setStartQuiz(true)} src={require("../../img/Quiz/startButton.png")} alt="start button" style={{ width: isMobile ? "10rem" : "10rem", marginTop: isMobile ? "0rem" : "1rem",cursor: "pointer" }} />
        </div>
      )}
    </>
  );

  function renderQuestion() {
    const { question, options } = questions[activeQuestion];
    const progressPercentage = ((activeQuestion + 1) / questions.length) * 100;

    return (
      <>
        <div
          style={{
            backgroundImage: `url(${require("../../img/Quiz/background.png")})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            flexShrink: 0,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <h2
            style={{
              display: "flex",
              height: "5vh",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
              color: "#EAEAEA",
              marginTop: "14vh",
              marginBottom: "3vh",
              padding: "0 2rem",
              textAlign: "center",
              fontFamily: "Kanit",
              fontSize: isMobile ? "32px" : "28px",
              fontStyle: "normal",
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          >
            {question}
          </h2>
          <div
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              left: "50%",
              bottom: "12.5vh",
              transform: "translateX(-50%)",
            }}
          >
            <img src={require("../../img/Quiz/FitforceLogo.png")} alt="logo" style={{ width: isMobile ? "12rem" : "8rem" }} />
          </div>
          <ul
            style={{
              padding: "0 2rem",
            }}
          >
            {options.map((option, index) => (
              <li
                onMouseEnter={() => onAnswerHovered(index)}
                onClick={onClickNext}
                onTouchStart={() => onAnswerHovered(index)}
                key={option.text}
                className={selectedAnswerIndex === index ? 'selected-answer' : ''}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "none",
                  listStyle: "none",
                  color: "#2d264b",
                  fontFamily: "Kanit",
                  fontSize: isMobile ? "20px" : "15px",
                  background: selectedAnswerIndex !== index ? "rgba(255, 255, 255, 0.70)" : "linear-gradient(180deg, #B397FF 0%, #FCAAF4 100%)",
                  borderRadius: "0.75rem",
                  padding: "2vh",
                  marginTop: "4.375vh",
                  cursor: "pointer",
                  maxWidth: "70vw",
                  transform: selectedAnswerIndex === index && popUp ? "scale(1.1)" : "scale(1)",
                  transition: "transform 0.1s ease-in-out"
                }}
              >
                <img src={require("../../img/Quiz/greenBar.png")} alt="green bar" style={{ height: "1.5rem", marginRight: "1rem", marginLeft: "1rem", marginTop: "0.25rem" }} />
                {option.text}
              </li>
            ))}
          </ul>
          <div
            style={{
              display: "flex",
              position: "absolute",
              left: 0,
              bottom: 0,
              right: 0,
              height: "7.5%",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <footer
              style={{
                flexDirection: "row",
                background: "#070014",
                display: "flex",
                padding: "10px",
                height: "100%",
                width: "100%",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <div
                onClick={onClickBack}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                  position: "relative",
                  width: "6.5rem",
                  height: "2rem",
                  background: `url(${require("../../img/Quiz/backButton.png")})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  cursor: "pointer",
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                  position: "relative",
                  width: "19rem",
                  height: "2rem",
                  background: `url(${require("../../img/Quiz/progressionBar.png")})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    fontFamily: "Kanit",
                    fontSize: "16px",
                    width: "100%",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                  }}>
                  {activeQuestion + 1}/{questions.length}
                </p>
                <div
                  style={{
                    width: `${progressPercentage}%`,
                    position: "absolute",
                    height: "80%",
                    marginBottom: "0.2rem",
                    marginLeft: "0.075rem",
                    borderRadius: "0.2rem",
                    background: "linear-gradient(180deg, #9873FF 0%, #FF8EF4 100%)",
                  }}
                />
              </div>
            </footer>
          </div>
        </div>
      </>
    );
  }

  function renderResult() {
    const comparator = (a, b, operator) => {
      switch (operator) {
        case ">":
          return a > b;
        case ">=":
          return a >= b;
        case "<":
          return a < b;
        case "<=":
          return a >= b;
        default:
          return a === b;
      }
    }

    var firstCategory = false;
    var secondCategory = false;
    var thirdCategory = false;
    const result = () => {
      for (let i = 0; i < results.length; i++) {
        if (comparator (scores[0].score, results[i].requirement[0].value, results[i].requirement[0].operator)) {
          firstCategory = true;
        }
        if (comparator (scores[1].score, results[i].requirement[1].value, results[i].requirement[1].operator)) {
          secondCategory = true;
        }
        if (comparator (scores[2].score, results[i].requirement[2].value, results[i].requirement[2].operator)) {
          thirdCategory = true;
        }
        if (firstCategory && secondCategory && thirdCategory) {
          return i;
        }
        firstCategory = false;
        secondCategory = false;
        thirdCategory = false;
      }
      return 0;
    }
    const resultIndex = result();
    return (
      <>
        <div
          style={{
            backgroundImage: `url(${require("../../img/Quiz/background.png")})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            flexShrink: 0,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              fontFamily: "Kanit",
              fontSize: isMobile ? "30px" : "20px",
              color: "white",
              letterSpacing: "1.4px",
              textAlign: "center",
              marginTop: "3.5rem",
              padding: "0 2rem",
            }}
          >
            คุณเป็นคนประเภทไหน
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              height: isMobile ? "32rem" : "14rem",
              width: isMobile ? "60rem" : "25rem",
              marginTop: isMobile ? "2rem" : "1rem",
              backgroundImage: `url(${require("../../img/Quiz/resultBox.png")})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div
              className="result"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: isMobile ? "4rem" : "1.5rem",
              }}
            >
              <p style={{ fontFamily: "Kanit", fontSize: isMobile ? "28px" : "16px", fontWeight: "600", textTransform: "uppercase", color: "white"}}>
              {results[resultIndex].name}
              </p>
              <img src={results[resultIndex].imageURL} alt={results[resultIndex].Name} style={{ width: isMobile ? "40rem" : "17.5rem", marginTop: "1rem"}} />
              <p style={{ fontFamily: "Kanit", fontSize: isMobile ? "24px" : "12px", color: "white", margin: isMobile ? "2rem 0 0 0" : "1.25rem 0 0 0", padding: isMobile ? "0 1rem" : "0 1.25rem" }}>
                {results[resultIndex].description}
              </p>
            </div>
          </div>
          <FacebookShareButton
            url={'https://www.fitforce2.com/quiz'}
            hashtag="#Fitforce2"
          >
            <img
            src={require("../../img/Quiz/shareButton.png")}
            alt="Share Button"
            style={{ width: isMobile ? "13rem" : "7rem", cursor: "pointer", marginTop: "1.5rem" }}
          />
          </FacebookShareButton>
        </div>
      </>
    );
  }
};

export default Quiz;
